/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemHouse
 */
export interface EnergyItemHouse {
  /**
   *
   * @type {number}
   * @memberof EnergyItemHouse
   */
  consumption?: number;
  /**
   *
   * @type {number}
   * @memberof EnergyItemHouse
   */
  selfConsumption?: number;
}

/**
 * Check if a given object implements the EnergyItemHouse interface.
 */
export function instanceOfEnergyItemHouse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EnergyItemHouseFromJSON(json: any): EnergyItemHouse {
  return EnergyItemHouseFromJSONTyped(json, false);
}

export function EnergyItemHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemHouse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    consumption: !exists(json, 'consumption') ? undefined : json['consumption'],
    selfConsumption: !exists(json, 'selfConsumption')
      ? undefined
      : json['selfConsumption'],
  };
}

export function EnergyItemHouseToJSON(value?: EnergyItemHouse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    consumption: value.consumption,
    selfConsumption: value.selfConsumption,
  };
}
