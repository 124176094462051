import { DeleteAccountModal } from './components/DeleteAccountModal';
import { demoAccountSettings } from '@clevergy/shared/constants/demoAccountSettings';
import { useHouseSettingsBackupStore } from '@clevergy/shared/stores/houseSettingsBackupStore';
import { useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { AppHeader } from 'components/AppHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appSettingsStore';
import { useUserCompletionsWhileOnboardingStore } from 'stores/userCompletionsWhileOnboardingStore';

export const AccountPage: FC = () => {
  const { t } = useTranslation();
  const { authedUser, signOut } = useAuthContext();
  const { userInfo } = useUserSetupContext();
  const { hasLogOutEnabled } = useTenantSettings();

  const resetAppConfiguration = useAppConfigurationStore(
    (state) => () => state.reset(),
  );

  const resetUserCompletions = useUserCompletionsWhileOnboardingStore(
    (state) => state.reset,
  );

  const resetHouseSettingsBackup = useHouseSettingsBackupStore(
    (state) => state.reset,
  );

  const { hasDeleteAccountEnabled } = useTenantSettings();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const list = [
    {
      id: 'name',
      key: t('account.name.key'),
      text:
        userInfo?.name && userInfo?.surname
          ? `${userInfo?.name} ${userInfo?.surname}`
          : null,
    },
    {
      id: 'email',
      key: t('account.email.key'),
      text: userInfo?.email,
    },
    {
      id: 'dni',
      key: userInfo.isCompany ? t('account.cif.key') : t('account.dni.key'),
      text: userInfo?.dni,
    },
  ];
  const isDemoAccount = demoAccountSettings.emailRegex.test(
    authedUser?.email || '',
  );

  return (
    <>
      <AppHeader backButton title={t('account.title') as string} />
      <Wrapper>
        <div className="flex flex-col">
          <ul className="list-none">
            {list.map((item) => {
              if (!item.text) {
                return null;
              }

              return (
                <li
                  key={item.id}
                  className="flex w-full flex-wrap justify-between gap-4 rounded-none border-b border-gray-200 px-2 py-4 pr-2"
                >
                  <div className="text-left">{item.key}</div>
                  <div className="text-clevergy-unselected truncate text-right">
                    {item.text}
                  </div>
                </li>
              );
            })}
            {hasLogOutEnabled && (
              <li className="w-full my-4">
                <Link to={'/logout'}>
                  <Button fullWidth colorScheme="primary">
                    {t('account.session.button')}
                  </Button>
                </Link>
              </li>
            )}
            {hasDeleteAccountEnabled && !isDemoAccount && (
              <li className="w-full my-4">
                <Button
                  fullWidth
                  colorScheme="danger"
                  onClick={() => setShowDeleteAccountModal(true)}
                >
                  {t('account.deleteAccount.button')}
                </Button>
              </li>
            )}
          </ul>
        </div>
      </Wrapper>

      <DeleteAccountModal
        isOpen={showDeleteAccountModal}
        onCloseModal={() => setShowDeleteAccountModal(false)}
        onAccountDeleted={() => {
          setShowDeleteAccountModal(false);
          resetAppConfiguration();
          resetUserCompletions();
          resetHouseSettingsBackup();
          signOut();
        }}
      />
    </>
  );
};
