import LeadCaptureSuccessIllustration from '../assets/lead-capture-success.svg?react';
import { Button } from '@clevergy/ui/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type LeadCaptureSuccessProps = {
  onFinish: () => void;
};

export const LeadCaptureSuccess: FC<LeadCaptureSuccessProps> = ({
  onFinish,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center h-full text-center gap-4">
      <div>
        <h2 className="text-xl mb-1 font-semibold">
          {t('leadCapture.success.title')}
        </h2>
        <p>{t('leadCapture.success.message')}</p>
      </div>
      <LeadCaptureSuccessIllustration />
      <Button inverted variant={'filled'} onClick={onFinish} fullWidth>
        {t('leadCapture.success.button')}
      </Button>
    </div>
  );
};
