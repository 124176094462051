/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 * New ticket information
 * @export
 * @interface NewTicket
 */
export interface NewTicket {
  /**
   * Ticket subject
   * @type {string}
   * @memberof NewTicket
   */
  subject: string;
  /**
   * Ticket description
   * @type {string}
   * @memberof NewTicket
   */
  description: string;
  /**
   * House identifier
   * @type {string}
   * @memberof NewTicket
   */
  houseId?: string;
}

/**
 * Check if a given object implements the NewTicket interface.
 */
export function instanceOfNewTicket(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'subject' in value;
  isInstance = isInstance && 'description' in value;

  return isInstance;
}

export function NewTicketFromJSON(json: any): NewTicket {
  return NewTicketFromJSONTyped(json, false);
}

export function NewTicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NewTicket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: json['subject'],
    description: json['description'],
    houseId: !exists(json, 'houseId') ? undefined : json['houseId'],
  };
}

export function NewTicketToJSON(value?: NewTicket | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subject: value.subject,
    description: value.description,
    houseId: value.houseId,
  };
}
