import { useTenantSettings } from '../../context/TenantSettingsContext';
import { tenantsApiBaseUrl } from '@clevergy/shared/constants/default';
import { FC } from 'react';

export type TenantLogoProps = {
  type?: 'default' | 'small';
  className?: string;
  width?: number;
  height?: number;
};

export const TenantLogo: FC<TenantLogoProps> = (props) => {
  const { type = 'default', className, width, height } = props;

  const { tenantId, tenantName } = useTenantSettings();

  const src = `${tenantsApiBaseUrl}/public-assets/${tenantId}/${
    type === 'small' ? 'logo-small.png' : 'logo.png'
  }`;

  return (
    <img
      src={src}
      alt={tenantName}
      className={className}
      width={width}
      height={height}
    />
  );
};
