import { LoadingIndicator } from '../LoadingIndicator';
import { FC, ReactNode } from 'react';

export type ModuleHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
  isBusy?: boolean;
};

export const ModuleHeader: FC<ModuleHeaderProps> = (props) => {
  const { title, subtitle, icon, action, isBusy } = props;
  return (
    <header className="mb-2">
      <div className="flex w-full items-start gap-2">
        {icon && (
          <div className="text-clevergy-moduleHeaderTitle flex-grow-0">
            {icon}
          </div>
        )}
        {title && (
          <div className="text-clevergy-moduleHeaderTitle grow text-xl font-semibold leading-6 @3xl:grow-0">
            <h3>{title}</h3>
          </div>
        )}
        <div className="w-5 h-5 flex items-center justify-center order-1 @3xl:order-2">
          {isBusy && <LoadingIndicator variant="spinner" />}
        </div>
        {action && (
          <div className="text-clevergy-moduleHeaderAction order-2 @3xl:order-1 ">
            {action}
          </div>
        )}
      </div>
      {subtitle && (
        <div className="text-clevergy-text my-2 text-base">{subtitle}</div>
      )}
    </header>
  );
};
