import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { Button } from '@clevergy/ui/components/Button';
import { Prose } from '@clevergy/ui/components/Prose';
import { useMutation } from '@tanstack/react-query';
import { HousePicker } from 'components/HousePicker';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlexaSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { alexaAuthorization } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();

  const createAlexaUserAuthCodeMutation = useMutation({
    mutationFn: (cups: string) =>
      api.alexa.createAlexaUserAuthCode({
        userId: authedUser?.uid || 'me',
        createAlexaUserAuthCodeRequest: {
          cups,
        },
      }),
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { state, redirect_uri } = alexaAuthorization || {};
      const { userAuthCode } =
        await createAlexaUserAuthCodeMutation.mutateAsync(
          selectedHouse?.cups || '',
        );

      window.location.replace(
        `${redirect_uri}?state=${state}&code=${userAuthCode}`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TrackedPage
      event={{
        event_type: 'page_view',
        event_properties: {
          page_name: 'integration_alexa',
        },
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-col gap-4">
        <div>
          <Prose>
            <h1>{t('alexa.settings.title')}</h1>
            <p>{t('alexa.settings.message')}</p>
          </Prose>
        </div>

        <div>
          <div>
            <HousePicker flatMode={false} />
          </div>
        </div>

        <div>
          <Button
            fullWidth
            type="submit"
            busy={createAlexaUserAuthCodeMutation.isPending}
          >
            {t('alexa.settings.button')}
          </Button>
        </div>
      </form>
    </TrackedPage>
  );
};
