import { SurplusRecommendations } from './components/SurplusRecommendations';
import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { TenantLoadingIndicator, useTenantSettings } from '@clevergy/tenants';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Modal } from '@clevergy/ui/components/Modal';
import { AppAlerts } from 'components/AppAlerts';
import { AppHeader } from 'components/AppHeader';
import { HousePicker } from 'components/HousePicker';
import { ModuleCard } from 'components/ModuleCard';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OptimizePage: FC = () => {
  const { t } = useTranslation();

  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const { rateRecommenderType } = useTenantSettings();

  const [showTipsPanelModal, setShowTipsPanelModal] = useState(false);

  return (
    <>
      <TrackedPage
        event={{
          event_type: 'page_view',
          event_properties: {
            page_name: 'optimize',
          },
        }}
      >
        <AppHeader>
          <HousePicker />
        </AppHeader>

        <div className="bg-[#F3F4F6] min-h-full">
          <PullToRefresh />
          <Wrapper>
            {!token && <TenantLoadingIndicator />}
            {token && (
              <>
                <AppAlerts />

                {selectedHouse && (
                  <clevergy-recommendations
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {selectedHouse && (
                  <clevergy-solar-recommender
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {selectedHouse && <SurplusRecommendations />}

                <ModuleCard>
                  <button
                    onClick={() => setShowTipsPanelModal(true)}
                    className="flex w-full items-center gap-2"
                  >
                    <div className="flex-shrink-0 text-clevergy-primary">
                      <FontIcon name="energy_savings_leaf" />
                    </div>
                    <div className="flex-grow font-bold text-left">
                      {t('optimize.tips.title')}
                    </div>
                    <div className="flex-shrink-0">
                      <FontIcon name="arrow_forward_ios" />
                    </div>
                  </button>
                </ModuleCard>

                {selectedHouse && rateRecommenderType && (
                  <clevergy-rate-recommender
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-variant={rateRecommenderType}
                  />
                )}

                <Modal
                  isOpen={showTipsPanelModal}
                  onClose={() => setShowTipsPanelModal(false)}
                >
                  <TrackedPage
                    event={{
                      event_type: 'module_tips_panel',
                      event_properties: {
                        viewed: 'showed',
                      },
                    }}
                  >
                    <clevergy-tips-panel
                      data-token={token}
                      data-house-id={selectedHouse?.houseId}
                    />
                  </TrackedPage>
                </Modal>
              </>
            )}
          </Wrapper>
        </div>
      </TrackedPage>
    </>
  );
};
