import { TenantSettings } from '../types';
import { FC, ReactNode, createContext, useContext } from 'react';

export const TenantSettingsContext = createContext<TenantSettings | null>(null);

export type TenantSettingsProviderProps = {
  tenantSettings: TenantSettings;
  children: ReactNode;
};

export const TenantSettingsProvider: FC<TenantSettingsProviderProps> = ({
  tenantSettings,
  children,
}) => {
  if (!tenantSettings) {
    throw new Error(`Tenant settings not found`);
  }

  return (
    <TenantSettingsContext.Provider value={tenantSettings}>
      {children}
    </TenantSettingsContext.Provider>
  );
};

export const useTenantSettings = () => {
  const setup = useContext(TenantSettingsContext);
  if (!setup) {
    throw new Error(
      `useTenantSettings must be used within a TenantSettingsProvider`,
    );
  }
  return setup;
};
