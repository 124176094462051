import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { skipToken, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useApiContext } from 'context/ApiContext';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

export const Navbar = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();
  const {
    i18n: { language },
  } = useTranslation();

  const recommendationsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_OPTIMIZE_RECOMMENDATIONS,
      {
        houseId: selectedHouse?.houseId,
      },
    ],
    queryFn: selectedHouse?.houseId
      ? () =>
          api.optimize.getOptimizeRecommendations({
            houseId: selectedHouse.houseId,
            locale: language,
          })
      : skipToken,
  });

  const optimizeBadge = recommendationsQuery.data?.length || 0;

  return (
    <div
      className="flex bg-white font-sans flex-nowrap border-t border-[#eeeef1]"
      style={{
        paddingBottom: 'max(env(safe-area-inset-bottom), 0px)',
      }}
    >
      <NavbarItem
        url={selectedHouse ? `/houses/${selectedHouse?.houseId}` : '/'}
        icon={<FontIcon name="home" />}
        label={t('navbar.home')}
        machingRoutes={['/', '/houses/:houseId']}
      />
      <NavbarItem
        url={
          selectedHouse
            ? `/houses/${selectedHouse?.houseId}/analysis`
            : '/analysis'
        }
        icon={<FontIcon name="grouped_bar_chart" />}
        label={t('navbar.analysis')}
        machingRoutes={['/analysis', '/houses/:houseId/analysis']}
      />
      <NavbarItem
        url={
          selectedHouse
            ? `/houses/${selectedHouse?.houseId}/optimize`
            : '/optimize'
        }
        icon={<FontIcon name="emoji_objects" />}
        label={t('navbar.optimize')}
        badge={optimizeBadge}
        machingRoutes={['/optimize', '/houses/:houseId/optimize']}
      />
      <NavbarItem
        url="/profile"
        icon={<FontIcon name="account_circle" />}
        label={t('navbar.profile')}
        machingRoutes={[
          '/profile',
          '/account',
          '/integrations',
          '/contact',
          '/alexa/*',
          '/support/*',
          '/houses/:houseId/alerts',
          '/houses/:houseId/invoices',
          '/houses/:houseId/smart-devices',
          '/houses/:houseId/settings',
        ]}
      />
    </div>
  );
};

type NavbarItemProps = {
  url: string;
  icon: ReactNode;
  label: string;
  badge?: number;
  machingRoutes: string[];
};

const NavbarItem: FC<NavbarItemProps> = ({
  url,
  icon,
  label,
  badge = 0,
  machingRoutes,
}) => {
  const location = useLocation();
  const isActive = matchRoutes(
    machingRoutes.map((route) => ({ path: route })),
    location,
  );

  return (
    <AnimatePresence key={url} mode="popLayout">
      <Link
        to={url}
        className={clsx(
          'flex flex-col items-center justify-center gap-0.5 text-xs w-full px-2 py-3 cursor-pointer transition-colors duration-200',
          {
            'text-clevergy-primary': isActive,
            'text-clevergy-unselected': !isActive,
          },
        )}
        aria-current={isActive ? 'page' : 'false'}
      >
        <div className="relative">
          {icon}
          {badge > 0 && (
            <motion.span
              className="absolute top-0 -right-[14px] flex items-center justify-center bg-clevergy-secondary text-white font-semibold rounded-full text-[10px] w-4 h-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ scale: 0 }}
            >
              {badge}
            </motion.span>
          )}
        </div>
        {label}
      </Link>
    </AnimatePresence>
  );
};
