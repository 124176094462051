import {
  DeviceFormValues,
  SmartDeviceForm,
} from './components/SmartDeviceForm';
import { SmartDeviceNotFound } from './components/SmartDeviceNotFound';
import {
  UpdateSmartDeviceOperationRequest,
  UpdateSmartDeviceRequestSubtypeEnum,
} from '@clevergy/api-client';
import { MutationKeys } from '@clevergy/shared/constants/mutationKeys';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import {
  skipToken,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AppHeader } from 'components/AppHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';

export const SmartDevicePage: FC = () => {
  const { deviceId, channel } = useParams();
  const { api } = useApiContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectedHouse } = useSelectedHouse();

  const smartDeviceQuery = useQuery({
    queryKey: [
      QueryKeys.GET_SMART_DEVICE,
      { houseId: selectedHouse?.houseId, deviceId, channel },
    ],
    queryFn:
      selectedHouse?.houseId && deviceId
        ? () =>
            api.smartDevices.getSmartDeviceDetails({
              houseId: selectedHouse.houseId,
              deviceId,
              channel: channel ? parseInt(channel) : 0,
            })
        : skipToken,
  });

  const updateSmartDeviceMutation = useMutation({
    mutationKey: [
      MutationKeys.UPDATE_SMART_DEVICE,
      { houseId: selectedHouse?.houseId, deviceId },
    ],
    mutationFn: (payload: UpdateSmartDeviceOperationRequest) => {
      return api.smartDevices.updateSmartDevice(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.GET_SMART_DEVICE,
          { houseId: selectedHouse?.houseId, deviceId },
        ],
      });

      navigate(`/houses/${selectedHouse?.houseId}/smart-devices`);
    },
  });

  const isMutationLoading = useIsMutating({
    mutationKey: [
      MutationKeys.UPDATE_SMART_DEVICE,
      { houseId: selectedHouse?.houseId, deviceId },
    ],
  });

  const handleSubmitDeviceForm = async (data: DeviceFormValues) => {
    updateSmartDeviceMutation.mutate({
      houseId: data.houseId,
      deviceId: data.deviceId,
      channel: data.channel,
      updateSmartDeviceRequest: {
        subtype: data.subtype as UpdateSmartDeviceRequestSubtypeEnum,
      },
    });
  };

  if (smartDeviceQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  if (selectedHouse && !smartDeviceQuery.data) {
    return <SmartDeviceNotFound deviceId={deviceId} channel={channel} />;
  }

  if (selectedHouse && smartDeviceQuery.data) {
    return (
      <>
        <AppHeader backButton title={smartDeviceQuery.data.name} />
        <Wrapper>
          <SmartDeviceForm
            houseId={selectedHouse.houseId}
            device={smartDeviceQuery.data}
            isMutationLoading={isMutationLoading}
            onUpdateSmartDevice={handleSubmitDeviceForm}
          />
        </Wrapper>
      </>
    );
  }
};
