/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { ConsumptionCategory } from './ConsumptionCategory';
import {
  ConsumptionCategoryFromJSON,
  ConsumptionCategoryFromJSONTyped,
  ConsumptionCategoryToJSON,
} from './ConsumptionCategory';

/**
 * Energy consumed by the house
 * @export
 * @interface HouseEnergyConsumptionKwh
 */
export interface HouseEnergyConsumptionKwh {
  /**
   * Consumption organized by category
   * @type {Array<ConsumptionCategory>}
   * @memberof HouseEnergyConsumptionKwh
   */
  categories?: Array<ConsumptionCategory>;
  /**
   * Total consumption in kWh
   * @type {number}
   * @memberof HouseEnergyConsumptionKwh
   */
  total?: number;
}

/**
 * Check if a given object implements the HouseEnergyConsumptionKwh interface.
 */
export function instanceOfHouseEnergyConsumptionKwh(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function HouseEnergyConsumptionKwhFromJSON(
  json: any,
): HouseEnergyConsumptionKwh {
  return HouseEnergyConsumptionKwhFromJSONTyped(json, false);
}

export function HouseEnergyConsumptionKwhFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseEnergyConsumptionKwh {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    categories: !exists(json, 'categories')
      ? undefined
      : (json['categories'] as Array<any>).map(ConsumptionCategoryFromJSON),
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function HouseEnergyConsumptionKwhToJSON(
  value?: HouseEnergyConsumptionKwh | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    categories:
      value.categories === undefined
        ? undefined
        : (value.categories as Array<any>).map(ConsumptionCategoryToJSON),
    total: value.total,
  };
}
