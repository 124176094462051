/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemSolar
 */
export interface EnergyItemSolar {
  /**
   *
   * @type {number}
   * @memberof EnergyItemSolar
   */
  production?: number;
}

/**
 * Check if a given object implements the EnergyItemSolar interface.
 */
export function instanceOfEnergyItemSolar(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EnergyItemSolarFromJSON(json: any): EnergyItemSolar {
  return EnergyItemSolarFromJSONTyped(json, false);
}

export function EnergyItemSolarFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemSolar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    production: !exists(json, 'production') ? undefined : json['production'],
  };
}

export function EnergyItemSolarToJSON(value?: EnergyItemSolar | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    production: value.production,
  };
}
