import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  selectedHouseId: undefined,
};

export type HousePickerStoreState = {
  selectedHouseId: string | undefined;
  setSelectedHouseId: (selectedHouseId: string) => void;
};

export const useHousePickerStore = create<HousePickerStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setSelectedHouseId: (selectedHouseId: string) => set({ selectedHouseId }),
    }),
    {
      name: 'housePickerStoreState',
    },
  ),
);
