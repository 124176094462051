/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface HouseInvoiceSummaryResponse
 */
export interface HouseInvoiceSummaryResponse {
  /**
   * Invoice id
   * @type {string}
   * @memberof HouseInvoiceSummaryResponse
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof HouseInvoiceSummaryResponse
   */
  startDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof HouseInvoiceSummaryResponse
   */
  endDate?: Date;
  /**
   *
   * @type {number}
   * @memberof HouseInvoiceSummaryResponse
   */
  totalCost?: number;
  /**
   *
   * @type {string}
   * @memberof HouseInvoiceSummaryResponse
   */
  urlFile?: string;
}

/**
 * Check if a given object implements the HouseInvoiceSummaryResponse interface.
 */
export function instanceOfHouseInvoiceSummaryResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function HouseInvoiceSummaryResponseFromJSON(
  json: any,
): HouseInvoiceSummaryResponse {
  return HouseInvoiceSummaryResponseFromJSONTyped(json, false);
}

export function HouseInvoiceSummaryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseInvoiceSummaryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    startDate: !exists(json, 'startDate')
      ? undefined
      : new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    totalCost: !exists(json, 'totalCost') ? undefined : json['totalCost'],
    urlFile: !exists(json, 'urlFile') ? undefined : json['urlFile'],
  };
}

export function HouseInvoiceSummaryResponseToJSON(
  value?: HouseInvoiceSummaryResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString(),
    endDate:
      value.endDate === undefined ? undefined : value.endDate.toISOString(),
    totalCost: value.totalCost,
    urlFile: value.urlFile,
  };
}
