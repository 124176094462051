import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { TenantLoadingIndicator, useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { ModuleHeader } from '@clevergy/ui/components/ModuleHeader';
import ComparisonModuleDraft from 'assets/illustrations/comparison-module-draft.svg?react';
import { AppAlerts } from 'components/AppAlerts';
import { AppHeader } from 'components/AppHeader';
import { HousePicker } from 'components/HousePicker';
import { LeadCaptureBanner } from 'components/LeadCaptureBanner';
import { ModuleCard } from 'components/ModuleCard';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { format } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

const tooltipConsumptionCumulative = `
      Si ves aquí <strong>consumos negativos</strong>
      significa que tu producción es mayor que tu consumo.
`;

const tooltipHouseComparison = `
<ul>
  <li>
    <strong>Hogares eficientes:</strong>
    consumo medio de hogares con
    características similares según tu configuración de la cuenta,
    teniendo en cuenta solo el 30% de los hogares con menor consumo.
  </li>
  <li>
    <strong>Hogares similares:</strong>
    consumo medio de hogares con características similares según tu configuración de la cuenta.
  </li>
  <li>
    <strong>Tus vecinos:</strong>
    consumo medio de hogares con el mismo código postal que tú.
  </li>
</ul>
`;

export const HomePage: FC = () => {
  const { token } = useAuthContext();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const { hasEnergyPricesEnabled, tenantId } = useTenantSettings();
  const setHasSkippedSmartMeterIntegration = useAppConfigurationStore(
    (state) => state.setHasSkippedSmartMeterIntegration,
  );

  return (
    <>
      <TrackedPage
        event={{
          event_type: 'page_view',
          event_properties: {
            page_name: 'home',
          },
        }}
      >
        <AppHeader>
          <HousePicker />
        </AppHeader>
        <div className="bg-[#F3F4F6] min-h-full">
          <PullToRefresh />
          <Wrapper>
            {!token && <TenantLoadingIndicator />}

            {token && (
              <>
                <AppAlerts />

                {tenantId === 'smartconsum' && (
                  <LeadCaptureBanner
                    cta={`Con la tarifa TRANQUILIDAD, ten el mantenimiento de tu instalación asegurado`}
                    title="Te llamamos gratis"
                    description="¡Entendido! Para tramitar el cambio de tarifa necesitamos tu teléfono. Un experto se pondrá en contacto contigo para ayudarte a cambiar de tarifa."
                    campaign="tranquilidad"
                  />
                )}

                {selectedHouse && (
                  <clevergy-solar-real-time
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {selectedHouse && (
                  <clevergy-power-chart
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-current-date={format(new Date(), 'yyyy-MM-dd')}
                  />
                )}

                {selectedHouse && (
                  <clevergy-solar-info
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {selectedHouse && (
                  <clevergy-smart-devices
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {hasEnergyPricesEnabled && (
                  <clevergy-energy-prices
                    data-show-energy-price-surplus={
                      selectedHouse?.dataProviders?.solarInverter
                        ? 'true'
                        : 'false'
                    }
                    data-show-energy-price-list={
                      tenantId === 'selectra' ? 'true' : 'false'
                    }
                  />
                )}

                {selectedHouse && (
                  <clevergy-consumption-cumulative
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-tooltip={
                      selectedHouse.dataProviders?.solarInverter
                        ? tooltipConsumptionCumulative
                        : ''
                    }
                  />
                )}

                {selectedHouse && (
                  <clevergy-invoice-viewer
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                  />
                )}

                {selectedHouse &&
                  !userInfo.isCompany &&
                  tenantId !== 'comunidad-solar' && (
                    <clevergy-house-comparison
                      data-token={token}
                      data-house-id={selectedHouse.houseId}
                      data-view="race"
                      data-variant="advanced"
                      data-tooltip={tooltipHouseComparison}
                    />
                  )}

                {(userStatus === UserSetUpUserStatusEnum.Initial ||
                  userStatus === UserSetUpUserStatusEnum.AcceptedConditions) &&
                  tenantId !== 'comunidad-solar' && (
                    <ModuleCard>
                      <ModuleHeader
                        title="Comparador de hogares"
                        subtitle="Compara tu consumo con el de otros hogares similares"
                      />
                      <div className="my-4">
                        <ComparisonModuleDraft />
                      </div>
                      {userStatus === UserSetUpUserStatusEnum.Initial && (
                        <Button
                          variant="filled"
                          fullWidth
                          onClick={() =>
                            setHasSkippedSmartMeterIntegration(false)
                          }
                        >
                          Conectar mis datos de consumo
                        </Button>
                      )}
                    </ModuleCard>
                  )}
              </>
            )}
          </Wrapper>
        </div>
      </TrackedPage>
    </>
  );
};
