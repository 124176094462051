/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  CreateLead,
  DataConsent,
  HttpError,
  UploadDniUrl,
  UploadInvoiceUrl,
  UserAccountDetails,
  UserAppDevice,
  UserExpectations,
  UserPreferencesNotificationRequest,
  UserPreferencesNotificationResponse,
  UserRegistration,
  UserRegistrationCompleted,
  UserSetUp,
} from '../models';
import {
  CreateLeadFromJSON,
  CreateLeadToJSON,
  DataConsentFromJSON,
  DataConsentToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  UploadDniUrlFromJSON,
  UploadDniUrlToJSON,
  UploadInvoiceUrlFromJSON,
  UploadInvoiceUrlToJSON,
  UserAccountDetailsFromJSON,
  UserAccountDetailsToJSON,
  UserAppDeviceFromJSON,
  UserAppDeviceToJSON,
  UserExpectationsFromJSON,
  UserExpectationsToJSON,
  UserPreferencesNotificationRequestFromJSON,
  UserPreferencesNotificationRequestToJSON,
  UserPreferencesNotificationResponseFromJSON,
  UserPreferencesNotificationResponseToJSON,
  UserRegistrationFromJSON,
  UserRegistrationToJSON,
  UserRegistrationCompletedFromJSON,
  UserRegistrationCompletedToJSON,
  UserSetUpFromJSON,
  UserSetUpToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface AcceptDataConsentRequest {
  userId: string;
  dataConsent?: DataConsent;
}

export interface CompleteUserRegistrationRequest {
  userRegistration?: UserRegistrationCompleted;
}

export interface CreateDeleteUserAccountRequestRequest {
  userId: string;
}

export interface CreateLeadRequest {
  userId: string;
  createLead?: CreateLead;
}

export interface CreateUserAppDeviceRequest {
  userId: string;
  userAppDevice?: UserAppDevice;
}

export interface DenyDataConsentRequest {
  userId: string;
}

export interface GetPreferencesNotificationsRequest {
  userId: string;
  type: GetPreferencesNotificationsTypeEnum;
}

export interface GetUploadDniSignedUrlRequest {
  userId: string;
}

export interface GetUploadInvoiceSignedUrlRequest {
  userId: string;
}

export interface GetUserAccountDetailsRequest {
  userId: string;
}

export interface MarkAsValidatingHolderRequest {
  userId: string;
}

export interface MarkAsWaitingAuthorizationRequest {
  userId: string;
}

export interface MarkUserAsCompletedRequest {
  userId: string;
}

export interface RegisterUserRequest {
  user?: UserRegistration;
}

export interface RetrieveUserSetUpRequest {
  userId: string;
}

export interface StoreUserExpectationsRequest {
  userId: string;
  expectations?: UserExpectations;
}

export interface TrackUserAppLoginRequest {
  userId: string;
}

export interface UpdatePreferencesNotificationsRequest {
  userId: string;
  type: UpdatePreferencesNotificationsTypeEnum;
  updateUserPreferencesNotifications?: UserPreferencesNotificationRequest;
}

export interface ValidateDniRequest {
  userId: string;
}

export interface ValidateInvoiceRequest {
  userId: string;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * accepts data consent
   */
  async acceptDataConsentRaw(
    requestParameters: AcceptDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling acceptDataConsent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/data-consent/accept`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DataConsentToJSON(requestParameters.dataConsent),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * accepts data consent
   */
  async acceptDataConsent(
    requestParameters: AcceptDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.acceptDataConsentRaw(requestParameters, initOverrides);
  }

  /**
   * Complete the user data after Firebase registration
   */
  async completeUserRegistrationRaw(
    requestParameters: CompleteUserRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/complete-user-registration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserRegistrationCompletedToJSON(
          requestParameters.userRegistration,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Complete the user data after Firebase registration
   */
  async completeUserRegistration(
    requestParameters: CompleteUserRegistrationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.completeUserRegistrationRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a request to delete user account
   */
  async createDeleteUserAccountRequestRaw(
    requestParameters: CreateDeleteUserAccountRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling createDeleteUserAccountRequest.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/delete-account`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a request to delete user account
   */
  async createDeleteUserAccountRequest(
    requestParameters: CreateDeleteUserAccountRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createDeleteUserAccountRequestRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Creates a lead for a specific user
   */
  async createLeadRaw(
    requestParameters: CreateLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling createLead.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/leads`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateLeadToJSON(requestParameters.createLead),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a lead for a specific user
   */
  async createLead(
    requestParameters: CreateLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createLeadRaw(requestParameters, initOverrides);
  }

  /**
   * Registers an user application device
   */
  async createUserAppDeviceRaw(
    requestParameters: CreateUserAppDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling createUserAppDevice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/app-devices`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserAppDeviceToJSON(requestParameters.userAppDevice),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Registers an user application device
   */
  async createUserAppDevice(
    requestParameters: CreateUserAppDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createUserAppDeviceRaw(requestParameters, initOverrides);
  }

  /**
   * denies data consent
   */
  async denyDataConsentRaw(
    requestParameters: DenyDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling denyDataConsent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/data-consent/deny`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * denies data consent
   */
  async denyDataConsent(
    requestParameters: DenyDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.denyDataConsentRaw(requestParameters, initOverrides);
  }

  /**
   * Get user preferences notifications
   */
  async getPreferencesNotificationsRaw(
    requestParameters: GetPreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserPreferencesNotificationResponse>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getPreferencesNotifications.',
      );
    }

    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling getPreferencesNotifications.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/notifications/{type}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters.userId)),
          )
          .replace(
            `{${'type'}}`,
            encodeURIComponent(String(requestParameters.type)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserPreferencesNotificationResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get user preferences notifications
   */
  async getPreferencesNotifications(
    requestParameters: GetPreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserPreferencesNotificationResponse> {
    const response = await this.getPreferencesNotificationsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns a signed, temporary bucket url to upload a dni image
   */
  async getUploadDniSignedUrlRaw(
    requestParameters: GetUploadDniSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadDniUrl>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getUploadDniSignedUrl.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/upload-dni-url`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadDniUrlFromJSON(jsonValue),
    );
  }

  /**
   * Returns a signed, temporary bucket url to upload a dni image
   */
  async getUploadDniSignedUrl(
    requestParameters: GetUploadDniSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadDniUrl> {
    const response = await this.getUploadDniSignedUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns a signed, temporary bucket url to upload an invoice file
   */
  async getUploadInvoiceSignedUrlRaw(
    requestParameters: GetUploadInvoiceSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadInvoiceUrl>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getUploadInvoiceSignedUrl.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/upload-invoice-url`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadInvoiceUrlFromJSON(jsonValue),
    );
  }

  /**
   * Returns a signed, temporary bucket url to upload an invoice file
   */
  async getUploadInvoiceSignedUrl(
    requestParameters: GetUploadInvoiceSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadInvoiceUrl> {
    const response = await this.getUploadInvoiceSignedUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Obtains user account info
   */
  async getUserAccountDetailsRaw(
    requestParameters: GetUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserAccountDetails>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getUserAccountDetails.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserAccountDetailsFromJSON(jsonValue),
    );
  }

  /**
   * Obtains user account info
   */
  async getUserAccountDetails(
    requestParameters: GetUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserAccountDetails> {
    const response = await this.getUserAccountDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * set user\'s status to VALIDATING_HOLDER
   */
  async markAsValidatingHolderRaw(
    requestParameters: MarkAsValidatingHolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling markAsValidatingHolder.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-validating-holder`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to VALIDATING_HOLDER
   */
  async markAsValidatingHolder(
    requestParameters: MarkAsValidatingHolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markAsValidatingHolderRaw(requestParameters, initOverrides);
  }

  /**
   * set user\'s status to WAITING_AUTHORIZATION
   */
  async markAsWaitingAuthorizationRaw(
    requestParameters: MarkAsWaitingAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling markAsWaitingAuthorization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-waiting-authorization`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to WAITING_AUTHORIZATION
   */
  async markAsWaitingAuthorization(
    requestParameters: MarkAsWaitingAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markAsWaitingAuthorizationRaw(requestParameters, initOverrides);
  }

  /**
   * set user\'s status to COMPLETED
   */
  async markUserAsCompletedRaw(
    requestParameters: MarkUserAsCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling markUserAsCompleted.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-completed`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to COMPLETED
   */
  async markUserAsCompleted(
    requestParameters: MarkUserAsCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markUserAsCompletedRaw(requestParameters, initOverrides);
  }

  /**
   * Save the user from Firebase after registration.
   */
  async registerUserRaw(
    requestParameters: RegisterUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user-registration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserRegistrationToJSON(requestParameters.user),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Save the user from Firebase after registration.
   */
  async registerUser(
    requestParameters: RegisterUserRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.registerUserRaw(requestParameters, initOverrides);
  }

  /**
   * Returns the user setup.
   */
  async retrieveUserSetUpRaw(
    requestParameters: RetrieveUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserSetUp>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling retrieveUserSetUp.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/setup`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSetUpFromJSON(jsonValue),
    );
  }

  /**
   * Returns the user setup.
   */
  async retrieveUserSetUp(
    requestParameters: RetrieveUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserSetUp> {
    const response = await this.retrieveUserSetUpRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Register user expectations
   */
  async storeUserExpectationsRaw(
    requestParameters: StoreUserExpectationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling storeUserExpectations.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/expectations`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserExpectationsToJSON(requestParameters.expectations),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Register user expectations
   */
  async storeUserExpectations(
    requestParameters: StoreUserExpectationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.storeUserExpectationsRaw(requestParameters, initOverrides);
  }

  /**
   * Tracks a user login from app
   */
  async trackUserAppLoginRaw(
    requestParameters: TrackUserAppLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling trackUserAppLogin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/track-app-login`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Tracks a user login from app
   */
  async trackUserAppLogin(
    requestParameters: TrackUserAppLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.trackUserAppLoginRaw(requestParameters, initOverrides);
  }

  /**
   * Save user preferences notification
   */
  async updatePreferencesNotificationsRaw(
    requestParameters: UpdatePreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling updatePreferencesNotifications.',
      );
    }

    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling updatePreferencesNotifications.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/notifications/{type}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters.userId)),
          )
          .replace(
            `{${'type'}}`,
            encodeURIComponent(String(requestParameters.type)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserPreferencesNotificationRequestToJSON(
          requestParameters.updateUserPreferencesNotifications,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Save user preferences notification
   */
  async updatePreferencesNotifications(
    requestParameters: UpdatePreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePreferencesNotificationsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Validates a previously uploaded user\'s dni.
   */
  async validateDniRaw(
    requestParameters: ValidateDniRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling validateDni.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/validate-dni`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validates a previously uploaded user\'s dni.
   */
  async validateDni(
    requestParameters: ValidateDniRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.validateDniRaw(requestParameters, initOverrides);
  }

  /**
   * Validates a previously uploaded user\'s invoice.
   */
  async validateInvoiceRaw(
    requestParameters: ValidateInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling validateInvoice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/validate-invoice`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validates a previously uploaded user\'s invoice.
   */
  async validateInvoice(
    requestParameters: ValidateInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.validateInvoiceRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const GetPreferencesNotificationsTypeEnum = {
  ConsumptionReport: 'CONSUMPTION_REPORT',
  Export: 'EXPORT',
} as const;
export type GetPreferencesNotificationsTypeEnum =
  (typeof GetPreferencesNotificationsTypeEnum)[keyof typeof GetPreferencesNotificationsTypeEnum];
/**
 * @export
 */
export const UpdatePreferencesNotificationsTypeEnum = {
  Export: 'EXPORT',
  ConsumptionReport: 'CONSUMPTION_REPORT',
} as const;
export type UpdatePreferencesNotificationsTypeEnum =
  (typeof UpdatePreferencesNotificationsTypeEnum)[keyof typeof UpdatePreferencesNotificationsTypeEnum];
