import { useModalContext } from '../../context/ModalContext';
import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

export type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  fitContent?: boolean;
  inverted?: boolean;
};

export const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  fitContent = false,
  inverted = false,
}) => {
  const { modalRenderElement } = useModalContext();
  if (!modalRenderElement) {
    throw Error('modalRenderElement is required');
  }

  const safeAreaStyles = {
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    paddingTop: 'max(env(safe-area-inset-top), 16px)',
    paddingLeft: 'max(env(safe-area-inset-left), 16px)',
    paddingRight: 'max(env(safe-area-inset-right), 16px)',
  };

  const style = {
    ...(!fitContent ? safeAreaStyles : {}),
  };

  // Prevent scrolling on the body when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen, modalRenderElement]);

  const ModalContent = (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50" role="dialog" data-testid="modal">
          <motion.div
            className="fixed inset-0 bg-black opacity-70"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
            data-testid="modal-background"
            onClick={onClose}
            onKeyDown={onClose}
            tabIndex={-1}
            role="button"
          />
          <motion.div
            className={clsx('fixed inset-0 pointer-events-none', {
              'p-0 md:p-4': !fitContent,
              'p-4': fitContent,
            })}
            style={fitContent ? safeAreaStyles : undefined}
            initial={{ opacity: 0, y: 50, scale: 0.98 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 50, scale: 0.98 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            data-testid="modal-container"
          >
            <div
              className={clsx(
                'relative pointer-events-auto overflow-y-auto md:max-w-2xl mx-auto max-h-full md:h-auto top-1/2 -translate-y-1/2',
                {
                  'w-fit rounded-xl p-4': fitContent,
                  'h-full md:rounded-xl px-4': !fitContent,
                  'bg-white text-clevergy-text font-sans text-base': !inverted,
                  'bg-clevergy-text text-white font-sans text-base': inverted,
                },
              )}
              style={style}
            >
              {onClose && (
                <div className="sticky top-0 z-10">
                  <button
                    type="button"
                    data-testid="modal-close"
                    onClick={onClose}
                    className={clsx('absolute right-0', {
                      'text-clevergy-primary': !inverted,
                      'text-white': inverted,
                    })}
                  >
                    <FontIcon name="close" />
                  </button>
                </div>
              )}
              <div
                className={clsx('h-full', {
                  ' pt-6': onClose,
                })}
              >
                {children}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );

  return <>{createPortal(ModalContent, modalRenderElement)}</>;
};
