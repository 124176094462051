/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { HouseEnergy } from './HouseEnergy';
import {
  HouseEnergyFromJSON,
  HouseEnergyFromJSONTyped,
  HouseEnergyToJSON,
} from './HouseEnergy';

/**
 *
 * @export
 * @interface HouseDataEnergy
 */
export interface HouseDataEnergy {
  /**
   *
   * @type {Array<HouseEnergy>}
   * @memberof HouseDataEnergy
   */
  data?: Array<HouseEnergy>;
  /**
   *
   * @type {string}
   * @memberof HouseDataEnergy
   */
  rateType?: HouseDataEnergyRateTypeEnum;
}

/**
 * @export
 */
export const HouseDataEnergyRateTypeEnum = {
  Regulated: 'REGULATED',
  Pymes: 'PYMES',
} as const;
export type HouseDataEnergyRateTypeEnum =
  (typeof HouseDataEnergyRateTypeEnum)[keyof typeof HouseDataEnergyRateTypeEnum];

/**
 * Check if a given object implements the HouseDataEnergy interface.
 */
export function instanceOfHouseDataEnergy(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function HouseDataEnergyFromJSON(json: any): HouseDataEnergy {
  return HouseDataEnergyFromJSONTyped(json, false);
}

export function HouseDataEnergyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseDataEnergy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : (json['data'] as Array<any>).map(HouseEnergyFromJSON),
    rateType: !exists(json, 'rateType') ? undefined : json['rateType'],
  };
}

export function HouseDataEnergyToJSON(value?: HouseDataEnergy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : (value.data as Array<any>).map(HouseEnergyToJSON),
    rateType: value.rateType,
  };
}
