import { TenantPageIntroWithLogo } from '@clevergy/tenants';
import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { Wrapper } from 'components/Wrapper';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthError, AuthService } from 'services/AuthService';

type LoginFormValues = {
  email: string;
  password: string;
  customError?: string;
};

export const LoginForAlexaPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAlexaAuthorization } = useUserSetupContext();
  const [searchParams] = useSearchParams();

  const { register, handleSubmit, setError, clearErrors, formState } =
    useForm<LoginFormValues>();

  const onSignInWithEmailAndPasswordSubmit = async (data: LoginFormValues) => {
    try {
      await AuthService.signInWithEmailAndPassword(data.email, data.password);
      navigate('/', { replace: true });
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        error.message === AuthError.INVALID_CREDENTIALS
      ) {
        setError('customError', {
          type: 'serverSideError',
          message: t('login.error') as string,
        });
      }
    }
  };

  useEffect(() => {
    const state = searchParams.get('state');
    const redirect_uri = searchParams.get('redirect_uri');

    if (!state || !redirect_uri) return;

    setAlexaAuthorization({
      state,
      redirect_uri,
    });
  }, [searchParams, setAlexaAuthorization]);

  return (
    <Wrapper fullHeight safeArea>
      <div className="flex h-full flex-col gap-6">
        <div className="flex grow flex-col justify-center">
          <TenantPageIntroWithLogo title={t('login.title') as string} />
        </div>
        <div>
          <form
            onSubmit={handleSubmit(onSignInWithEmailAndPasswordSubmit)}
            className="my-6 flex flex-col gap-4"
          >
            <InputText
              id="email"
              type="email"
              label={t('login.email.input') as string}
              hiddenLabel
              placeholder={t('Tu Email')}
              {...register('email', {
                required: t('login.email.needed') as string,
                validate: (value) =>
                  isValidEmail(value) || (t('login.email.invalid') as string),
              })}
              error={formState.errors.email?.message as string}
            />

            <InputText
              id="password"
              type="password"
              label={t('login.password.label') as string}
              hiddenLabel
              placeholder={t('login.password.input')}
              {...register('password', {
                required: t('login.password.needed') as string,
                minLength: {
                  value: 6,
                  message: t('login.password.requirement') as string,
                },
              })}
              error={formState.errors.password?.message as string}
            />

            {formState.errors.customError && (
              <Alert
                type="error"
                text={t(formState.errors.customError.message as string)}
                actions={
                  <Button
                    type="submit"
                    variant="filled"
                    fullWidth
                    busy={formState.isSubmitting}
                    onClick={() => clearErrors()}
                  >
                    {t('login.email.continue')}
                  </Button>
                }
              />
            )}

            <Button type="submit" variant="filled" fullWidth>
              {t('login.email.continue-with-email')}
            </Button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};
