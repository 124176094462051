import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import { useTenantSettings } from '@clevergy/tenants';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { splitDisplayName } from '@clevergy/utils/format/splitDisplayName';
import { AppHeader } from 'components/AppHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

export const SmartMeterIntegrationPage: FC = () => {
  const { t } = useTranslation();
  const { token, authedUser } = useAuthContext();
  const { consumptionDataConsentInfo, hasSignUpForCompaniesEnabled } =
    useTenantSettings();
  const { refetchSetup } = useUserSetupContext();

  const { setHasSkippedSmartMeterIntegration } = useAppConfigurationStore();

  const [isWaitingForUserStatusRedirect, setIsWaitingForUserStatusRedirect] =
    useState(false);

  useClevergyEvent(ClevergyEventTypes.INTEGRATION_SMARTMETER_SKIPPED, () => {
    if (window.confirm(t('smartmeter.skipConfirmation') as string)) {
      setHasSkippedSmartMeterIntegration(true);
    }
  });

  useClevergyEvent(
    ClevergyEventTypes.INTEGRATION_SMARTMETER_COMPLETED,
    async () => {
      setIsWaitingForUserStatusRedirect(true);
      await refetchSetup();
    },
  );

  const { firstName, lastName } = splitDisplayName(authedUser?.displayName);

  if (isWaitingForUserStatusRedirect) {
    return <LoadingIndicator fullScreen="overlay" />;
  }

  return (
    <>
      <AppHeader title={t('smartmeter.title') as string} />
      <Wrapper safeArea={['bottom']}>
        {token && (
          <clevergy-integration-smartmeter
            data-token={token}
            data-consent-name={consumptionDataConsentInfo.legalName}
            data-opt-out-email={consumptionDataConsentInfo.email}
            data-user-firstname={firstName}
            data-user-lastname={lastName}
            data-companies-enabled={hasSignUpForCompaniesEnabled}
          />
        )}
      </Wrapper>
    </>
  );
};
