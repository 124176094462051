/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarRecommendation
 */
export interface SolarRecommendation {
  /**
   *
   * @type {boolean}
   * @memberof SolarRecommendation
   */
  isSolarRecommended?: boolean;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  totalKwh?: number;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  kwhInSunHours?: number;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  percentageOfKwhInSunHours?: number;
}

/**
 * Check if a given object implements the SolarRecommendation interface.
 */
export function instanceOfSolarRecommendation(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SolarRecommendationFromJSON(json: any): SolarRecommendation {
  return SolarRecommendationFromJSONTyped(json, false);
}

export function SolarRecommendationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarRecommendation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isSolarRecommended: !exists(json, 'isSolarRecommended')
      ? undefined
      : json['isSolarRecommended'],
    totalKwh: !exists(json, 'totalKwh') ? undefined : json['totalKwh'],
    kwhInSunHours: !exists(json, 'kwhInSunHours')
      ? undefined
      : json['kwhInSunHours'],
    percentageOfKwhInSunHours: !exists(json, 'percentageOfKwhInSunHours')
      ? undefined
      : json['percentageOfKwhInSunHours'],
  };
}

export function SolarRecommendationToJSON(
  value?: SolarRecommendation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isSolarRecommended: value.isSolarRecommended,
    totalKwh: value.totalKwh,
    kwhInSunHours: value.kwhInSunHours,
    percentageOfKwhInSunHours: value.percentageOfKwhInSunHours,
  };
}
