import { CreateLeadLeadTypeEnum } from '@clevergy/api-client';
import { TenantPrivacyPolicy } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { InputCheck } from '@clevergy/ui/components/InputCheck';
import { InputText } from '@clevergy/ui/components/InputText';
import { Modal } from '@clevergy/ui/components/Modal';
import { formatOnlyNumericCharacters } from '@clevergy/utils/format/formatOnlyNumericCharacters';
import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type LeadCaptureFormProps = {
  title: string;
  description: string;
  campaign: string;
  onFinish: () => void;
};

type LeadCaptureFormSchema = {
  phoneNumber: string;
  consentDataUsage: boolean;
};

export const LeadCaptureForm: FC<LeadCaptureFormProps> = (props) => {
  const { title, description, campaign, onFinish } = props;

  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const leadType = CreateLeadLeadTypeEnum.Banner;

  const createLeadMutation = useMutation({
    mutationKey: ['createLead', leadType],
    mutationFn: (params: LeadCaptureFormSchema) => {
      const { phoneNumber } = params;
      const { uid, email, tenantId } = authedUser || {};
      return api.users.createLead({
        userId: uid || '',
        createLead: {
          leadType,
          payload: {
            email: email || '',
            tenant: tenantId || '',
            phoneNumber,
            campaign,
          },
        },
      });
    },
    onSuccess: () => {
      onFinish();
    },
  });

  const isMutationLoading = useIsMutating({
    mutationKey: ['createLead', leadType],
  });

  const [hasConsentChecked, setHasConsentChecked] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

  const { register, handleSubmit, formState, clearErrors, setValue } =
    useForm<LeadCaptureFormSchema>();

  const handleSubmitLeadCaptureForm = (data: LeadCaptureFormSchema) => {
    // TODO: Add mutation to create lead
    createLeadMutation.mutate(data);
    onFinish();
  };

  return (
    <div className="h-full flex flex-col justify-center">
      <form
        onSubmit={handleSubmit(handleSubmitLeadCaptureForm)}
        className="flex w-full flex-col gap-4 items-center justify-center"
      >
        {title && <h2 className="text-xl font-semibold">{title}</h2>}
        {description && <p>{description}</p>}

        <InputText
          id="phoneNumber"
          type="number"
          autoComplete="off"
          label={t('leadCapture.form.label') as string}
          placeholder={t('leadCapture.form.placeholder')}
          error={formState.errors.phoneNumber?.message}
          {...register('phoneNumber', {
            required: t('leadCapture.form.required.phone') as string,
            validate: (value) =>
              value.length >= 9 ||
              (t('leadCapture.form.validate.phoneLength') as string),
            onChange: (e) =>
              setValue(
                'phoneNumber',
                formatOnlyNumericCharacters(e.target.value),
              ),
          })}
        />

        <div className="text-sm text-gray-500">
          <InputCheck
            id="consentDataUsage"
            label={t('leadCapture.form.checkbox')}
            isChecked={hasConsentChecked}
            error={formState.errors.consentDataUsage?.message}
            {...register('consentDataUsage', {
              onChange: (e) => setHasConsentChecked(e.target.checked),
              required: t('leadCapture.form.required.checkbox') as string,
            })}
          />
        </div>

        <div className="text-sm text-gray-500">
          {t('leadCapture.privacyPolicy.text1')}{' '}
          <button
            type="button"
            className="text-clevergy-primary"
            onClick={() => setShowPrivacyPolicyModal(true)}
          >
            {t('leadCapture.privacyPolicy.text2')}
          </button>
        </div>
        <Button
          fullWidth
          type="submit"
          disabled={
            formState.isSubmitting ||
            !formState.isValid ||
            Boolean(isMutationLoading)
          }
          busy={formState.isSubmitting || Boolean(isMutationLoading)}
          onClick={() => clearErrors()}
        >
          {t('leadCapture.form.button')}
        </Button>
      </form>

      <Modal
        isOpen={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      >
        <TenantPrivacyPolicy />
      </Modal>
    </div>
  );
};
