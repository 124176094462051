export const QueryKeys = {
  CONSUMPTION_DETAILS: 'consumptionDetails',
  CONSUMPTION_RANGE: 'consumptionRange',
  FIND_HOUSE_ENERGY: 'findHouseEnergy',
  /** @deprecated */
  FIND_HOUSE_SUPPLIES_ENERGY: 'findHouseSuppliesEnergy',
  GET_REAL_TIME_POWER: 'getRealTimePower',
  GET_BATTERY_REAL_TIME: 'getBatteryRealTime',
  GET_POWER: 'getPower',
  GET_USER_SETUP: 'getUserSetup',
  GET_TIPS: 'getTips',
  GET_HOUSE_SETTINGS: 'getHouseSettings',
  GET_USER_ACCOUNT_INFO: 'getUserAccountInfo',
  GET_USER_PREFERENCES_NOTIFICATIONS: 'getUserPreferencesNotifications',
  GET_CONSUMPTION_CUMULATIVE: 'getConsumptionCumulative',
  GET_HOUSE_DEVICES: 'getHouseDevices',
  GET_RULE_PARAMS: 'getRuleParams',
  HOUSE_COMPARISON: 'houseComparison',
  HOUSE_CONTRACT: 'houseContract',
  RECOMMENDED_RATE: 'recommendedRate',
  GET_OPTIMIZE_RECOMMENDATIONS: 'getOptimizeRecommendations',
  SOLAR_INFO: 'solarInfo',
  SOLAR_RECOMMENDATION: 'solarRecommendation',
  SOLAR_RECOMMENDATION_CHART: 'solarRecommendationChart',
  REGISTERED_HOUSE_DEVICES: 'registeredHouseDevices',
  GET_INVOICES_FOR_HOUSE: 'getInvoicesForHouse',
  GET_INVOICES_PAGE_FOR_HOUSE: 'getInvoicesPageForHouse',
  GET_INVOICES_PAGE_FILTER: 'getInvoicesPageFilter',
  GET_ENERGY_PRICES_CONSUMPTIONS: 'getEnergyPricesConsumptions',
  GET_ENERGY_PRICES_SURPLUS: 'getEnergyPricesSurplus',
  GET_ENERGY_PRICES_AVG: 'getEnergyPricesAvg',
  GET_ENERGY_PRICES_MAX: 'getEnergyPricesMax',
  GET_ENERGY_PRICES_MIN: 'getEnergyPricesMin',
  GET_SUPPORT_CUSTOM_ISSUES_LIST: 'getSupportCustomIssuesList',
  GET_TICKETS: 'getTickets',
  GET_TICKET: 'getTicket',
  GET_SMART_DEVICE: 'getSmartDevice',
  GET_SMART_DEVICES: 'getSmartDevices',
  GET_HOUSE_LAST_INVOICE: 'getHouseLastInvoice',
  TENANT_SETTINGS_FROM_DOMAIN: 'tenantSettingsFromDomain',
  TENANT_SETTINGS_FROM_STORE: 'tenantSettingsFromStore',
  TENANT_SETTINGS_FROM_TOKEN: 'tenantSettingsFromToken',
  DEFAULT_TENANT_SETTINGS: 'defaultTenantSettings',
  DEFAULT_MULTI_TENANT_SETTINGS: 'defaultMultiTenantSettings',
} as const;
