/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConnectWithAlexaRequest
 */
export interface ConnectWithAlexaRequest {
  /**
   *
   * @type {string}
   * @memberof ConnectWithAlexaRequest
   */
  authCode: string;
  /**
   *
   * @type {string}
   * @memberof ConnectWithAlexaRequest
   */
  cups: string;
}

/**
 * Check if a given object implements the ConnectWithAlexaRequest interface.
 */
export function instanceOfConnectWithAlexaRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'authCode' in value;
  isInstance = isInstance && 'cups' in value;

  return isInstance;
}

export function ConnectWithAlexaRequestFromJSON(
  json: any,
): ConnectWithAlexaRequest {
  return ConnectWithAlexaRequestFromJSONTyped(json, false);
}

export function ConnectWithAlexaRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConnectWithAlexaRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    authCode: json['authCode'],
    cups: json['cups'],
  };
}

export function ConnectWithAlexaRequestToJSON(
  value?: ConnectWithAlexaRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    authCode: value.authCode,
    cups: value.cups,
  };
}
