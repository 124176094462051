import { FC, ReactNode } from 'react';

export type ModuleCardProps = {
  children: ReactNode;
};

export const ModuleCard: FC<ModuleCardProps> = ({ children }) => {
  return (
    <div
      style={{
        background: 'var(--clevergy-module-container-background)',
        border: 'var(--clevergy-module-container-border)',
        borderRadius: 'var(--clevergy-module-container-border-radius)',
        boxShadow: 'var(--clevergy-module-container-box-shadow)',
        padding: 'var(--clevergy-module-container-padding)',
        margin: 'var(--clevergy-module-container-margin)',
      }}
    >
      {children}
    </div>
  );
};
