/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from './Comment';

/**
 * Ticket information
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   * Ticket identifier
   * @type {string}
   * @memberof Ticket
   */
  id: string;
  /**
   * Ticket creation date
   * @type {string}
   * @memberof Ticket
   */
  createdAt: string;
  /**
   * Ticket last update date
   * @type {string}
   * @memberof Ticket
   */
  updatedAt: string;
  /**
   * Ticket type
   * @type {string}
   * @memberof Ticket
   */
  type: string;
  /**
   * Ticket subject
   * @type {string}
   * @memberof Ticket
   */
  subject: string;
  /**
   * Ticket description
   * @type {string}
   * @memberof Ticket
   */
  description: string;
  /**
   * Ticket status
   * @type {string}
   * @memberof Ticket
   */
  status: TicketStatusEnum;
  /**
   * Tenant GCP identifier
   * @type {string}
   * @memberof Ticket
   */
  tenantGcpId: string;
  /**
   * User identifier
   * @type {string}
   * @memberof Ticket
   */
  userId: string;
  /**
   * House identifier
   * @type {string}
   * @memberof Ticket
   */
  houseId: string;
  /**
   * User email
   * @type {string}
   * @memberof Ticket
   */
  email: string;
  /**
   * User full name
   * @type {string}
   * @memberof Ticket
   */
  fullName: string;
  /**
   * List of comments
   * @type {Array<Comment>}
   * @memberof Ticket
   */
  comments?: Array<Comment>;
}

/**
 * @export
 */
export const TicketStatusEnum = {
  New: 'new',
  Pending: 'pending',
  Open: 'open',
  Solved: 'solved',
} as const;
export type TicketStatusEnum =
  (typeof TicketStatusEnum)[keyof typeof TicketStatusEnum];

/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'createdAt' in value;
  isInstance = isInstance && 'updatedAt' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'subject' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'tenantGcpId' in value;
  isInstance = isInstance && 'userId' in value;
  isInstance = isInstance && 'houseId' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'fullName' in value;

  return isInstance;
}

export function TicketFromJSON(json: any): Ticket {
  return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Ticket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: json['createdAt'],
    updatedAt: json['updatedAt'],
    type: json['type'],
    subject: json['subject'],
    description: json['description'],
    status: json['status'],
    tenantGcpId: json['tenantGcpId'],
    userId: json['userId'],
    houseId: json['houseId'],
    email: json['email'],
    fullName: json['fullName'],
    comments: !exists(json, 'comments')
      ? undefined
      : (json['comments'] as Array<any>).map(CommentFromJSON),
  };
}

export function TicketToJSON(value?: Ticket | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt,
    updatedAt: value.updatedAt,
    type: value.type,
    subject: value.subject,
    description: value.description,
    status: value.status,
    tenantGcpId: value.tenantGcpId,
    userId: value.userId,
    houseId: value.houseId,
    email: value.email,
    fullName: value.fullName,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
  };
}
