/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserPreferencesNotificationRequest
 */
export interface UserPreferencesNotificationRequest {
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationRequest
   */
  push?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationRequest
   */
  email?: boolean;
}

/**
 * Check if a given object implements the UserPreferencesNotificationRequest interface.
 */
export function instanceOfUserPreferencesNotificationRequest(
  value: object,
): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserPreferencesNotificationRequestFromJSON(
  json: any,
): UserPreferencesNotificationRequest {
  return UserPreferencesNotificationRequestFromJSONTyped(json, false);
}

export function UserPreferencesNotificationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserPreferencesNotificationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    push: !exists(json, 'push') ? undefined : json['push'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function UserPreferencesNotificationRequestToJSON(
  value?: UserPreferencesNotificationRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    push: value.push,
    email: value.email,
  };
}
