import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';

export type AppHeaderProps = {
  title?: string;
  backButton?: boolean;
  onBackButton?: () => void;
  backButtonPath?: string;
  children?: ReactNode;
};

export const AppHeader: FC<AppHeaderProps> = ({
  title,
  children,
  onBackButton,
  backButtonPath,
  backButton = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButton = () => {
    if (onBackButton) {
      onBackButton();
      return;
    }

    if (location.key !== 'default') {
      typeof backButtonPath === 'string'
        ? navigate(backButtonPath)
        : navigate(-1);
    } else {
      navigate('/');
    }
  };

  if (!backButton && !title && !children) {
    return null;
  }

  return (
    <div
      className="sticky top-0 z-10 bg-white border-b border-[#eeeef1]"
      style={{
        paddingTop: 'max(env(safe-area-inset-top), 8px)',
        paddingRight: 'max(env(safe-area-inset-right), 16px)',
        paddingLeft: 'max(env(safe-area-inset-left), 16px)',
        paddingBottom: '8px',
      }}
    >
      <div className="grid grid-cols-[1fr_auto_1fr] gap-2">
        {backButton ? (
          <button
            type="button"
            className="text-left"
            onClick={handleBackButton}
          >
            <FontIcon name="arrow_back_ios_new" />
          </button>
        ) : (
          <span></span>
        )}
        {title && (
          <h2 className="text-xl font-semibold text-center py-2">{title}</h2>
        )}
      </div>
      {children && <>{children}</>}
    </div>
  );
};
