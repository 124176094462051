import SurplusIllustration from '../assets/surplus-illustration.svg?react';
import { AerothermalModal } from './AerothermalModal';
import { BatteryModal } from './BatteryModal';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { TenantIcon } from '@clevergy/tenants';
import { Card } from '@clevergy/ui/components/Card';
import { ModuleHeader } from '@clevergy/ui/components/ModuleHeader';
import { formatNumber } from '@clevergy/utils/format/formatNumber';
import { skipToken, useQuery } from '@tanstack/react-query';
import { ModuleCard } from 'components/ModuleCard';
import { useApiContext } from 'context/ApiContext';
import { format, startOfDay, subDays, subMonths } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const SurplusRecommendations: FC = () => {
  const { api } = useApiContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { selectedHouse } = useSelectedHouse();
  const [showAerothermalModal, setShowAerothermalModal] = useState(false);
  const [showBatteryModal, setShowBatteryModal] = useState(false);

  const hasSolar = Boolean(selectedHouse?.dataProviders?.solarInverter);
  const timeZone = selectedHouse?.timeZone || 'Etc/UTC';

  const endDateZoned = subDays(new Date(), 1);
  const startDateZoned = startOfDay(subMonths(endDateZoned, 1));
  const endDateUTC = fromZonedTime(endDateZoned, timeZone);
  const startDateUTC = fromZonedTime(startDateZoned, timeZone);

  const solarInfoQuery = useQuery({
    queryKey: [
      QueryKeys.SOLAR_INFO,
      {
        houseId: selectedHouse?.houseId,
        startDate: format(startDateZoned, 'd MMM yyyy'),
        endDate: format(endDateZoned, 'd MMM yyyy'),
      },
    ],
    queryFn:
      hasSolar && selectedHouse?.houseId
        ? () =>
            api.solar.getSolarInfo({
              houseId: selectedHouse.houseId,
              startDate: startDateUTC,
              endDate: endDateUTC,
            })
        : skipToken,
  });

  const getHouseSettingsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_HOUSE_SETTINGS,
      { houseId: selectedHouse?.houseId },
    ],
    queryFn:
      hasSolar && selectedHouse?.houseId
        ? () =>
            api.settings.getHouseSettings({ houseId: selectedHouse.houseId })
        : skipToken,
  });

  const hasSurplus =
    (solarInfoQuery.data?.exportOverProductionPercentage || 0) >= 20;

  const hasBattery = getHouseSettingsQuery.data?.devices?.includes('BATTERY');
  const hasAerothermal =
    getHouseSettingsQuery.data?.devices?.includes('AEROTHERMAL');

  if (!hasSolar || !hasSurplus || (hasBattery && hasAerothermal)) {
    return null;
  }

  const exportOverProductionPercentage = formatNumber({
    value: solarInfoQuery.data?.exportOverProductionPercentage || 0,
    language,
    precision: 0,
  }).formattedValue;

  return (
    <ModuleCard>
      <ModuleHeader title={t('optimize.recommendations.banner.title')} />
      <div className="flex flex-col gap-2 items-center mb-4">
        <SurplusIllustration />
        <p>
          <Trans t={t} values={{ percentage: exportOverProductionPercentage }}>
            optimize.recommendations.banner.message
          </Trans>
        </p>
      </div>

      <div className="w-full flex gap-2 flex-nowrap overflow-x-auto md:!grid md:grid-cols-2 lg:!grid-cols-3">
        {!hasBattery && (
          <div className="min-w-[80vw] md:min-w-min">
            <Card
              withBorder
              icon={<TenantIcon name="DeviceBattery" inline />}
              title={t('optimize.recommendations.tips.battery.title') as string}
              text={
                t('optimize.recommendations.tips.battery.message') as string
              }
              actionButton={{
                label: t('optimize.recommendations.tips.battery.cta'),
                onClick: () => setShowBatteryModal(true),
              }}
            />
          </div>
        )}

        {!hasAerothermal && (
          <div className="min-w-[80vw] md:min-w-min">
            <Card
              withBorder
              icon={<TenantIcon name="DeviceAerothermal" inline />}
              title={
                t('optimize.recommendations.tips.aerothermal.title') as string
              }
              text={
                t('optimize.recommendations.tips.aerothermal.message') as string
              }
              actionButton={{
                label: t('optimize.recommendations.tips.aerothermal.cta'),
                onClick: () => setShowAerothermalModal(true),
              }}
            />
          </div>
        )}
      </div>

      {!hasBattery && (
        <BatteryModal
          isOpen={showBatteryModal}
          onClose={() => setShowBatteryModal(false)}
        />
      )}

      {!hasAerothermal && (
        <AerothermalModal
          isOpen={showAerothermalModal}
          onClose={() => setShowAerothermalModal(false)}
        />
      )}
    </ModuleCard>
  );
};
