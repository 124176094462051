/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface DataProviderInfo
 */
export interface DataProviderInfo {
  /**
   *
   * @type {Date}
   * @memberof DataProviderInfo
   */
  firstDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof DataProviderInfo
   */
  lastDate?: Date;
  /**
   *
   * @type {string}
   * @memberof DataProviderInfo
   */
  type?: DataProviderInfoTypeEnum;
}

/**
 * @export
 */
export const DataProviderInfoTypeEnum = {
  RemoteSelfConsumption: 'REMOTE_SELF_CONSUMPTION',
  CollectiveSelfConsumption: 'COLLECTIVE_SELF_CONSUMPTION',
  EnergyCommunity: 'ENERGY_COMMUNITY',
} as const;
export type DataProviderInfoTypeEnum =
  (typeof DataProviderInfoTypeEnum)[keyof typeof DataProviderInfoTypeEnum];

/**
 * Check if a given object implements the DataProviderInfo interface.
 */
export function instanceOfDataProviderInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DataProviderInfoFromJSON(json: any): DataProviderInfo {
  return DataProviderInfoFromJSONTyped(json, false);
}

export function DataProviderInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataProviderInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstDate: !exists(json, 'firstDate')
      ? undefined
      : new Date(json['firstDate']),
    lastDate: !exists(json, 'lastDate')
      ? undefined
      : new Date(json['lastDate']),
    type: !exists(json, 'type') ? undefined : json['type'],
  };
}

export function DataProviderInfoToJSON(value?: DataProviderInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstDate:
      value.firstDate === undefined ? undefined : value.firstDate.toISOString(),
    lastDate:
      value.lastDate === undefined ? undefined : value.lastDate.toISOString(),
    type: value.type,
  };
}
