import BgGradient from '../assets/bg-gradient.svg?react';
import Step1Illustration from '../assets/tutorial-step-1.svg?react';
import Step2Illustration from '../assets/tutorial-step-2.svg?react';
import Step3Illustration from '../assets/tutorial-step-3.svg?react';
import { TrackedButton } from '@clevergy/amplitude/components/TrackedButton';
import {
  TrackedEvent,
  useAnalyticsContext,
} from '@clevergy/amplitude/context/AnalyticsContext';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { StepsMini } from '@clevergy/ui/components/StepsMini';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TUTORIAL_STEPS = 3;

const TUTORIAL_STEPS_ANALYTICS_EVENT: Record<number, TrackedEvent> = {
  1: {
    event_type: 'onboarding_lesson',
    event_properties: {
      lesson: 'consumption',
    },
  },
  2: {
    event_type: 'onboarding_lesson',
    event_properties: {
      lesson: 'disaggregation',
    },
  },
  3: {
    event_type: 'onboarding_lesson',
    event_properties: {
      lesson: 'recommendations',
    },
  },
};

export type TutorialStepsProps = {
  onSkip: () => void;
  onComplete: () => void;
};

export const TutorialSteps: FC<TutorialStepsProps> = (props) => {
  const { onSkip, onComplete } = props;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const { track } = useAnalyticsContext();

  const sliderLeftPosition = (currentStep - 1) * 100;

  const handleGoStepBack = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleGoStepForward = () => {
    if (currentStep >= TUTORIAL_STEPS) {
      onComplete();
    } else {
      setCurrentStep(currentStep + 1);
      track(TUTORIAL_STEPS_ANALYTICS_EVENT[currentStep + 1]);
    }
  };

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="flex h-full w-full flex-col gap-4">
        <div className="relative w-full pointer-events-none grow">
          <div
            className="absolute bottom-0 flex h-full w-full transition-all duration-700 ease-in-out"
            style={{
              left: `-${sliderLeftPosition}%`,
              width: `${TUTORIAL_STEPS * 100}%`,
            }}
          >
            <BgGradient className="absolute inset-0" />
            <div className="flex w-full h-full flex-col">
              <div className="relative grow">
                <div className="absolute bottom-0 flex w-full justify-center">
                  <Step1Illustration />
                </div>
              </div>
              <div className="min-h-[35%] px-4 flex items-center justify-center text-center">
                <Heading as="h3" colorScheme="neutral">
                  <Trans>tutorial.steps.step1.title</Trans>
                </Heading>
              </div>
            </div>

            <div className="flex w-full h-full flex-col">
              <div className="relative grow">
                <div className="absolute bottom-0 flex w-full justify-center">
                  <Step2Illustration />
                </div>
              </div>
              <div className="min-h-[35%] px-4 flex items-center justify-center text-center">
                <Heading as="h3" colorScheme="neutral">
                  <Trans>tutorial.steps.step2.title</Trans>
                </Heading>
              </div>
            </div>

            <div className="flex w-full h-full flex-col">
              <div className="relative grow">
                <div className="absolute bottom-0 flex w-full justify-center">
                  <Step3Illustration />
                </div>
              </div>
              <div className="min-h-[35%] px-4 flex items-center justify-center text-center">
                <Heading as="h3" colorScheme="neutral">
                  <Trans>tutorial.steps.step3.title</Trans>
                </Heading>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="flex w-full items-center px-4">
            <div className="grow">
              <StepsMini numberOfSteps={3} currentStep={currentStep} />
            </div>
            <div className="flex gap-2">
              {currentStep > 1 && (
                <Button onClick={handleGoStepBack}>
                  <FontIcon name="arrow_back_ios_new" />
                </Button>
              )}
              <Button onClick={handleGoStepForward}>
                {currentStep === 1 ? (
                  t('tutorial.steps.next')
                ) : currentStep === TUTORIAL_STEPS ? (
                  t('tutorial.steps.finish')
                ) : (
                  <FontIcon name="arrow_forward_ios" />
                )}
              </Button>
            </div>
          </div>
          <div className="text-clevergy-primary flex justify-center py-4">
            <TrackedButton
              event={{
                event_type: 'onboarding_skipped',
                user_properties: {
                  onboarding_lessons: false,
                },
              }}
              variant="flat"
              onClick={currentStep >= TUTORIAL_STEPS ? undefined : onSkip}
            >
              {t('tutorial.steps.skip')}
            </TrackedButton>
          </div>
        </footer>
      </div>
    </div>
  );
};
