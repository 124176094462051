/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserExpectations
 */
export interface UserExpectations {
  /**
   *
   * @type {Array<string>}
   * @memberof UserExpectations
   */
  expectations?: Array<string>;
}

/**
 * Check if a given object implements the UserExpectations interface.
 */
export function instanceOfUserExpectations(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserExpectationsFromJSON(json: any): UserExpectations {
  return UserExpectationsFromJSONTyped(json, false);
}

export function UserExpectationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserExpectations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expectations: !exists(json, 'expectations')
      ? undefined
      : json['expectations'],
  };
}

export function UserExpectationsToJSON(value?: UserExpectations | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    expectations: value.expectations,
  };
}
