import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { forwardRef, useState } from 'react';

export type InputTextProps = {
  id: string;
  name: string;
  type: 'text' | 'email' | 'password' | 'number';
  label?: string;
  placeholder: string;
  value?: string;
  unit?: string;
  autoComplete?: string;
  hiddenLabel?: boolean;
  readOnly?: boolean;
  showPasswordToggleButton?: boolean;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const {
      id,
      name,
      type,
      label,
      hiddenLabel,
      readOnly,
      placeholder,
      value,
      unit,
      autoComplete,
      showPasswordToggleButton,
      error,
      onChange,
      onClick,
      onFocus,
    } = props;
    return (
      <div className="relative flex flex-col gap-1 w-full">
        {label && (
          <label
            htmlFor={name}
            hidden={hiddenLabel}
            className="text-clevergy-text font-semibold"
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            className={clsx(
              'font-normal text-clevergy-text placeholder-gray-300 w-full rounded-lg border border-gray-400 bg-white px-3 py-2 placeholder:font-normal focus:border-clevergy-primary focus:outline-none',
              {
                'pr-12': unit,
                'pr-10': showPasswordToggleButton,
                '!bg-gray-100 !text-clevergy-subtext focus:!border-gray-400':
                  readOnly,
              },
            )}
            id={id}
            ref={ref}
            name={name}
            placeholder={placeholder}
            type={showPassword && type === 'password' ? 'text' : type}
            value={value}
            autoComplete={autoComplete}
            aria-invalid={Boolean(error)}
            readOnly={readOnly}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
          />
          {(type === 'text' || type === 'number') && unit && (
            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext">
              {unit}
            </span>
          )}
          {type === 'password' && showPasswordToggleButton && (
            <button
              tabIndex={-1}
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext"
            >
              <FontIcon
                name={showPassword ? 'visibility' : 'visibility_off'}
                size={24}
              />
            </button>
          )}
          {readOnly && type !== 'password' && (
            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext">
              <FontIcon name="lock" size={24} />
            </span>
          )}
        </div>

        {error && <span className="text-red-800 block text-sm">{error}</span>}
      </div>
    );
  },
);

InputText.displayName = 'InputText';
