import {
  modulesDevelopmentPort,
  modulesScriptDevelopmentPath,
  modulesScriptProductionUrl,
  modulesStylesDevelopmentPath,
} from '@clevergy/shared/constants/default';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

export const PageWithModules: FC<{
  children: ReactNode;
}> = ({ children }) => {
  // Needed for react-refresh in Vite
  const developmentHmrScript = `
    import RefreshRuntime from "/@react-refresh"
    RefreshRuntime.injectIntoGlobalHook(window)
    window.$RefreshReg$ = () => {}
    window.$RefreshSig$ = () => (type) => type
    window.__vite_plugin_react_preamble_installed__ = true
  `;

  const modulesDevelopmentBaseUrl = `${window.location.protocol}//${window.location.hostname}:${modulesDevelopmentPort}`;
  const modulesStylesDevelopmentUrl = `${modulesDevelopmentBaseUrl}${modulesStylesDevelopmentPath}`;
  const modulesScriptDevelopmentUrl = `${modulesDevelopmentBaseUrl}${modulesScriptDevelopmentPath}`;

  // generate a burst code from date to avoid cache every day
  const burstCode = new Date().toISOString().slice(0, 10).replace(/-/g, '');

  return (
    <>
      {import.meta.env.MODE === 'development' ? (
        <Helmet>
          <link rel="stylesheet" href={modulesStylesDevelopmentUrl} />
          <script type="module">{developmentHmrScript}</script>
          <script type="module" src={modulesScriptDevelopmentUrl}></script>
        </Helmet>
      ) : (
        <Helmet>
          <script
            type="module"
            src={`${modulesScriptProductionUrl}?v=${burstCode}`}
          />
        </Helmet>
      )}
      {children}
    </>
  );
};
