import { tenantsApiBaseUrl } from '@clevergy/shared/constants/default';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useTenantSettings } from '@clevergy/tenants';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const defaultIssuesList = [
  'Tengo dudas con el registro',
  'Errores en mis datos de consumos',
  'Fallos al vincular una instalación solar',
  'Tengo un problema con mi facturación',
  'Otras consultas',
];

export const useSupport = () => {
  const tenantSettings = useTenantSettings();
  const {
    i18n: { language },
  } = useTranslation();

  const [issuesList, setIssuesList] = useState<string[] | null>(null);

  const customIssuesListQuery = useQuery({
    queryKey: [
      QueryKeys.GET_SUPPORT_CUSTOM_ISSUES_LIST,
      tenantSettings.tenantId,
      language,
    ],
    queryFn: async () => {
      const result = await fetch(
        `${tenantsApiBaseUrl}/public-assets/${tenantSettings.tenantId}/support-issues.${language}.json`,
      );
      return await result.json();
    },
  });

  useEffect(() => {
    if (
      customIssuesListQuery.isSuccess &&
      customIssuesListQuery.data?.issuesList
    ) {
      setIssuesList(customIssuesListQuery.data.issuesList);
    } else {
      setIssuesList(defaultIssuesList);
    }
  }, [
    customIssuesListQuery.data,
    customIssuesListQuery.isError,
    customIssuesListQuery.isSuccess,
  ]);

  return {
    issuesList,
  };
};
