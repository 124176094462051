/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateAlexaUserAuthCodeResponse
 */
export interface CreateAlexaUserAuthCodeResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAlexaUserAuthCodeResponse
   */
  userAuthCode?: string;
}

/**
 * Check if a given object implements the CreateAlexaUserAuthCodeResponse interface.
 */
export function instanceOfCreateAlexaUserAuthCodeResponse(
  value: object,
): boolean {
  let isInstance = true;

  return isInstance;
}

export function CreateAlexaUserAuthCodeResponseFromJSON(
  json: any,
): CreateAlexaUserAuthCodeResponse {
  return CreateAlexaUserAuthCodeResponseFromJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAlexaUserAuthCodeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userAuthCode: !exists(json, 'userAuthCode')
      ? undefined
      : json['userAuthCode'],
  };
}

export function CreateAlexaUserAuthCodeResponseToJSON(
  value?: CreateAlexaUserAuthCodeResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userAuthCode: value.userAuthCode,
  };
}
