import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useMemo } from 'react';

export const useShellyConnectUrl = () => {
  const { selectedHouse } = useSelectedHouse();

  const shellyConnectUrl = useMemo(() => {
    if (!selectedHouse?.houseId) {
      return null;
    }

    const shellyIntegratorUrl = 'https://my.shelly.cloud/integrator.html';
    const shellyCallbackUrl = `https://api.clever.gy/houses/${selectedHouse.houseId}/integrations/shelly`;

    const shellyCustomField = 'clevergy';
    const shellyIntegratorParams = new URLSearchParams({
      itg: 'ITG_CVG',
      cb: shellyCallbackUrl,
      customField: shellyCustomField,
    });

    return `${shellyIntegratorUrl}?${shellyIntegratorParams.toString()}`;
  }, [selectedHouse?.houseId]);

  return { shellyConnectUrl };
};
