import { App, URLOpenListenerEvent } from '@capacitor/app';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

export const AppUrlListener: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Too tight coupling with the domain
      const slug = event.url.split('clever.gy').pop();
      if (slug) {
        navigate(slug);
      }
    });
  }, [navigate]);

  return null;
};
