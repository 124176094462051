/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 * Comment information
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   * Comment identifier
   * @type {string}
   * @memberof Comment
   */
  id: string;
  /**
   * Comment creation date
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
  /**
   * Comment author
   * @type {string}
   * @memberof Comment
   */
  author: CommentAuthorEnum;
  /**
   * Comment body
   * @type {string}
   * @memberof Comment
   */
  body: string;
}

/**
 * @export
 */
export const CommentAuthorEnum = {
  User: 'USER',
  Support: 'SUPPORT',
} as const;
export type CommentAuthorEnum =
  (typeof CommentAuthorEnum)[keyof typeof CommentAuthorEnum];

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'createdAt' in value;
  isInstance = isInstance && 'author' in value;
  isInstance = isInstance && 'body' in value;

  return isInstance;
}

export function CommentFromJSON(json: any): Comment {
  return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Comment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: json['createdAt'],
    author: json['author'],
    body: json['body'],
  };
}

export function CommentToJSON(value?: Comment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt,
    author: value.author,
    body: value.body,
  };
}
