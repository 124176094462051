import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { useTenantSettings } from '@clevergy/tenants';
import { TenantPageIntroWithLogo } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { ModuleError } from '@clevergy/ui/components/ModuleError';
import { AppVersionInfo } from 'components/AppVersionInfo';
import { LegalFooter } from 'components/LegalFooter';
import { LoginWithEmailForm } from 'components/LoginWithEmailForm';
import { SocialLoginButtons } from 'components/SocialLoginButtons';
import { Wrapper } from 'components/Wrapper';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const emailLoginFormAnimationVariants = {
  closed: { opacity: 0, y: 20 },
  open: { opacity: 1, y: 0 },
};

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { enabledAuthProviders, hasSignUpWithEmailEnabled } =
    useTenantSettings();

  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [isSocialLoginInProgress, setIsSocialLoginInProgress] = useState(false);

  const emailLoginFormContainerRef = useRef<HTMLDivElement>(null);

  // If only email login is enabled, we show the email login form by default
  const isOnlyEmailLoginEnabled =
    enabledAuthProviders.length === 1 && enabledAuthProviders.includes('email');

  useEffect(() => {
    if (showEmailLogin) {
      emailLoginFormContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [showEmailLogin]);

  if (enabledAuthProviders.length === 0) {
    return <ModuleError title={t('login.noProvidersError') as string} />;
  }

  return (
    <TrackedPage
      event={{
        event_type: 'page_view',
        event_properties: {
          page_name: 'signin',
        },
      }}
    >
      <AnimatePresence>
        <Wrapper fullHeight safeArea>
          {isSocialLoginInProgress && <LoadingIndicator fullScreen="overlay" />}

          <div className="flex h-full flex-col gap-6">
            <div className="flex grow flex-col justify-center">
              <TenantPageIntroWithLogo title={t('login.title') as string} />
            </div>

            <div className="flex flex-col gap-3">
              <SocialLoginButtons
                onLoginStarted={() => setIsSocialLoginInProgress(true)}
                onLoginFinished={() => setIsSocialLoginInProgress(false)}
              />

              {!showEmailLogin && !isOnlyEmailLoginEnabled && (
                <Button
                  type="button"
                  variant="filled"
                  fullWidth
                  onClick={() => setShowEmailLogin(true)}
                >
                  {t('login.email.button')}
                </Button>
              )}

              {(showEmailLogin || isOnlyEmailLoginEnabled) && (
                <motion.div
                  ref={emailLoginFormContainerRef}
                  variants={emailLoginFormAnimationVariants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                >
                  <LoginWithEmailForm />
                </motion.div>
              )}

              {hasSignUpWithEmailEnabled && (
                <p className="text-clevergy-subtext text-center font-semibold">
                  {t('login.signup.title')}{' '}
                  <Link to="/signup" className="text-clevergy-primary">
                    {t('login.signup.link')}
                  </Link>
                </p>
              )}
            </div>

            <LegalFooter />

            <AppVersionInfo />
          </div>
        </Wrapper>
      </AnimatePresence>
    </TrackedPage>
  );
};
