/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UploadInvoiceUrl
 */
export interface UploadInvoiceUrl {
  /**
   *
   * @type {string}
   * @memberof UploadInvoiceUrl
   */
  url?: string;
}

/**
 * Check if a given object implements the UploadInvoiceUrl interface.
 */
export function instanceOfUploadInvoiceUrl(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UploadInvoiceUrlFromJSON(json: any): UploadInvoiceUrl {
  return UploadInvoiceUrlFromJSONTyped(json, false);
}

export function UploadInvoiceUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadInvoiceUrl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url'],
  };
}

export function UploadInvoiceUrlToJSON(value?: UploadInvoiceUrl | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
  };
}
