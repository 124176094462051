import { TenantSettings } from '../types';
import {
  defaultMultiTenantId,
  defaultTenantId,
  tenantsApiBaseUrl,
} from '@clevergy/shared/constants/default';
import { formatGcpTenantId } from '@clevergy/utils/format/formatGcpTenantId';
import { v4 as uuidv4 } from 'uuid';

/**
 * Get default settings for the default tenant
 */
export const getDefaultTenantSettings =
  async (): Promise<TenantSettings | null> => {
    const tenantSettings = await getTenantSettingsFromTenantId(defaultTenantId);
    if (!tenantSettings) {
      throw new Error(
        `Tenant settings not found for default tenant ID: ${defaultTenantId}`,
      );
    }
    return tenantSettings;
  };

/**
 * Get default settings for the multi tenant
 */
export const getDefaultMultiTenantSettings =
  async (): Promise<TenantSettings | null> => {
    const tenantSettings =
      await getTenantSettingsFromTenantId(defaultMultiTenantId);
    if (!tenantSettings) {
      throw new Error(
        `Tenant settings not found for default multi tenant ID: ${defaultMultiTenantId}`,
      );
    }
    return tenantSettings;
  };

/**
 * Get the tenant settings for the given tenant ID
 * @param tenantId
 * @returns The tenant settings for the given tenant ID
 */
export const getTenantSettingsFromTenantId = async (
  tenantId: string,
): Promise<TenantSettings | null> => {
  const result = await fetch(
    `${tenantsApiBaseUrl}/tenants/${tenantId}.json?v=${uuidv4()}`,
  );
  if (!result.ok) {
    return null;
  }
  const tenantSettings = await result.json();
  return tenantSettings;
};

/**
 * Get the tenant ID for the current browser domain hostname
 * @returns The tenant ID for the current domain
 */
export const getTenantSettingsFromBrowserHostname =
  async (): Promise<TenantSettings | null> => {
    if (!window || !window.location) {
      throw new Error('window.location is not defined');
    }
    return getTenantSettingsFromHostname(window.location.hostname);
  };

/**
 * Get the tenant ID for a hostname
 * @returns The tenant ID a host domain
 */
export const getTenantSettingsFromHostname = async (
  hostname: string | null,
): Promise<TenantSettings | null> => {
  if (!hostname) {
    return null;
  }
  const result = await fetch(
    `${tenantsApiBaseUrl}/domains/${hostname}.json?v=${uuidv4()}`,
  );
  if (!result.ok) {
    return null;
  }

  const tenantSettings = await result.json();
  return tenantSettings;
};

/**
 * Get the tenant settings from GCP tenant ID
 * @param tenantGcpId GCP tenant ID
 * @returns boolean
 */
export const getTenantSettingsFromGcpTenantId = async (
  tenantGcpId: string,
): Promise<TenantSettings | null> => {
  const result = await fetch(
    `${tenantsApiBaseUrl}/gcp-tenants/${formatGcpTenantId(
      tenantGcpId,
    )}.json?v=${uuidv4()}`,
  );

  if (!result.ok) {
    return null;
  }

  const tenantSettings = await result.json();
  return tenantSettings;
};
