/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { IntegrationsStatus } from './IntegrationsStatus';
import {
  IntegrationsStatusFromJSON,
  IntegrationsStatusFromJSONTyped,
  IntegrationsStatusToJSON,
} from './IntegrationsStatus';
import type { UserSetUpHouse } from './UserSetUpHouse';
import {
  UserSetUpHouseFromJSON,
  UserSetUpHouseFromJSONTyped,
  UserSetUpHouseToJSON,
} from './UserSetUpHouse';

/**
 *
 * @export
 * @interface UserSetUp
 */
export interface UserSetUp {
  /**
   *
   * @type {string}
   * @memberof UserSetUp
   */
  userStatus?: UserSetUpUserStatusEnum;
  /**
   *
   * @type {Array<UserSetUpHouse>}
   * @memberof UserSetUp
   */
  houses?: Array<UserSetUpHouse>;
  /**
   *
   * @type {IntegrationsStatus}
   * @memberof UserSetUp
   */
  integrationsStatus?: IntegrationsStatus;
  /**
   * Identity document is validated
   * @type {boolean}
   * @memberof UserSetUp
   */
  hasIdentityDocumentValidated?: boolean;
  /**
   * User accept conditions date
   * @type {Date}
   * @memberof UserSetUp
   */
  acceptedConditionsDate?: Date;
}

/**
 * @export
 */
export const UserSetUpUserStatusEnum = {
  Initial: 'INITIAL',
  PendingRenewal: 'PENDING_RENEWAL',
  AcceptedConditions: 'ACCEPTED_CONDITIONS',
  Completed: 'COMPLETED',
} as const;
export type UserSetUpUserStatusEnum =
  (typeof UserSetUpUserStatusEnum)[keyof typeof UserSetUpUserStatusEnum];

/**
 * Check if a given object implements the UserSetUp interface.
 */
export function instanceOfUserSetUp(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserSetUpFromJSON(json: any): UserSetUp {
  return UserSetUpFromJSONTyped(json, false);
}

export function UserSetUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSetUp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userStatus: !exists(json, 'userStatus') ? undefined : json['userStatus'],
    houses: !exists(json, 'houses')
      ? undefined
      : (json['houses'] as Array<any>).map(UserSetUpHouseFromJSON),
    integrationsStatus: !exists(json, 'integrationsStatus')
      ? undefined
      : IntegrationsStatusFromJSON(json['integrationsStatus']),
    hasIdentityDocumentValidated: !exists(json, 'hasIdentityDocumentValidated')
      ? undefined
      : json['hasIdentityDocumentValidated'],
    acceptedConditionsDate: !exists(json, 'acceptedConditionsDate')
      ? undefined
      : new Date(json['acceptedConditionsDate']),
  };
}

export function UserSetUpToJSON(value?: UserSetUp | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userStatus: value.userStatus,
    houses:
      value.houses === undefined
        ? undefined
        : (value.houses as Array<any>).map(UserSetUpHouseToJSON),
    integrationsStatus: IntegrationsStatusToJSON(value.integrationsStatus),
    hasIdentityDocumentValidated: value.hasIdentityDocumentValidated,
    acceptedConditionsDate:
      value.acceptedConditionsDate === undefined
        ? undefined
        : value.acceptedConditionsDate.toISOString(),
  };
}
