/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserRegistrationCompleted
 */
export interface UserRegistrationCompleted {
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  cups?: string;
}

/**
 * Check if a given object implements the UserRegistrationCompleted interface.
 */
export function instanceOfUserRegistrationCompleted(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserRegistrationCompletedFromJSON(
  json: any,
): UserRegistrationCompleted {
  return UserRegistrationCompletedFromJSONTyped(json, false);
}

export function UserRegistrationCompletedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRegistrationCompleted {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    surname: !exists(json, 'surname') ? undefined : json['surname'],
    dni: !exists(json, 'dni') ? undefined : json['dni'],
    cups: !exists(json, 'cups') ? undefined : json['cups'],
  };
}

export function UserRegistrationCompletedToJSON(
  value?: UserRegistrationCompleted | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    surname: value.surname,
    dni: value.dni,
    cups: value.cups,
  };
}
