import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { TenantLoadingIndicator } from '@clevergy/tenants';
import { ConditionalRedirect } from 'components/ConditionalRedirect';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router';
import { useAppConfigurationStore } from 'stores/appSettingsStore';
import { useUserCompletionsWhileOnboardingStore } from 'stores/userCompletionsWhileOnboardingStore';

export const UserSetupRedirectRoute: FC = () => {
  const { userStatus, alexaAuthorization } = useUserSetupContext();
  const { steps, getPendingUserCompletionsPaths, hasPendingUserCompletions } =
    useUserCompletionsWhileOnboardingStore();

  const { hasSkippedSmartMeterIntegration, hasAnsweredNotifications } =
    useAppConfigurationStore();

  const nextCompletionRedirect = useMemo(() => {
    const nextPendingCompletion = Object.values(steps).find(
      (completion) => completion.pending,
    );

    if (!nextPendingCompletion) {
      return null;
    }

    return (
      <ConditionalRedirect
        targetPath={nextPendingCompletion.path}
        redirectCondition={hasPendingUserCompletions()}
      />
    );
  }, [hasPendingUserCompletions, steps]);

  if (!userStatus) {
    return <TenantLoadingIndicator />;
  }

  return (
    <>
      {/* If user has completed setup, but came from Alexa auth, redirect to Alexa settings */}
      <ConditionalRedirect
        targetPath="/alexa/settings"
        redirectCondition={
          userStatus === UserSetUpUserStatusEnum.Completed &&
          Boolean(alexaAuthorization)
        }
      />

      {/* If user needs to complete some step in onboarding process */}
      {nextCompletionRedirect}

      {/* If user is in initial state, redirect to register page and is not completing a pending completion */}
      <ConditionalRedirect
        targetPath="/smart-meter"
        excludedPaths={getPendingUserCompletionsPaths()}
        redirectCondition={
          (userStatus === UserSetUpUserStatusEnum.Initial ||
            userStatus === UserSetUpUserStatusEnum.PendingRenewal) &&
          !hasSkippedSmartMeterIntegration
        }
      />

      {/* If user is in initial state, redirect to register page and is not completing a pending completion */}
      <ConditionalRedirect
        targetPath="/notifications"
        excludedPaths={[...getPendingUserCompletionsPaths(), '/smart-meter']}
        redirectCondition={!hasAnsweredNotifications}
      />

      {/* If current page is register but user is not in initial state or has skipped register */}
      <ConditionalRedirect
        targetPath="/"
        includedPaths={['/smart-meter', '/smart-meter/force']}
        redirectCondition={
          (userStatus !== UserSetUpUserStatusEnum.Initial &&
            userStatus !== UserSetUpUserStatusEnum.PendingRenewal) ||
          hasSkippedSmartMeterIntegration
        }
      />

      {/* If current page is one of the completion steps and there are no pending completions */}
      <ConditionalRedirect
        targetPath="/"
        includedPaths={getPendingUserCompletionsPaths()}
        redirectCondition={!hasPendingUserCompletions()}
      />

      {/* If none of the above conditions are met, render the child routes */}
      <Outlet />
    </>
  );
};
