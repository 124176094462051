import { isClevergyEmail } from '../validations/isClevergyEmail';

/**
 * Generates a mailto link for contacting support.
 * If the contact email is a Clevergy email, it includes a subject and body in the mailto link.
 * Otherwise, it only includes the contact email.
 *
 * @param {Object} options - The options for generating the mailto link.
 * @param {string} options.tenantName - The name of the tenant.
 * @param {string} options.contactEmail - The contact email.
 * @returns {string} The mailto link.
 */
export function getSupportContact(options: {
  tenantName: string;
  contactEmail: string;
}): string {
  const { tenantName, contactEmail } = options;

  const subject = `Contacto desde la app de ${tenantName}`;
  const body = [
    'Hola Soporte',
    `Os escribo desde la app de ${tenantName} para que me echéis un cable.`,
    '[🙏🏻 Mantén estas líneas en el correo para que podamos ayudarte]',
  ].join('%0D%0A');

  return isClevergyEmail(contactEmail)
    ? `mailto:${contactEmail}?subject=${subject}&body=${body}`
    : `mailto:${contactEmail}`;
}
