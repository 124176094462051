import { LeadCaptureForm } from './LeadCaptureForm';
import { LeadCaptureSuccess } from './LeadCaptureSuccess';
import { Modal } from '@clevergy/ui/components/Modal';
import { FC, useState } from 'react';

export type LeadCaptureModalProps = {
  title: string;
  description: string;
  campaign: string;
  isOpen: boolean;
  onClose: () => void;
};

export const LeadCaptureModal: FC<LeadCaptureModalProps> = (props) => {
  const { title, description, campaign, isOpen, onClose } = props;
  const [showLeadCaptureSuccess, setShowLeadCaptureSuccess] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => onClose()}>
        {!showLeadCaptureSuccess && (
          <LeadCaptureForm
            title={title}
            description={description}
            campaign={campaign}
            onFinish={() => setShowLeadCaptureSuccess(true)}
          />
        )}
        {showLeadCaptureSuccess && <LeadCaptureSuccess onFinish={onClose} />}
      </Modal>
    </>
  );
};
