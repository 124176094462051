import { ClevergyJwtPayload } from './types';
import jwt_decode from 'jwt-decode';

export const getGcpTenantIdFromToken = (token: string): string | null => {
  const decoded = jwt_decode(token) as ClevergyJwtPayload;

  if (!decoded) {
    throw new Error('Invalid token');
  }

  const tenantGcpId = decoded.tenant_id || decoded.firebase?.tenant;

  return tenantGcpId || null;
};
