import { useTenantSettings } from '@clevergy/tenants';
import { TenantPageIntroWithLogo } from '@clevergy/tenants';
import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { FormDivider } from '@clevergy/ui/components/FormDivider';
import { InputText } from '@clevergy/ui/components/InputText';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { LegalFooter } from 'components/LegalFooter';
import { SocialLoginButtons } from 'components/SocialLoginButtons';
import { Wrapper } from 'components/Wrapper';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  UserAccountError,
  UserAccountService,
} from 'services/UserAccountService';

type SignupFormValues = {
  email: string;
  password: string;
  repeatPassword: string;
  customError?: string;
};

export const SignupPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSocialLoginInProgress, setIsSocialLoginInProgress] = useState(false);
  const {
    enabledAuthProviders,
    hasSignUpWithEmailEnabled,
    requiresEmailVerification,
  } = useTenantSettings();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm<SignupFormValues>();

  // If only email login is enabled, we show the email login form by default
  const isOnlyEmailLoginEnabled =
    enabledAuthProviders.length === 1 && enabledAuthProviders.includes('email');

  const onSubmit = async (data: SignupFormValues) => {
    try {
      await UserAccountService.createUserWithEmailAndPassword(
        data.email,
        data.password,
        requiresEmailVerification,
      );
      navigate('/login', { replace: true });
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        error.message === UserAccountError.USERS_ALREADY_EXISTS
      ) {
        setError('customError', {
          type: 'serverSideError',
          message: t('sign-up.errors.existing-account') as string,
        });
      } else {
        setError('customError', {
          type: 'serverSideError',
          message: t('sign-up.errors.unknown') as string,
        });
      }
    }
  };

  // If sign up is disabled, redirect to login page
  if (!hasSignUpWithEmailEnabled) {
    navigate('/login', { replace: true });
    return null;
  }

  return (
    <Wrapper fullHeight safeArea>
      {isSocialLoginInProgress && <LoadingIndicator fullScreen="overlay" />}

      <div className="flex flex-col gap-6">
        <div className="flex grow flex-col justify-center">
          <TenantPageIntroWithLogo
            title={t('sign-up.title')}
            subtitle={t('sign-up.subtitle') as string}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="flex flex-col gap-4">
            <InputText
              id="email"
              type="email"
              label={t('sign-up.email.label') as string}
              hiddenLabel
              autoComplete="email"
              placeholder={t('sign-up.email.placeholder')}
              {...register('email', {
                required: t('sign-up.email.required') as string,
                validate: (value) =>
                  isValidEmail(value) || (t('sign-up.email.invalid') as string),
              })}
              error={formState.errors.email?.message as string}
            />

            <InputText
              id="password"
              type="password"
              label={t('sign-up.password.label') as string}
              hiddenLabel
              showPasswordToggleButton
              autoComplete="new-password"
              placeholder={t('sign-up.password.placeholder')}
              {...register('password', {
                required: t('sign-up.password.required') as string,
                minLength: {
                  value: 6,
                  message: t('sign-up.password.min-length') as string,
                },
              })}
              error={formState.errors.password?.message as string}
            />

            <InputText
              id="repeatPassword"
              type="password"
              label={t('sign-up.repeat-password.label') as string}
              hiddenLabel
              showPasswordToggleButton
              autoComplete="new-password"
              placeholder={t('sign-up.repeat-password.placeholder')}
              {...register('repeatPassword', {
                required: t('sign-up.repeat-password.required') as string,
                validate: (value) =>
                  value === getValues('password') ||
                  (t('sign-up.repeat-password.invalid') as string),
                minLength: {
                  value: 6,
                  message: t('sign-up.repeat-password.min-length') as string,
                },
                deps: ['password'],
              })}
              error={formState.errors.repeatPassword?.message as string}
            />

            {formState.errors.customError && (
              <Alert
                type="error"
                text={formState.errors.customError?.message as string}
              />
            )}

            <Button
              type="submit"
              variant="filled"
              fullWidth
              busy={formState.isSubmitting}
              onClick={() => clearErrors()}
            >
              {t('sign-up.sign-up')}
            </Button>
          </div>

          {!isOnlyEmailLoginEnabled && (
            <>
              <FormDivider text="O" />
              <div className="flex flex-col gap-4">
                <SocialLoginButtons
                  onLoginStarted={() => setIsSocialLoginInProgress(true)}
                  onLoginFinished={() => setIsSocialLoginInProgress(false)}
                />
              </div>
            </>
          )}

          <div className="text-clevergy-subtext my-6 space-y-2 text-center font-semibold">
            <p>
              {t('sign-up.login.title')}{' '}
              <Link to="/login" className="text-clevergy-primary">
                {t('sign-up.login.link')}
              </Link>
            </p>
          </div>

          <LegalFooter />
        </form>
      </div>
    </Wrapper>
  );
};
