import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  steps: {
    expectations: {
      path: '/expectations',
      pending: true,
    },
    houseSettings: {
      path: '/onboarding-house-settings',
      pending: true,
    },
  },
};

export type UserCompletionsItem = {
  path: string;
  pending: boolean;
};

export type UserCompletionsWhileOnboardingState = {
  /**
   * The user completions steps.
   */
  steps: {
    expectations: UserCompletionsItem;
    houseSettings: UserCompletionsItem;
  };
  /**
   * Completes an user completion.
   */
  completeUserCompletionsStep: (
    completionKey: keyof UserCompletionsWhileOnboardingState['steps'],
  ) => void;
  /**
   * Whether or not there are pending user completions.
   */
  hasPendingUserCompletions: () => boolean;
  /**
   * Returns the paths of the pending user completions.
   */
  getPendingUserCompletionsPaths: () => string[];
  /**
   * Resets the user completions store.
   */
  reset: () => void;
};

export const useUserCompletionsWhileOnboardingStore =
  create<UserCompletionsWhileOnboardingState>()(
    persist(
      (set, get) => ({
        ...initialState,
        hasPendingUserCompletions: () =>
          Object.values(get().steps).some((completion) => completion.pending),

        getPendingUserCompletionsPaths: () =>
          Object.values(get().steps).map((completion) => completion.path),

        completeUserCompletionsStep: (
          completionKey: keyof UserCompletionsWhileOnboardingState['steps'],
        ) =>
          set((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [completionKey]: {
                ...prev.steps[completionKey],
                pending: false,
              },
            },
          })),
        reset: () =>
          set({
            ...initialState,
          }),
      }),
      {
        name: 'userCompletionsWhileOnboardingState',
        version: 2,
        migrate(prevState, prevVersion) {
          // Migrate from version 0 to 1 by removing "notifications" from "steps"
          if (prevVersion === 0) {
            return {
              steps: {
                expectations:
                  (prevState as UserCompletionsWhileOnboardingState)?.steps
                    ?.expectations || initialState.steps.expectations,
                houseSettings:
                  (prevState as UserCompletionsWhileOnboardingState)?.steps
                    ?.houseSettings || initialState.steps.houseSettings,
              },
            } as UserCompletionsWhileOnboardingState;
          }
          // migrate from version 1 to 2 by changin path of houseSettings to /onboarding-house-settings
          if (prevVersion === 1) {
            return {
              steps: {
                ...(prevState as UserCompletionsWhileOnboardingState)?.steps,
                houseSettings: {
                  path: initialState.steps.houseSettings.path,
                  pending: (prevState as UserCompletionsWhileOnboardingState)
                    ?.steps.houseSettings.pending,
                },
              },
            } as UserCompletionsWhileOnboardingState;
          }

          return prevState as UserCompletionsWhileOnboardingState;
        },
      },
    ),
  );
