import { ClevergyIcons } from './variants/ClevergyIcons';
import { NaturgyIcons } from './variants/NaturgyIcons';
import { FC } from 'react';

const iconVariants = {
  clevergy: ClevergyIcons,
  naturgy: NaturgyIcons,
};

export type IconVariants = keyof typeof iconVariants;

export type IconProps = React.SVGProps<SVGElement> & {
  /**
   * The icon name to use
   * */
  name: IconNames;
  /**
   * The icon variant to use
   * @default clevergy
   * */
  variant?: IconVariants;
  /**
   * If true, the icon will be displayed with CSS display inline
   */
  inline?: boolean;
  /**
   * Size of the icon in pixels
   * @default 24
   */
  size?: number;
};

export type IconNames = keyof typeof ClevergyIcons;
export const iconNames = Object.keys(iconVariants.clevergy) as IconNames[];

export const Icon: FC<IconProps> = (props) => {
  const { variant = 'clevergy', name, inline, size = 24, ...rest } = props;

  const IconSet = iconVariants[variant];

  if (!IconSet) {
    throw new Error(`Icon variant ${variant} not found`);
  }

  const IconComponent = IconSet[name];

  if (!IconComponent) {
    throw new Error(`Icon ${name} not found`);
  }

  return (
    <IconComponent
      data-testid={`icon-${name}`}
      data-name={name}
      data-variant={variant}
      style={{
        display: inline ? 'inline' : 'block',
        verticalAlign: inline ? 'bottom' : '',
      }}
      size={size}
      width={size}
      height={size}
      inline={inline}
      {...rest}
    />
  );
};
