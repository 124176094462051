import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useTenantSettings } from '@clevergy/tenants';
import { Alert } from '@clevergy/ui/components/Alert';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { differenceInDays } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserAccountService } from 'services/UserAccountService';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

export const AppAlerts: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const { requiresEmailVerification } = useTenantSettings();
  const setHasSkippedSmartMeterIntegration = useAppConfigurationStore(
    (state) => state.setHasSkippedSmartMeterIntegration,
  );

  const [hasSendVerificationEmail, setHasSendVerificationEmail] =
    useState(false);

  const houseSettingsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_HOUSE_SETTINGS,
      { houseId: selectedHouse?.houseId },
    ],
    queryFn: selectedHouse?.houseId
      ? () => api.settings.getHouseSettings({ houseId: selectedHouse.houseId })
      : skipToken,
  });

  const alerts = {
    smartMeterIntegrationSkippedAlert:
      userStatus === UserSetUpUserStatusEnum.Initial ||
      userStatus === UserSetUpUserStatusEnum.PendingRenewal,

    emailVerificationAlert:
      requiresEmailVerification && authedUser?.emailVerified === false,

    houseSettingsAlert:
      !userInfo.isCompany &&
      !houseSettingsQuery.isPending &&
      houseSettingsQuery.isError,

    waitingForConsumptionsAlert:
      userStatus === UserSetUpUserStatusEnum.AcceptedConditions,

    consumptionDataOutdatedAlert:
      userStatus === UserSetUpUserStatusEnum.Completed &&
      selectedHouse?.dataProviders?.smartMeter &&
      selectedHouse.dataProviders.smartMeter.lastDate &&
      differenceInDays(
        new Date(),
        selectedHouse.dataProviders.smartMeter.lastDate,
      ) >= 14 &&
      selectedHouse?.dataProviders?.solarInverter &&
      selectedHouse.dataProviders.solarInverter.lastDate &&
      differenceInDays(
        new Date(),
        selectedHouse.dataProviders.solarInverter.lastDate,
      ) >= 14,
  };

  const handleSendVerificationEmail = () => {
    UserAccountService.sendVerificationEmail();
    setHasSendVerificationEmail(true);
  };

  const hasAnyAlertToShow = Object.values(alerts).some(Boolean);

  if (!hasAnyAlertToShow) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 mb-4">
      {alerts.smartMeterIntegrationSkippedAlert && (
        <Alert
          type="warning"
          title={
            t('applicationAlerts.smartMeterIntegrationSkipped.title') as string
          }
          text={
            t('applicationAlerts.smartMeterIntegrationSkipped.text') as string
          }
          actions={
            <button onClick={() => setHasSkippedSmartMeterIntegration(false)}>
              {t('applicationAlerts.smartMeterIntegrationSkipped.button')}
            </button>
          }
        />
      )}
      {alerts.emailVerificationAlert && (
        <>
          {!hasSendVerificationEmail && (
            <Alert
              type="warning"
              text={t('applicationAlerts.emailVerification.text') as string}
              actions={
                <button onClick={handleSendVerificationEmail}>
                  {t('applicationAlerts.emailVerification.link') as string}
                </button>
              }
            />
          )}
          {hasSendVerificationEmail && authedUser?.email && (
            <Alert
              type="warning"
              text={t('applicationAlerts.emailVerification.sent') as string}
            />
          )}
        </>
      )}
      {alerts.houseSettingsAlert && selectedHouse && (
        <Alert
          type="warning"
          text={t('applicationAlerts.missingHouseSettings.text') as string}
          actions={
            <Link to={`/houses/${selectedHouse.houseId}/settings`}>
              {t('applicationAlerts.missingHouseSettings.link')}
            </Link>
          }
        />
      )}
      {alerts.waitingForConsumptionsAlert && (
        <Alert
          type="help"
          title={t('applicationAlerts.waitingForConsumptions.title') as string}
          text={t('applicationAlerts.waitingForConsumptions.text') as string}
          icon={<FontIcon name="hourglass_empty" />}
        />
      )}
      {alerts.consumptionDataOutdatedAlert && (
        <Alert
          type="help"
          title={t('applicationAlerts.consumptionDataOutdated.title') as string}
          text={t('applicationAlerts.consumptionDataOutdated.text') as string}
          icon={<FontIcon name="signal_disconnected" />}
        />
      )}
    </div>
  );
};
