import { Navbar } from 'components/Navbar';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';

export const NavbarLayout = () => {
  const location = useLocation();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  // reset scroll position when location changes
  useEffect(() => {
    if (location && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <div className="flex flex-col h-full">
      <div className="grow overflow-y-auto" ref={scrollDivRef}>
        <Outlet />
      </div>
      <div>
        <Navbar />
      </div>
    </div>
  );
};
