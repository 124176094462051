import { LeadCaptureModal } from './components/LeadCaptureModal';
import { FC, useState } from 'react';

export type LeadCaptureBannerProps = {
  cta: string;
  title: string;
  campaign: string;
  description: string;
};

export const LeadCaptureBanner: FC<LeadCaptureBannerProps> = (props) => {
  const { cta, title, campaign, description } = props;
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowLeadCaptureModal(true)}
        className="text-left rounded-md p-4 w-full mb-4 text-white font-bold bg-clevergy-primary"
      >
        {cta}
      </button>

      {showLeadCaptureModal && (
        <LeadCaptureModal
          title={title}
          description={description}
          campaign={campaign}
          isOpen={showLeadCaptureModal}
          onClose={() => setShowLeadCaptureModal(false)}
        />
      )}
    </>
  );
};
