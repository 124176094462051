import IconGreenBuilding from '../assets/icon-green-building.svg?react';
import IconHouseBattery from '../assets/icon-house-battery.svg?react';
import IconSavings from '../assets/icon-savings.svg?react';
import IconVerified from '../assets/icon-verified.svg?react';
import { LeadCaptureSuccess } from './LeadCaptureSuccess';
import { CreateLeadLeadTypeEnum } from '@clevergy/api-client';
import { Badge } from '@clevergy/ui/components/Badge';
import { Button } from '@clevergy/ui/components/Button';
import { Modal } from '@clevergy/ui/components/Modal';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useState } from 'react';

export type BatteryModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const BatteryModal: FC<BatteryModalProps> = ({ isOpen, onClose }) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const [showLeadCaptureSuccess, setShowLeadCaptureSuccess] = useState(false);

  const createLeadMutation = useMutation({
    mutationFn: () => {
      const { uid, email, tenantId } = authedUser || {};
      return api.users.createLead({
        userId: uid || '',
        createLead: {
          leadType: CreateLeadLeadTypeEnum.Battery,
          payload: {
            email: email || '',
            tenant: tenantId || '',
          },
        },
      });
    },
    onSuccess: () => {
      setShowLeadCaptureSuccess(true);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} inverted>
      {showLeadCaptureSuccess && (
        <LeadCaptureSuccess
          onClick={() => {
            onClose();
            setShowLeadCaptureSuccess(false);
          }}
        />
      )}
      {!showLeadCaptureSuccess && (
        <div className="flex flex-col gap-4 h-full">
          <div className="flex flex-col gap-4 grow">
            <Badge colorScheme="neutral" inverted>
              Batería solar
            </Badge>
            <IconHouseBattery />

            <p className="text-lg font-semibold opacity-80">
              Si instalas una batería solar...
            </p>
            <p className="text-2xl font-semibold">
              Podrás almacenar tus excedentes solares para utilizarlos cuando
              quieras.
            </p>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0">
                <IconGreenBuilding />
              </p>
              <p className="text-3xl font-semibold">
                hasta <span className="text-5xl">90</span>%
              </p>
              <p className="text-xl opacity-80">
                independencia de la red eléctrica
              </p>
            </div>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0">
                <IconVerified />
              </p>
              <p className="text-3xl font-semibold">
                <span className="text-5xl">10</span> años
              </p>
              <p className="text-xl opacity-80">
                de garantía en fabricantes más comunes
              </p>
            </div>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0">
                <IconSavings />
              </p>
              <p className="text-3xl font-semibold">
                <span className="text-5xl">51</span>%
              </p>
              <p className="text-xl opacity-80">
                de ahorro medio en tu factura
              </p>
            </div>
          </div>
          <div>
            <Button
              variant="filled"
              fullWidth
              busy={createLeadMutation.isPending}
              disabled={createLeadMutation.isPending}
              onClick={() => createLeadMutation.mutate()}
            >
              Quiero que me contacten
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
