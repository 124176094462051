/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UploadDniUrl
 */
export interface UploadDniUrl {
  /**
   *
   * @type {string}
   * @memberof UploadDniUrl
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof UploadDniUrl
   */
  urlBack?: string;
}

/**
 * Check if a given object implements the UploadDniUrl interface.
 */
export function instanceOfUploadDniUrl(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UploadDniUrlFromJSON(json: any): UploadDniUrl {
  return UploadDniUrlFromJSONTyped(json, false);
}

export function UploadDniUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadDniUrl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url'],
    urlBack: !exists(json, 'urlBack') ? undefined : json['urlBack'],
  };
}

export function UploadDniUrlToJSON(value?: UploadDniUrl | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    urlBack: value.urlBack,
  };
}
