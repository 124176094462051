import {
  FirebaseAuthentication,
  Persistence,
  User,
} from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';

export enum AuthError {
  UNKNOWN_ERROR = 'Unknown error',
  NO_TOKEN_ID = 'No tokenId',
  NO_USER_ID = 'No userId',
  INVALID_CREDENTIALS = 'Invalid credentials',
  USERS_ALREADY_EXISTS = 'Users already exists',
}

export type AuthedUser = Omit<User, ''>;

export const AuthService = {
  initialize: () => {
    // Only set persistence for web (non native platforms will use the default persistence method)
    if (!Capacitor.isNativePlatform()) {
      FirebaseAuthentication.setPersistence({
        persistence: Persistence.BrowserLocal,
      });
    }
  },
  subscribeToAuthStateChanges: async (
    callback: (token: string | null, user: AuthedUser | null) => void,
  ) => {
    await FirebaseAuthentication.addListener('authStateChange', (data) => {
      const { user } = data;
      if (user) {
        AuthService.getIdToken().then((token) => {
          console.log('Auth state changed: ', { token, user });
          callback(token, user as AuthedUser);
        });
      } else {
        callback(null, null);
        console.log('Auth state changed: Logout');
      }
    });
  },

  unsubscribeToAuthStateChanges: async () => {
    await FirebaseAuthentication.removeAllListeners();
  },

  signInWithEmailAndPassword: async (
    email: string,
    password: string,
  ): Promise<AuthedUser> => {
    try {
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
      return AuthService.getCurrentUser();
    } catch (error) {
      throw new Error(AuthError.INVALID_CREDENTIALS as AuthError);
    }
  },

  signInWithGoogle: async () => {
    try {
      await FirebaseAuthentication.signInWithGoogle();
      return AuthService.getCurrentUser();
    } catch (error) {
      throw new Error(AuthError.INVALID_CREDENTIALS as AuthError);
    }
  },

  signInWithApple: async () => {
    try {
      await FirebaseAuthentication.signInWithApple();
      return AuthService.getCurrentUser();
    } catch (error) {
      throw new Error(AuthError.INVALID_CREDENTIALS as AuthError);
    }
  },

  signInWithCustomToken: async (token: string): Promise<AuthedUser> => {
    try {
      await FirebaseAuthentication.signInWithCustomToken({
        token,
      });
      return AuthService.getCurrentUser();
    } catch (error) {
      throw new Error(AuthError.INVALID_CREDENTIALS as AuthError);
    }
  },

  signOut: () => {
    return FirebaseAuthentication.signOut();
  },

  getIdToken: async (forceRefresh = false) => {
    const { token } = await FirebaseAuthentication.getIdToken({
      forceRefresh,
    });
    return token;
  },

  getCurrentUser: async () => {
    const { user } = await FirebaseAuthentication.getCurrentUser();
    if (user?.uid === undefined) {
      throw new Error(AuthError.NO_USER_ID as AuthError);
    }
    return user as AuthedUser;
  },
};
