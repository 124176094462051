import { ClevergyIcons } from '../variants/ClevergyIcons';
import Calendar from './naturgy/icon-calendar.svg?react';
import DeviceAc from './naturgy/icon-device-ac.svg?react';
import DeviceAerothermal from './naturgy/icon-device-aerothermal.svg?react';
import DeviceBattery from './naturgy/icon-device-battery.svg?react';
import DeviceCar from './naturgy/icon-device-car.svg?react';
import DeviceCeramic from './naturgy/icon-device-ceramic.svg?react';
import DeviceChargingPoint from './naturgy/icon-device-charging-point.svg?react';
import DeviceComputer from './naturgy/icon-device-computer.svg?react';
import DeviceDishwasher from './naturgy/icon-device-dishwasher.svg?react';
import DeviceDryer from './naturgy/icon-device-dryer.svg?react';
import DeviceElectricBoiler from './naturgy/icon-device-electric-boiler.svg?react';
import DeviceElectricWaterHeater from './naturgy/icon-device-electric-water-heater.svg?react';
import DeviceFlame from './naturgy/icon-device-flame.svg?react';
import DeviceFreezer from './naturgy/icon-device-freezer.svg?react';
import DeviceFridge from './naturgy/icon-device-fridge.svg?react';
import DeviceGasBoiler from './naturgy/icon-device-gas-boiler.svg?react';
import DeviceGasWaterHeater from './naturgy/icon-device-gas-water-heater.svg?react';
import DeviceGeothermal from './naturgy/icon-device-geothermal.svg?react';
import DeviceHotWater from './naturgy/icon-device-hot-water.svg?react';
import DeviceLight from './naturgy/icon-device-light.svg?react';
import DeviceOther from './naturgy/icon-device-other.svg?react';
import DeviceOven from './naturgy/icon-device-oven.svg?react';
import DevicePhotovoltaicPanel from './naturgy/icon-device-photovoltaic-panel.svg?react';
import DevicePurifier from './naturgy/icon-device-purifier.svg?react';
import DeviceRadiator from './naturgy/icon-device-radiator.svg?react';
import DeviceSolarPanel from './naturgy/icon-device-solar-panel.svg?react';
import DeviceStandby from './naturgy/icon-device-standby.svg?react';
import DeviceTv from './naturgy/icon-device-tv.svg?react';
import DeviceWashingMachine from './naturgy/icon-device-washing-machine.svg?react';
import Flat from './naturgy/icon-flat.svg?react';
import HouseEfficient from './naturgy/icon-house-efficient.svg?react';
import HouseVerified from './naturgy/icon-house-verified.svg?react';
import House from './naturgy/icon-house.svg?react';
import Location from './naturgy/icon-location.svg?react';
import Monitoring from './naturgy/icon-monitoring.svg?react';
import Neighbors from './naturgy/icon-neighbors.svg?react';
import PeopleOne from './naturgy/icon-people-1.svg?react';
import PeopleTwo from './naturgy/icon-people-2.svg?react';
import PeopleThree from './naturgy/icon-people-3.svg?react';
import PeopleMore from './naturgy/icon-people-4.svg?react';

// Any icon here needs to be present in ClevergyIcons
// but it can be optional because Clevergy version is used as fallback
type NaturgyIconsType = Record<
  keyof typeof ClevergyIcons,
  React.FC<React.SVGProps<SVGElement>>
>;

export const NaturgyIcons: Partial<NaturgyIconsType> = {
  Calendar,
  DeviceAc,
  DeviceAerothermal,
  DeviceBattery,
  DeviceCar,
  DeviceCeramic,
  DeviceChargingPoint,
  DeviceComputer,
  DeviceDishwasher,
  DeviceDryer,
  DeviceElectricBoiler,
  DeviceElectricWaterHeater,
  DeviceFlame,
  DeviceFreezer,
  DeviceFridge,
  DeviceGasBoiler,
  DeviceGasWaterHeater,
  DeviceGeothermal,
  DeviceHotWater,
  DeviceLight,
  DeviceOther,
  DeviceOven,
  DevicePhotovoltaicPanel,
  DevicePurifier,
  DeviceRadiator,
  DeviceSolarPanel,
  DeviceStandby,
  DeviceTv,
  DeviceWashingMachine,
  Flat,
  House,
  HouseEfficient,
  HouseVerified,
  Location,
  Monitoring,
  Neighbors,
  PeopleMore,
  PeopleOne,
  PeopleThree,
  PeopleTwo,
};
