import { getAlexaIntegrationItemLink } from './getAlexaIntegrationItemLink';
import { getConnectedList } from './getConnectedList';
import { getDisconnectedList } from './getDisconnectedList';
import { IntegrationData, IntegrationId } from './types';
import { useTenantSettings } from '@clevergy/tenants';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';

export function useIntegration() {
  const { authedUser } = useAuthContext();
  const { tenantId } = useTenantSettings();
  const { integrationsStatus, houses } = useUserSetupContext();

  const hasHouses = houses.length > 0;

  const data: IntegrationData = {
    authedUser,
    integrationsStatus,
  };

  const list = [
    {
      id: IntegrationId.Alexa,
      title: 'integrations.alexa',
      pathForConnect: getAlexaIntegrationItemLink(),
      pathForDisconnect: '/alexa',
      isEnabled: hasHouses && tenantId === 'clevergy',
      isConnected: Boolean(data.integrationsStatus?.alexaConnected),
    },
  ];

  return {
    connectedList: getConnectedList(list),
    disconnectedList: getDisconnectedList(list),
  };
}
