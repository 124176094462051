import { useApiContext } from './ApiContext';
import { useAuthContext } from './AuthContext';
import {
  FirebaseMessaging,
  GetTokenOptions,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import { MutationKeys } from '@clevergy/shared/constants/mutationKeys';
import { useIsMutating, useMutation } from '@tanstack/react-query';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { PushNotificationShowUpModal } from 'components/PushNotificationShowUpModal';
import { firebaseVapidKey } from 'constants/firebase';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type NotificationData = {
  clevergy_communicationId: string;
  clevergy_title: string;
  clevergy_body: string;
  clevergy_targetUrlPath?: string;
};

export type NotificationsPermissionsStatus =
  | 'unavailable'
  | 'prompt'
  | 'prompt-with-rationale'
  | 'denied'
  | 'granted'
  | 'unknown'
  | null;

export type NotificationsContextValue = {
  permissionsStatus: NotificationsPermissionsStatus;
  requestNotificationsPermission: () => Promise<void>;
};

export const NotificationsContext =
  createContext<NotificationsContextValue | null>(null);

export const NotificationsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { t } = useTranslation();
  const { track } = useAnalyticsContext();
  const navigate = useNavigate();

  const [openedNotification, setOpenedNotification] =
    useState<NotificationData | null>(null);

  const hasRegisteredDeviceToken = useRef(false);

  const [permissionsStatus, setPermissionsStatus] =
    useState<NotificationsPermissionsStatus>(null);

  // Mutation to persist the device token in the database
  const isMutatingRegisterUserDeviceTokenMutation = useIsMutating({
    mutationKey: [MutationKeys.REGISTER_USER_DEVICE_TOKEN, authedUser?.uid],
  });

  // Mutation to persist the device token in backend
  const registerUserDeviceTokenMutation = useMutation({
    mutationKey: [MutationKeys.REGISTER_USER_DEVICE_TOKEN, authedUser?.uid],
    mutationFn: ({
      userId,
      deviceToken,
    }: {
      userId: string;
      deviceToken: string;
    }) => {
      console.log('Registering device token:', deviceToken);
      return api.users.createUserAppDevice({
        userId,
        userAppDevice: {
          deviceToken,
        },
      });
    },
    onSuccess: () => {
      console.log('Device token registered');
      hasRegisteredDeviceToken.current = true;
    },
    onError: (error: unknown) => {
      hasRegisteredDeviceToken.current = true;
      throw new Error(`Error registering device token ${error}`);
    },
  });

  const trackNotificationMutation = useMutation({
    mutationKey: [MutationKeys.TRACK_NOTIFICATION],
    mutationFn: async (options: { notificationData: NotificationData }) => {
      const { notificationData } = options;
      const deviceToken = await FirebaseMessaging.getToken();
      return await fetch(
        'https://portal.clever.gy/api/consumer/notifications/track',
        {
          method: 'POST',
          body: JSON.stringify({
            communicationId: notificationData.clevergy_communicationId,
            deviceToken: deviceToken.token,
          }),
        },
      );
    },
  });

  // Check native notifications permission
  const checkNotificationPermissions =
    useCallback(async (): Promise<NotificationsPermissionsStatus> => {
      // Ask for platform information
      const { platform, osVersion } = await Device.getInfo();
      const isSupported = await FirebaseMessaging.isSupported();

      // If push notifications are not available on the platform, return unavailable
      if (!isSupported) {
        setPermissionsStatus('unavailable');
        return 'unavailable';
      }

      // Check the current permissions status using the Firebase plugin
      const permStatus = await FirebaseMessaging.checkPermissions();

      if (platform === 'android' && parseInt(osVersion) <= 12) {
        setPermissionsStatus('unknown');
        return 'unknown';
      }

      setPermissionsStatus(permStatus.receive);
      return permStatus.receive;
    }, []);

  // Request web notifications permission
  const requestWebNotificationsPermission = useCallback(async () => {
    if (!authedUser || isMutatingRegisterUserDeviceTokenMutation) {
      return;
    }

    if (
      !navigator ||
      !navigator.serviceWorker ||
      !navigator.serviceWorker.register
    ) {
      console.log('Service workers are not supported');
      return;
    }
    console.log('Registering service worker for notifications');

    const registration = await navigator.serviceWorker.register(
      'firebase-messaging-sw.js',
    );
    console.log('Service worker registered:', registration);
    const options: GetTokenOptions = {
      vapidKey: firebaseVapidKey,
      serviceWorkerRegistration: registration,
    };
    const { token } = await FirebaseMessaging.getToken(options);
    registerUserDeviceTokenMutation.mutate({
      userId: authedUser.uid,
      deviceToken: token,
    });
  }, [
    authedUser,
    isMutatingRegisterUserDeviceTokenMutation,
    registerUserDeviceTokenMutation,
  ]);

  // Request notifications permission
  const requestNotificationsPermission = useCallback(async () => {
    const { platform } = await Device.getInfo();
    const currentNotificationPermissions = await checkNotificationPermissions();

    if (currentNotificationPermissions === 'unavailable') {
      throw new Error('Notifications are not available on this platform');
    }

    if (platform === 'web' && currentNotificationPermissions === 'granted') {
      window.alert(t('notifications.currentStatusGranted'));
      return;
    }
    if (platform === 'web' && currentNotificationPermissions === 'denied') {
      window.alert(t('notifications.currentStatusDenied'));
      return;
    }

    if (
      currentNotificationPermissions === 'prompt' ||
      currentNotificationPermissions === 'prompt-with-rationale'
    ) {
      console.log('Requesting notification permission');
      const result = await FirebaseMessaging.requestPermissions();
      console.log(
        `Notification permission requested with result: ${result.receive}`,
      );

      if (!Capacitor.isNativePlatform()) {
        requestWebNotificationsPermission();
      }
      return;
    }

    // Otherwise, open the native settings
    NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App,
    });
  }, [checkNotificationPermissions, requestWebNotificationsPermission, t]);

  // Check native permissions on mount
  useEffect(() => {
    checkNotificationPermissions();
  }, [checkNotificationPermissions]);

  // Register device token listener for native notifications
  useEffect(() => {
    // If the user is not logged in or the auth state is still pending, do nothing
    if (!authedUser) {
      return;
    }
    FirebaseMessaging.addListener('tokenReceived', (event) => {
      const { token } = event;
      console.log(`Device token received ${token}`);
      registerUserDeviceTokenMutation
        .mutateAsync({
          userId: authedUser.uid,
          deviceToken: token,
        })
        .then(() => {
          console.log('Device token registered');
        })
        .catch((err) => {
          throw new Error(`Error registering device token ${err}`);
        });
    });

    // Add listeners for notification events
    FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
      const notificationData = event.notification.data as NotificationData;
      track({
        event_type: 'push_notification_action_performed',
        event_properties: {
          ...event,
        },
      });
      trackNotificationMutation.mutate({
        notificationData,
      });
      if (notificationData.clevergy_title && notificationData.clevergy_body) {
        setOpenedNotification(notificationData);
      } else {
        if (notificationData.clevergy_targetUrlPath) {
          navigate(notificationData.clevergy_targetUrlPath);
        }
      }
    });

    // Add listener for notification received (when the app is in the foreground)
    FirebaseMessaging.addListener('notificationReceived', (event) => {
      const notificationData = event.notification.data as NotificationData;
      track({
        event_type: 'push_notification_received',
        event_properties: {
          ...event,
        },
      });
      trackNotificationMutation.mutate({
        notificationData,
      });
      if (notificationData.clevergy_title && notificationData.clevergy_body) {
        setOpenedNotification(notificationData);
      }
    });

    return () => {
      FirebaseMessaging.removeAllListeners();
    };
  }, [
    authedUser,
    navigate,
    registerUserDeviceTokenMutation,
    track,
    trackNotificationMutation,
  ]);

  useEffect(() => {
    if (
      !Capacitor.isNativePlatform() &&
      permissionsStatus === 'granted' &&
      !hasRegisteredDeviceToken.current
    ) {
      requestWebNotificationsPermission();
    }
  }, [permissionsStatus, requestWebNotificationsPermission]);

  return (
    <NotificationsContext.Provider
      value={{
        permissionsStatus,
        requestNotificationsPermission,
      }}
    >
      {children}
      <PushNotificationShowUpModal
        isOpen={Boolean(openedNotification)}
        title={openedNotification?.clevergy_title || ''}
        body={openedNotification?.clevergy_body || ''}
        onConfirm={() => {
          // navigate to the target URL path if it exists
          if (openedNotification?.clevergy_targetUrlPath) {
            navigate(openedNotification.clevergy_targetUrlPath);
          }
          setOpenedNotification(null);
        }}
      />
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === null) {
    throw Error(
      'useNotificationsContext must be used inside of a NotificationsContextProvider',
    );
  }
  return context as NotificationsContextValue;
};
