import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseError } from 'firebase/app';

export enum UserAccountError {
  UNKNOWN_ERROR = 'Unknown error',
  USERS_ALREADY_EXISTS = 'Users already exists',
}

export const UserAccountService = {
  createUserWithEmailAndPassword: async (
    email: string,
    password: string,
    sendEmailVerification = false,
  ) => {
    try {
      await FirebaseAuthentication.createUserWithEmailAndPassword({
        email,
        password,
      });
      if (sendEmailVerification) {
        await FirebaseAuthentication.sendEmailVerification();
      }
    } catch (error: unknown) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/email-already-in-use'
      ) {
        throw new Error(
          UserAccountError.USERS_ALREADY_EXISTS as UserAccountError,
        );
      } else {
        throw new Error(UserAccountError.UNKNOWN_ERROR as UserAccountError);
      }
    }
  },

  sendPasswordResetEmail: async (email: string) => {
    try {
      await FirebaseAuthentication.sendPasswordResetEmail({ email });
    } catch (error: unknown) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/user-not-found'
      ) {
        // We don't want to show an error if the user doesn't exist
        Promise.resolve();
      } else {
        throw new Error(UserAccountError.UNKNOWN_ERROR as UserAccountError);
      }
    }
  },

  sendVerificationEmail: async () => {
    try {
      await FirebaseAuthentication.sendEmailVerification();
    } catch (error: unknown) {
      throw new Error(UserAccountError.UNKNOWN_ERROR as UserAccountError);
    }
  },
};
