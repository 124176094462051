/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { DataProviderInfo } from './DataProviderInfo';
import {
  DataProviderInfoFromJSON,
  DataProviderInfoFromJSONTyped,
  DataProviderInfoToJSON,
} from './DataProviderInfo';

/**
 *
 * @export
 * @interface DataProviders
 */
export interface DataProviders {
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  smartMeter?: DataProviderInfo;
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  solarInverter?: DataProviderInfo;
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  energyCommunity?: DataProviderInfo;
}

/**
 * Check if a given object implements the DataProviders interface.
 */
export function instanceOfDataProviders(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DataProvidersFromJSON(json: any): DataProviders {
  return DataProvidersFromJSONTyped(json, false);
}

export function DataProvidersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataProviders {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    smartMeter: !exists(json, 'smartMeter')
      ? undefined
      : DataProviderInfoFromJSON(json['smartMeter']),
    solarInverter: !exists(json, 'solarInverter')
      ? undefined
      : DataProviderInfoFromJSON(json['solarInverter']),
    energyCommunity: !exists(json, 'energyCommunity')
      ? undefined
      : DataProviderInfoFromJSON(json['energyCommunity']),
  };
}

export function DataProvidersToJSON(value?: DataProviders | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    smartMeter: DataProviderInfoToJSON(value.smartMeter),
    solarInverter: DataProviderInfoToJSON(value.solarInverter),
    energyCommunity: DataProviderInfoToJSON(value.energyCommunity),
  };
}
