/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserRegistration
 */
export interface UserRegistration {
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  language?: string;
}

/**
 * Check if a given object implements the UserRegistration interface.
 */
export function instanceOfUserRegistration(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function UserRegistrationFromJSON(json: any): UserRegistration {
  return UserRegistrationFromJSONTyped(json, false);
}

export function UserRegistrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRegistration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    userName: !exists(json, 'userName') ? undefined : json['userName'],
    surname: !exists(json, 'surname') ? undefined : json['surname'],
    email: !exists(json, 'email') ? undefined : json['email'],
    tenant: !exists(json, 'tenant') ? undefined : json['tenant'],
    language: !exists(json, 'language') ? undefined : json['language'],
  };
}

export function UserRegistrationToJSON(value?: UserRegistration | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userName: value.userName,
    surname: value.surname,
    email: value.email,
    tenant: value.tenant,
    language: value.language,
  };
}
