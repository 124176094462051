// script and css urls for modules in production
export const modulesScriptProductionUrl =
  'https://storage.googleapis.com/clevergy-scripts/clevergy-modules.js';
export const modulesStylesProductionUrl =
  'https://storage.googleapis.com/clevergy-scripts/clevergy-modules.css';

// script and css urls for modules in development
export const modulesDevelopmentPort = 3001;
export const modulesScriptDevelopmentPath = `/src/clevergy-modules.tsx`;
export const modulesStylesDevelopmentPath = `/src/styles/clevergy-modules.css`;

export const googleSymbolsFontUrl =
  'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:wght,FILL,GRAD@400,0..1,0';

export const defaultTenantId = 'clevergy';
export const defaultGcpTenantId = 'clevergy-eo39m';
export const defaultMultiTenantId = 'ecogrid';

export const tenantsApiBaseUrl =
  import.meta.env && import.meta.env.DEV && !import.meta.env.STORYBOOK
    ? 'http://localhost:3002/tenants-api'
    : 'https://storage.googleapis.com/clevergy-tenants-api';
