import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { forwardRef, useState } from 'react';

export type InputCheckProps = {
  id: string;
  name: string;
  label: string;
  value?: string;
  error?: string;
  isChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputCheck = forwardRef<HTMLInputElement, InputCheckProps>(
  (props, ref) => {
    const {
      id,
      name,
      label,
      error,
      value,
      isChecked = false,
      onChange,
    } = props;
    const [isFocused, setIsFocused] = useState(false);
    return (
      <div data-checked={isChecked} data-focused={isFocused}>
        <label
          htmlFor={id}
          className={clsx('inline-flex cursor-pointer gap-2', {
            'font-semibold': isChecked,
          })}
        >
          <span className="border-clevergy-border text-clevergy-primary rounded-md border flex flex-shrink-0 items-center justify-center h-6 w-6 transition-colors">
            <span
              className={clsx({
                hidden: !isChecked,
                block: isChecked,
              })}
            >
              <FontIcon name="check" />
            </span>
          </span>
          <input
            ref={ref}
            id={id}
            name={name}
            className="sr-only"
            type="checkbox"
            aria-invalid={Boolean(error)}
            checked={isChecked}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={onChange}
          />
          <span>{label}</span>
        </label>
        {error && <span className="text-red-800 block text-sm">{error}</span>}
      </div>
    );
  },
);

InputCheck.displayName = 'InputCheck';
