/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { HouseEnergyConsumptionKwh } from './HouseEnergyConsumptionKwh';
import {
  HouseEnergyConsumptionKwhFromJSON,
  HouseEnergyConsumptionKwhFromJSONTyped,
  HouseEnergyConsumptionKwhToJSON,
} from './HouseEnergyConsumptionKwh';

/**
 *
 * @export
 * @interface HouseEnergy
 */
export interface HouseEnergy {
  /**
   * Date of the consumption. This value depends on the granularity parameter. The dates could be organized by hour, day or month.
   * @type {Date}
   * @memberof HouseEnergy
   */
  date?: Date;
  /**
   *
   * @type {HouseEnergyConsumptionKwh}
   * @memberof HouseEnergy
   */
  consumptionKwh?: HouseEnergyConsumptionKwh;
  /**
   * Energy consumed from the grid
   * @type {number}
   * @memberof HouseEnergy
   */
  outGridKwh?: number;
  /**
   * Energy produced by the house
   * @type {number}
   * @memberof HouseEnergy
   */
  selfProductionKwh?: number;
  /**
   * Energy injected to the grid
   * @type {number}
   * @memberof HouseEnergy
   */
  onGridKwh?: number;
  /**
   * Energy consumed by the house from its own production
   * @type {number}
   * @memberof HouseEnergy
   */
  selfConsumptionKwh?: number;
}

/**
 * Check if a given object implements the HouseEnergy interface.
 */
export function instanceOfHouseEnergy(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function HouseEnergyFromJSON(json: any): HouseEnergy {
  return HouseEnergyFromJSONTyped(json, false);
}

export function HouseEnergyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseEnergy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, 'date') ? undefined : new Date(json['date']),
    consumptionKwh: !exists(json, 'consumptionKwh')
      ? undefined
      : HouseEnergyConsumptionKwhFromJSON(json['consumptionKwh']),
    outGridKwh: !exists(json, 'outGridKwh') ? undefined : json['outGridKwh'],
    selfProductionKwh: !exists(json, 'selfProductionKwh')
      ? undefined
      : json['selfProductionKwh'],
    onGridKwh: !exists(json, 'onGridKwh') ? undefined : json['onGridKwh'],
    selfConsumptionKwh: !exists(json, 'selfConsumptionKwh')
      ? undefined
      : json['selfConsumptionKwh'],
  };
}

export function HouseEnergyToJSON(value?: HouseEnergy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === undefined ? undefined : value.date.toISOString(),
    consumptionKwh: HouseEnergyConsumptionKwhToJSON(value.consumptionKwh),
    outGridKwh: value.outGridKwh,
    selfProductionKwh: value.selfProductionKwh,
    onGridKwh: value.onGridKwh,
    selfConsumptionKwh: value.selfConsumptionKwh,
  };
}
