/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConsumptionCategory
 */
export interface ConsumptionCategory {
  /**
   * Name of the category
   * @type {string}
   * @memberof ConsumptionCategory
   */
  label?: string;
  /**
   * Consumption in kWh
   * @type {number}
   * @memberof ConsumptionCategory
   */
  kwh?: number;
}

/**
 * Check if a given object implements the ConsumptionCategory interface.
 */
export function instanceOfConsumptionCategory(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ConsumptionCategoryFromJSON(json: any): ConsumptionCategory {
  return ConsumptionCategoryFromJSONTyped(json, false);
}

export function ConsumptionCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConsumptionCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    label: !exists(json, 'label') ? undefined : json['label'],
    kwh: !exists(json, 'kwh') ? undefined : json['kwh'],
  };
}

export function ConsumptionCategoryToJSON(
  value?: ConsumptionCategory | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    label: value.label,
    kwh: value.kwh,
  };
}
