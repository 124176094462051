/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateAlexaUserAuthCodeRequest
 */
export interface CreateAlexaUserAuthCodeRequest {
  /**
   *
   * @type {string}
   * @memberof CreateAlexaUserAuthCodeRequest
   */
  cups: string;
}

/**
 * Check if a given object implements the CreateAlexaUserAuthCodeRequest interface.
 */
export function instanceOfCreateAlexaUserAuthCodeRequest(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cups' in value;

  return isInstance;
}

export function CreateAlexaUserAuthCodeRequestFromJSON(
  json: any,
): CreateAlexaUserAuthCodeRequest {
  return CreateAlexaUserAuthCodeRequestFromJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAlexaUserAuthCodeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cups: json['cups'],
  };
}

export function CreateAlexaUserAuthCodeRequestToJSON(
  value?: CreateAlexaUserAuthCodeRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cups: value.cups,
  };
}
