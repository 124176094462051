import {
  TrackedEvent,
  useAnalyticsContext,
} from '../../context/AnalyticsContext';
import { FC, ReactNode } from 'react';
import { useMount } from 'react-use';

export type TrackedPageProps = {
  event: TrackedEvent;
  children: ReactNode;
};

export const TrackedPage: FC<TrackedPageProps> = ({ event, children }) => {
  const { track } = useAnalyticsContext();

  useMount(() => {
    track(event);
  });

  return <>{children}</>;
};
