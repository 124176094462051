/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserAccountDetails
 */
export interface UserAccountDetails {
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  phoneNumber?: string;
}

/**
 * Check if a given object implements the UserAccountDetails interface.
 */
export function instanceOfUserAccountDetails(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserAccountDetailsFromJSON(json: any): UserAccountDetails {
  return UserAccountDetailsFromJSONTyped(json, false);
}

export function UserAccountDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    surname: !exists(json, 'surname') ? undefined : json['surname'],
    email: !exists(json, 'email') ? undefined : json['email'],
    dni: !exists(json, 'dni') ? undefined : json['dni'],
    phoneNumber: !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
  };
}

export function UserAccountDetailsToJSON(
  value?: UserAccountDetails | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    surname: value.surname,
    email: value.email,
    dni: value.dni,
    phoneNumber: value.phoneNumber,
  };
}
