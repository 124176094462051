/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserPreferencesNotificationResponse
 */
export interface UserPreferencesNotificationResponse {
  /**
   *
   * @type {string}
   * @memberof UserPreferencesNotificationResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof UserPreferencesNotificationResponse
   */
  type?: UserPreferencesNotificationResponseTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationResponse
   */
  push?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationResponse
   */
  email?: boolean;
}

/**
 * @export
 */
export const UserPreferencesNotificationResponseTypeEnum = {
  ConsumptionReport: 'CONSUMPTION_REPORT',
  Export: 'EXPORT',
} as const;
export type UserPreferencesNotificationResponseTypeEnum =
  (typeof UserPreferencesNotificationResponseTypeEnum)[keyof typeof UserPreferencesNotificationResponseTypeEnum];

/**
 * Check if a given object implements the UserPreferencesNotificationResponse interface.
 */
export function instanceOfUserPreferencesNotificationResponse(
  value: object,
): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserPreferencesNotificationResponseFromJSON(
  json: any,
): UserPreferencesNotificationResponse {
  return UserPreferencesNotificationResponseFromJSONTyped(json, false);
}

export function UserPreferencesNotificationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserPreferencesNotificationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, 'userId') ? undefined : json['userId'],
    type: !exists(json, 'type') ? undefined : json['type'],
    push: !exists(json, 'push') ? undefined : json['push'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function UserPreferencesNotificationResponseToJSON(
  value?: UserPreferencesNotificationResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    type: value.type,
    push: value.push,
    email: value.email,
  };
}
