import { TenantIcon, useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/AuthService';

export type SocialLoginButtonsProps = {
  onLoginStarted?: () => void;
  onLoginFinished?: () => void;
};

export const SocialLoginButtons: FC<SocialLoginButtonsProps> = (props) => {
  const { onLoginStarted, onLoginFinished } = props;
  const { enabledAuthProviders } = useTenantSettings();
  const { t } = useTranslation();

  const handleSignInWithGoogle = async () => {
    onLoginStarted?.();
    return AuthService.signInWithGoogle().finally(() => onLoginFinished?.());
  };

  const handleSignInWithApple = async () => {
    onLoginStarted?.();
    return AuthService.signInWithApple().finally(() => onLoginFinished?.());
  };

  return (
    <>
      {enabledAuthProviders.includes('google') && (
        <Button
          variant="outlined"
          colorScheme="neutral"
          fullWidth
          onClick={() => handleSignInWithGoogle()}
        >
          <TenantIcon name="SocialGoogle" />
          {t('login.google')}
        </Button>
      )}
      {enabledAuthProviders.includes('apple') && (
        <Button
          variant="outlined"
          colorScheme="neutral"
          fullWidth
          onClick={() => handleSignInWithApple()}
        >
          <TenantIcon name="SocialApple" />
          {t('login.apple')}
        </Button>
      )}
    </>
  );
};
