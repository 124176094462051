import { FC, ReactNode } from 'react';

export type AppLayoutProps = {
  children: ReactNode;
};

export const AppLayout: FC<AppLayoutProps> = (props) => (
  <div className="absolute inset-0 flex flex-col justify-between">
    {props.children}
  </div>
);
