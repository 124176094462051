import { useTenantSettings } from '@clevergy/tenants';
import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthError, AuthService } from 'services/AuthService';

type LoginFormValues = {
  email: string;
  password: string;
  customError?: string;
};

export type LoginWithEmailFormProps = {
  // Keep this empty for future props
};

export const LoginWithEmailForm: FC<LoginWithEmailFormProps> = () => {
  const { t } = useTranslation();
  const { tenantGcpId, enabledAuthProviders, hasMagicLinkSignInEnabled } =
    useTenantSettings();

  const { register, handleSubmit, setError, clearErrors, formState } =
    useForm<LoginFormValues>();

  const isEmailLoginEnabled = enabledAuthProviders.includes('email');

  const [currentLoginMethod, setCurrentLoginMethod] = useState<
    'magic-link' | 'email'
  >(hasMagicLinkSignInEnabled ? 'magic-link' : 'email');
  const [isMagicLinkSuccess, setIsMagicLinkSuccess] = useState(false);

  const onSubmit = async (data: LoginFormValues) => {
    // Login with magic link
    if (currentLoginMethod === 'magic-link') {
      try {
        await fetch(
          'https://portal.clever.gy/api/consumer/auth/login-with-link',
          {
            method: 'POST',
            body: JSON.stringify({
              email: data.email,
              tenantGcpId: tenantGcpId,
            }),
          },
        );
        setIsMagicLinkSuccess(true);
      } catch (error: unknown) {
        setError('customError', {
          type: 'serverSideError',
          message: t('login.error') as string,
        });
      }
    }

    // Login with email and password
    if (currentLoginMethod === 'email') {
      try {
        await AuthService.signInWithEmailAndPassword(data.email, data.password);
      } catch (error: unknown) {
        if (
          error instanceof Error &&
          error.message === AuthError.INVALID_CREDENTIALS
        ) {
          setError('customError', {
            type: 'serverSideError',
            message: t('login.error') as string,
          });
        }
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-3 grow"
    >
      {/* Magic link success alert */}
      {currentLoginMethod === 'magic-link' && isMagicLinkSuccess && (
        <Alert
          type="success"
          title="Revisa tu bandeja de entrada"
          text={`Hemos enviado un link de acceso al email facilitado. Asegúrate de pulsarlo con este dispositivo para iniciar sesión. Si no has recibido el email revisa en la carpeta de spam.`}
          actions={
            <button
              onClick={() => setIsMagicLinkSuccess(false)}
              className="text-sm"
            >
              Reenviar email de nuevo
            </button>
          }
        />
      )}

      {!isMagicLinkSuccess && (
        <>
          <InputText
            id="email"
            type="email"
            label={t('login.email.label') as string}
            hiddenLabel
            placeholder={t('login.email.placeholder')}
            autoComplete="email"
            {...register('email', {
              required: t('login.email.needed') as string,
              validate: (value) =>
                isValidEmail(value) || (t('login.email.invalid') as string),
            })}
            error={formState.errors.email?.message as string}
          />

          {currentLoginMethod === 'email' && (
            <InputText
              id="password"
              type="password"
              label={t('login.password.label') as string}
              hiddenLabel
              showPasswordToggleButton
              autoComplete="current-password"
              placeholder={t('login.password.placeholder')}
              {...register('password', {
                required:
                  currentLoginMethod === 'email'
                    ? (t('login.password.needed') as string)
                    : false,
                minLength: {
                  value: 6,
                  message: t('login.password.requirement') as string,
                },
              })}
              error={formState.errors.password?.message as string}
            />
          )}

          {formState.errors.customError && (
            <Alert
              type="error"
              text={t(formState.errors.customError.message as string)}
            />
          )}

          <Button
            type="submit"
            variant="filled"
            fullWidth
            busy={formState.isSubmitting}
            onClick={() => clearErrors()}
          >
            {currentLoginMethod === 'email' &&
              t('login.email.continue-with-email')}
            {currentLoginMethod === 'magic-link' &&
              t('login.email.continue-with-magic-link')}
          </Button>
        </>
      )}

      {currentLoginMethod === 'magic-link' && !isMagicLinkSuccess && (
        <Alert
          type="info"
          text={t('login.magic-link-info') as string}
          actions={
            isEmailLoginEnabled && (
              <button
                className="text-sm text-clevergy-primary"
                onClick={() => setCurrentLoginMethod('email')}
              >
                {t('login.magic-link-info-action')}
              </button>
            )
          }
        />
      )}

      {currentLoginMethod === 'email' && (
        <>
          <p className="text-clevergy-subtext text-center font-semibold">
            <Link to="/forgot-password" className="text-clevergy-primary">
              {t('login.forgot-password')}
            </Link>
          </p>
          {hasMagicLinkSignInEnabled && (
            <p className="text-clevergy-subtext text-center font-semibold">
              <button
                className=" text-clevergy-primary"
                onClick={() => setCurrentLoginMethod('magic-link')}
              >
                {t('login.magic-link-cta')}
              </button>
            </p>
          )}
        </>
      )}
    </form>
  );
};
