import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { DateRangeType } from '@clevergy/shared/types';
import { TenantLoadingIndicator, useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { ModuleHeader } from '@clevergy/ui/components/ModuleHeader';
import BreakdownModuleDraft from 'assets/illustrations/breakdown-module-draft.svg?react';
import ConsumptionModuleDraft from 'assets/illustrations/consumption-module-draft.svg?react';
import { AnalysisDatePicker } from 'components/AnalysisDatePicker';
import { AppAlerts } from 'components/AppAlerts';
import { AppHeader } from 'components/AppHeader';
import { HousePicker } from 'components/HousePicker';
import { ModuleCard } from 'components/ModuleCard';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { differenceInDays, format } from 'date-fns';
import { useAnalysisPageFilters } from 'hooks/useAnalysisPageFilters';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { dateFnsLocales } from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

export const AnalysisPage: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = dateFnsLocales[language];

  const { token } = useAuthContext();
  const { hasDownloadConsumptionsCsvEnabled } = useTenantSettings();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();

  const {
    dateRangeType,
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
  } = useAnalysisPageFilters();
  const setHasSkippedSmartMeterIntegration = useAppConfigurationStore(
    (state) => state.setHasSkippedSmartMeterIntegration,
  );

  const formattedStartDate = customStartDate
    ? format(customStartDate, 'yyyy-MM-dd')
    : undefined;
  const formattedEndDate = customEndDate
    ? format(customEndDate, 'yyyy-MM-dd')
    : undefined;
  const formattedRangeCurrentDate = dateRangeCurrentDate
    ? format(dateRangeCurrentDate, 'yyyy-MM-dd')
    : undefined;

  const shouldReplaceEnergyWithPower =
    selectedHouse?.dataProviders?.solarInverter &&
    (dateRangeType === DateRangeType.Day ||
      (dateRangeType === DateRangeType.Custom &&
        customStartDate &&
        customEndDate &&
        Math.abs(differenceInDays(customStartDate, customEndDate)) === 0));

  return (
    <>
      <TrackedPage
        event={{
          event_type: 'page_view',
          event_properties: {
            page_name: 'analysis',
          },
        }}
      >
        <AppHeader>
          <HousePicker />
          <AnalysisDatePicker
            texts={{
              day: t('analysis.day'),
              month: t('analysis.month'),
              year: t('analysis.year'),
              custom: t('analysis.custom'),
              cancelButton: t('analysis.cancelButton'),
              okButton: t('analysis.okButton'),
            }}
            locale={locale}
          />
        </AppHeader>
        <div className="bg-[#F3F4F6] min-h-full">
          <PullToRefresh />
          <Wrapper>
            {!token && <TenantLoadingIndicator />}
            {token && (
              <>
                <AppAlerts />

                {selectedHouse && (
                  <clevergy-solar-summary
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-show-dates-selector="false"
                    data-date-range-type={dateRangeType}
                    data-date-range-current-date={formattedRangeCurrentDate}
                    data-custom-start-date={formattedStartDate}
                    data-custom-end-date={formattedEndDate}
                  />
                )}

                {selectedHouse && (
                  <>
                    {shouldReplaceEnergyWithPower ? (
                      <clevergy-power-chart
                        data-title={t('analysis.solarDailyPowerChartTitle')}
                        data-token={token}
                        data-house-id={selectedHouse.houseId}
                        data-current-date={
                          formattedStartDate || formattedRangeCurrentDate
                        }
                      />
                    ) : (
                      <clevergy-energy-chart
                        data-house-id={selectedHouse.houseId}
                        data-token={token}
                        data-show-dates-selector="false"
                        data-date-range-type={dateRangeType}
                        data-date-range-current-date={formattedRangeCurrentDate}
                        data-custom-start-date={formattedStartDate}
                        data-custom-end-date={formattedEndDate}
                        data-show-average-consumption="false"
                        data-show-download-csv={
                          hasDownloadConsumptionsCsvEnabled
                        }
                      ></clevergy-energy-chart>
                    )}
                  </>
                )}

                {selectedHouse && !userInfo.isCompany && (
                  <clevergy-breakdown
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-show-dates-selector="false"
                    data-date-range-type={dateRangeType}
                    data-date-range-current-date={formattedRangeCurrentDate}
                    data-custom-start-date={formattedStartDate}
                    data-custom-end-date={formattedEndDate}
                  ></clevergy-breakdown>
                )}

                {(userStatus === UserSetUpUserStatusEnum.Initial ||
                  userStatus ===
                    UserSetUpUserStatusEnum.AcceptedConditions) && (
                  <>
                    <ModuleCard>
                      <ModuleHeader
                        title="Tu consumo en detalle"
                        subtitle="Consulta todos tus datos de consumo a lo largo del tiempo"
                      />
                      <div className="my-4">
                        <ConsumptionModuleDraft />
                      </div>
                      {userStatus === UserSetUpUserStatusEnum.Initial && (
                        <Button
                          variant="filled"
                          fullWidth
                          onClick={() =>
                            setHasSkippedSmartMeterIntegration(false)
                          }
                        >
                          Conectar mis datos de consumo
                        </Button>
                      )}
                    </ModuleCard>

                    {!userInfo.isCompany && (
                      <ModuleCard>
                        <ModuleHeader
                          title="Tu consumo explicado"
                          subtitle="Averigua qué electrodomésticos consumen más"
                        />
                        <div className="my-4">
                          <BreakdownModuleDraft />
                        </div>
                        {userStatus === UserSetUpUserStatusEnum.Initial && (
                          <Button
                            variant="filled"
                            fullWidth
                            onClick={() =>
                              setHasSkippedSmartMeterIntegration(false)
                            }
                          >
                            Conectar mis datos de consumo
                          </Button>
                        )}
                      </ModuleCard>
                    )}
                  </>
                )}
              </>
            )}
          </Wrapper>
        </div>
      </TrackedPage>
    </>
  );
};
