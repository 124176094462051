import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { AppHeader } from 'components/AppHeader';
import { ModuleSuccess } from 'components/ModuleSuccess';
import { Wrapper } from 'components/Wrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlexaIntegrationPage: FC = () => {
  const { t } = useTranslation();
  return (
    <TrackedPage
      event={{
        event_type: 'page_view',
        event_properties: {
          page_name: 'integration_alexa',
        },
      }}
    >
      <AppHeader backButton title={t('alexa.title') as string} />
      <Wrapper>
        <ModuleSuccess
          title={t('alexa.success.title') as string}
          message={t('alexa.success.message') as string}
        />
      </Wrapper>
    </TrackedPage>
  );
};
