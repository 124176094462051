import { TenantLogo } from '../TenantLogo';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';

export type TenantPageIntroWithLogoProps = {
  title: string;
  subtitle?: string;
};

export const TenantPageIntroWithLogo: FC<TenantPageIntroWithLogoProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="flex flex-col items-center px-4 text-center">
      <TenantLogo type="default" width={256} height={128} />

      <div className="flex flex-col items-center">
        <Heading as="h2" colorScheme="neutral">
          {title}
        </Heading>
        {subtitle && <p className="text-clevergy-subtext">{subtitle}</p>}
      </div>
    </div>
  );
};
