/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemEnergyCommunitiesInner
 */
export interface EnergyItemEnergyCommunitiesInner {
  /**
   *
   * @type {string}
   * @memberof EnergyItemEnergyCommunitiesInner
   */
  type: EnergyItemEnergyCommunitiesInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EnergyItemEnergyCommunitiesInner
   */
  production: number;
}

/**
 * @export
 */
export const EnergyItemEnergyCommunitiesInnerTypeEnum = {
  Solar: 'SOLAR',
  Wind: 'WIND',
  Hydro: 'HYDRO',
} as const;
export type EnergyItemEnergyCommunitiesInnerTypeEnum =
  (typeof EnergyItemEnergyCommunitiesInnerTypeEnum)[keyof typeof EnergyItemEnergyCommunitiesInnerTypeEnum];

/**
 * Check if a given object implements the EnergyItemEnergyCommunitiesInner interface.
 */
export function instanceOfEnergyItemEnergyCommunitiesInner(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'production' in value;

  return isInstance;
}

export function EnergyItemEnergyCommunitiesInnerFromJSON(
  json: any,
): EnergyItemEnergyCommunitiesInner {
  return EnergyItemEnergyCommunitiesInnerFromJSONTyped(json, false);
}

export function EnergyItemEnergyCommunitiesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemEnergyCommunitiesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    production: json['production'],
  };
}

export function EnergyItemEnergyCommunitiesInnerToJSON(
  value?: EnergyItemEnergyCommunitiesInner | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    production: value.production,
  };
}
