import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { useEffect } from 'react';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

export const ClearSkipSmartMeterIntegration = () => {
  const { setHasSkippedSmartMeterIntegration } = useAppConfigurationStore();

  useEffect(() => {
    setHasSkippedSmartMeterIntegration(false);
  }, [setHasSkippedSmartMeterIntegration]);

  return <LoadingIndicator fullScreen="overlay" />;
};
