import { useTenantSettings } from '../../context/TenantSettingsContext';
import { TenantLogo } from '../TenantLogo';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { FC } from 'react';

export const TenantLoadingIndicator: FC = () => {
  const { domains } = useTenantSettings();
  const showLogo = domains.length === 0;
  return (
    <div className="fixed inset-0 z-50 flex h-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2 gap-4">
        {showLogo && <TenantLogo type="small" width={140} />}
        <div className="h-11 flex items-center justify-center">
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
};
