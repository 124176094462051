/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { HouseSettings } from '../models';
import { HouseSettingsFromJSON, HouseSettingsToJSON } from '../models';
import * as runtime from '../runtime';

export interface GetHouseSettingsRequest {
  houseId: string;
}

export interface UpdateHouseSettingsRequest {
  houseId: string;
  houseSettings?: HouseSettings;
}

/**
 *
 */
export class SettingsApi extends runtime.BaseAPI {
  /**
   * Returns house settings stored and if the settings are completed.
   */
  async getHouseSettingsRaw(
    requestParameters: GetHouseSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HouseSettings>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling getHouseSettings.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/settings`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters.houseId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Returns house settings stored and if the settings are completed.
   */
  async getHouseSettings(
    requestParameters: GetHouseSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HouseSettings> {
    const response = await this.getHouseSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update the house settings stored and if the settings are completed.
   */
  async updateHouseSettingsRaw(
    requestParameters: UpdateHouseSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HouseSettings>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling updateHouseSettings.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/settings`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters.houseId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: HouseSettingsToJSON(requestParameters.houseSettings),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Update the house settings stored and if the settings are completed.
   */
  async updateHouseSettings(
    requestParameters: UpdateHouseSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HouseSettings> {
    const response = await this.updateHouseSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
