/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarInfo
 */
export interface SolarInfo {
  /**
   * Total consumption of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  totalConsumptionKwh?: number;
  /**
   * Total production of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  selfProductionKwh?: number;
  /**
   * Total self consumption of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionKwh?: number;
  /**
   * Percentage of self consumption over production
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionOverProductionPercentage?: number;
  /**
   * Percentage of self consumption over consumption
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionOverConsumptionPercentage?: number;
  /**
   * Consumed energy from the grid on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  importKwh?: number;
  /**
   * Percentage of energy consumed from the grid over consumption
   * @type {number}
   * @memberof SolarInfo
   */
  importOverConsumptionPercentage?: number;
  /**
   * Injected energy from the house into the grid on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  exportKwh?: number;
  /**
   * Percentage of energy injected into the grid over production
   * @type {number}
   * @memberof SolarInfo
   */
  exportOverProductionPercentage?: number;
  /**
   * Savings of the current period on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  currentPeriodSavingsKwh?: number;
  /**
   * Savings of the historical period on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  historicalSavingsKwh?: number;
  /**
   * How much CO2 was reduced during the historical period?
   * @type {number}
   * @memberof SolarInfo
   */
  historicalC02Reduced?: number;
  /**
   * How much CO2 was reduced by car usage during the historical period?
   * @type {number}
   * @memberof SolarInfo
   */
  historicalCarUsageReduced?: number;
}

/**
 * Check if a given object implements the SolarInfo interface.
 */
export function instanceOfSolarInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SolarInfoFromJSON(json: any): SolarInfo {
  return SolarInfoFromJSONTyped(json, false);
}

export function SolarInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalConsumptionKwh: !exists(json, 'totalConsumptionKwh')
      ? undefined
      : json['totalConsumptionKwh'],
    selfProductionKwh: !exists(json, 'selfProductionKwh')
      ? undefined
      : json['selfProductionKwh'],
    selfConsumptionKwh: !exists(json, 'selfConsumptionKwh')
      ? undefined
      : json['selfConsumptionKwh'],
    selfConsumptionOverProductionPercentage: !exists(
      json,
      'selfConsumptionOverProductionPercentage',
    )
      ? undefined
      : json['selfConsumptionOverProductionPercentage'],
    selfConsumptionOverConsumptionPercentage: !exists(
      json,
      'selfConsumptionOverConsumptionPercentage',
    )
      ? undefined
      : json['selfConsumptionOverConsumptionPercentage'],
    importKwh: !exists(json, 'importKwh') ? undefined : json['importKwh'],
    importOverConsumptionPercentage: !exists(
      json,
      'importOverConsumptionPercentage',
    )
      ? undefined
      : json['importOverConsumptionPercentage'],
    exportKwh: !exists(json, 'exportKwh') ? undefined : json['exportKwh'],
    exportOverProductionPercentage: !exists(
      json,
      'exportOverProductionPercentage',
    )
      ? undefined
      : json['exportOverProductionPercentage'],
    currentPeriodSavingsKwh: !exists(json, 'currentPeriodSavingsKwh')
      ? undefined
      : json['currentPeriodSavingsKwh'],
    historicalSavingsKwh: !exists(json, 'historicalSavingsKwh')
      ? undefined
      : json['historicalSavingsKwh'],
    historicalC02Reduced: !exists(json, 'historicalC02Reduced')
      ? undefined
      : json['historicalC02Reduced'],
    historicalCarUsageReduced: !exists(json, 'historicalCarUsageReduced')
      ? undefined
      : json['historicalCarUsageReduced'],
  };
}

export function SolarInfoToJSON(value?: SolarInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalConsumptionKwh: value.totalConsumptionKwh,
    selfProductionKwh: value.selfProductionKwh,
    selfConsumptionKwh: value.selfConsumptionKwh,
    selfConsumptionOverProductionPercentage:
      value.selfConsumptionOverProductionPercentage,
    selfConsumptionOverConsumptionPercentage:
      value.selfConsumptionOverConsumptionPercentage,
    importKwh: value.importKwh,
    importOverConsumptionPercentage: value.importOverConsumptionPercentage,
    exportKwh: value.exportKwh,
    exportOverProductionPercentage: value.exportOverProductionPercentage,
    currentPeriodSavingsKwh: value.currentPeriodSavingsKwh,
    historicalSavingsKwh: value.historicalSavingsKwh,
    historicalC02Reduced: value.historicalC02Reduced,
    historicalCarUsageReduced: value.historicalCarUsageReduced,
  };
}
