import { useTenantSettings } from '@clevergy/tenants';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

export const GoogleTagManager: FC = () => {
  const tenantSettings = useTenantSettings();
  const { googleTagManagerId } = tenantSettings;

  if (!googleTagManagerId) {
    return null;
  }

  const link = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`;
  const gtag = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', '${googleTagManagerId}');
  `;

  return (
    <>
      <Helmet>
        <script async src={link}></script>
        <script>{gtag}</script>
      </Helmet>
    </>
  );
};
