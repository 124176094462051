/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 * Detail of smart device
 * @export
 * @interface GetSmartDevicesResponse
 */
export interface GetSmartDevicesResponse {
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  vendor: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  subtype: GetSmartDevicesResponseSubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof GetSmartDevicesResponse
   */
  channel: number;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  status: GetSmartDevicesResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  model: GetSmartDevicesResponseModelEnum;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  deviceType: GetSmartDevicesResponseDeviceTypeEnum;
}

/**
 * @export
 */
export const GetSmartDevicesResponseSubtypeEnum = {
  Grid: 'GRID',
  SelfProduction: 'SELF_PRODUCTION',
  Battery: 'BATTERY',
  Export: 'EXPORT',
  Dishwasher: 'DISHWASHER',
  Kitchen: 'KITCHEN',
  Fridge: 'FRIDGE',
  Freezer: 'FREEZER',
  Microwave: 'MICROWAVE',
  Oven: 'OVEN',
  Ac: 'AC',
  PlugAc: 'PLUG_AC',
  WashingMachine: 'WASHING_MACHINE',
  WaterHeater: 'WATER_HEATER',
  Workstation: 'WORKSTATION',
  Vacuum: 'VACUUM',
  Heater: 'HEATER',
  PlugHeater: 'PLUG_HEATER',
  Tv: 'TV',
  Dryer: 'DRYER',
  Light: 'LIGHT',
  Pool: 'POOL',
  Aerothermal: 'AEROTHERMAL',
  Unknown: 'UNKNOWN',
  Other: 'OTHER',
} as const;
export type GetSmartDevicesResponseSubtypeEnum =
  (typeof GetSmartDevicesResponseSubtypeEnum)[keyof typeof GetSmartDevicesResponseSubtypeEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseStatusEnum = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
} as const;
export type GetSmartDevicesResponseStatusEnum =
  (typeof GetSmartDevicesResponseStatusEnum)[keyof typeof GetSmartDevicesResponseStatusEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseModelEnum = {
  PlugS: 'SHELLY_PLUG_S',
  Em: 'SHELLY_EM',
} as const;
export type GetSmartDevicesResponseModelEnum =
  (typeof GetSmartDevicesResponseModelEnum)[keyof typeof GetSmartDevicesResponseModelEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseDeviceTypeEnum = {
  Relay: 'RELAY',
  Emeter: 'EMETER',
} as const;
export type GetSmartDevicesResponseDeviceTypeEnum =
  (typeof GetSmartDevicesResponseDeviceTypeEnum)[keyof typeof GetSmartDevicesResponseDeviceTypeEnum];

/**
 * Check if a given object implements the GetSmartDevicesResponse interface.
 */
export function instanceOfGetSmartDevicesResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'deviceId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'vendor' in value;
  isInstance = isInstance && 'subtype' in value;
  isInstance = isInstance && 'channel' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'model' in value;
  isInstance = isInstance && 'deviceType' in value;

  return isInstance;
}

export function GetSmartDevicesResponseFromJSON(
  json: any,
): GetSmartDevicesResponse {
  return GetSmartDevicesResponseFromJSONTyped(json, false);
}

export function GetSmartDevicesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetSmartDevicesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deviceId: json['deviceId'],
    name: json['name'],
    vendor: json['vendor'],
    subtype: json['subtype'],
    channel: json['channel'],
    status: json['status'],
    model: json['model'],
    deviceType: json['deviceType'],
  };
}

export function GetSmartDevicesResponseToJSON(
  value?: GetSmartDevicesResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deviceId: value.deviceId,
    name: value.name,
    vendor: value.vendor,
    subtype: value.subtype,
    channel: value.channel,
    status: value.status,
    model: value.model,
    deviceType: value.deviceType,
  };
}
