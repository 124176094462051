import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import {
  ClevergyEvent,
  ClevergyEventTypes,
  useClevergyEvent,
} from '@clevergy/event-bus';
import { AppHeader } from 'components/AppHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserCompletionsWhileOnboardingStore } from 'stores/userCompletionsWhileOnboardingStore';
import { getHouseSettingsTrackingEventObject } from 'utils/getHouseSettingsTrackingEventObject';

export const OnBoardingHouseSettingsPage: FC = () => {
  const { token } = useAuthContext();
  const { t } = useTranslation();
  const { track } = useAnalyticsContext();
  const { completeUserCompletionsStep } =
    useUserCompletionsWhileOnboardingStore((state) => ({
      completeUserCompletionsStep: state.completeUserCompletionsStep,
    }));

  const trackEvent = (event: ClevergyEvent) => {
    const trackingEventObject = getHouseSettingsTrackingEventObject({
      event,
      page: 'onboarding',
    });

    track(trackingEventObject);
  };

  const navigateToNextStep = () => {
    completeUserCompletionsStep('houseSettings');
  };

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_SAVED, (event) => {
    trackEvent(event);
    navigateToNextStep();
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_NOT_SAVED, (event) => {
    trackEvent(event);
    navigateToNextStep();
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(
    ClevergyEventTypes.HOUSE_SETTINGS_SAVED_IN_BACKUP,
    (event) => {
      trackEvent(event);
      navigateToNextStep();
    },
  );

  return (
    <TrackedPage
      event={{
        event_type: 'house_settings_asked',
      }}
    >
      <AppHeader
        title={
          t('houseSettings.onboarding.configure-your-house-title') as string
        }
      />
      <Wrapper safeArea={['bottom']}>
        {token && (
          <div className="flex flex-col gap-4">
            <p>{t('houseSettings.onboarding.configure-your-house-content')}</p>
            <clevergy-house-settings
              data-token={token}
            ></clevergy-house-settings>
          </div>
        )}
      </Wrapper>
    </TrackedPage>
  );
};
