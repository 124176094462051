import { Button } from '@clevergy/ui/components/Button';
import { Modal } from '@clevergy/ui/components/Modal';
import { Prose } from '@clevergy/ui/components/Prose';
import { useMutation } from '@tanstack/react-query';
import DeleteAccountImage from 'assets/illustrations/delete-account.svg?react';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appSettingsStore';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  onAccountDeleted: () => void;
}

export const DeleteAccountModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  onAccountDeleted,
}: Props) => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const resetMultiTenant = useAppConfigurationStore(
    (state) => () => state.setTenantId(undefined),
  );

  const userId = authedUser?.uid;

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) =>
      api.users.createDeleteUserAccountRequest({ userId }),
  });

  const onDeleteAccountClick = async () => {
    if (!userId) {
      onCloseModal();
      return;
    }
    await deleteUserMutation.mutateAsync(userId);
    resetMultiTenant();
    onAccountDeleted();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onCloseModal()}>
      <div className="my-8 flex flex-col items-center gap-4 text-center">
        <DeleteAccountImage />
        <Prose>
          <Trans>account.modal.message</Trans>
        </Prose>
      </div>

      <Button
        fullWidth
        colorScheme="danger"
        onClick={onDeleteAccountClick}
        busy={deleteUserMutation.isPending}
        disabled={deleteUserMutation.isPending}
      >
        {t('account.modal.confirm-account-deletion')}
      </Button>
    </Modal>
  );
};
