import { tenantsApiBaseUrl } from '@clevergy/shared/constants/default';
import { useTenantSettings } from '@clevergy/tenants';
import { injectCssVariables } from '@clevergy/utils/browser/injectCssVariables';
import { FC, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';

export type TenantPageProps = {
  children: ReactNode;
};

export const TenantPage: FC<TenantPageProps> = ({ children }) => {
  const tenantSettings = useTenantSettings();

  useEffect(() => {
    injectCssVariables(tenantSettings.theme.cssVariables);
  }, [tenantSettings.theme]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: tenantSettings.languageCodeShort }}>
        <title>{tenantSettings.tenantName}</title>
        <link
          rel="icon"
          href={`${tenantsApiBaseUrl}/public-assets/${tenantSettings.tenantId}/favicon.png`}
        />
        {tenantSettings.theme.fontfaceUrl && (
          <link rel="stylesheet" href={tenantSettings.theme.fontfaceUrl} />
        )}
      </Helmet>
      {children}
    </>
  );
};
