import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  tenantId: undefined,
  isOnboardingLessonsCompleted: false,
  hasSkippedSmartMeterIntegration: false,
  hasAnsweredNotifications: false,
};

export type AppConfigurationStoreState = {
  tenantId: string | undefined;
  isOnboardingLessonsCompleted: boolean;
  hasSkippedSmartMeterIntegration: boolean;
  hasAnsweredNotifications: boolean;
  setTenantId: (tenantId: string | undefined) => void;
  setIsOnboardingLessonsAsCompleted: () => void;
  setHasSkippedSmartMeterIntegration: (newState: boolean) => void;
  setHasAnsweredNotifications: (newState: boolean) => void;
  reset: () => void;
};

export const useAppConfigurationStore = create<AppConfigurationStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setTenantId: (tenantId) => set({ tenantId }),
      setIsOnboardingLessonsAsCompleted: () =>
        set({ isOnboardingLessonsCompleted: true }),
      setHasSkippedSmartMeterIntegration: (newState: boolean) =>
        set({ hasSkippedSmartMeterIntegration: newState }),
      setHasAnsweredNotifications: (newState: boolean) =>
        set({ hasAnsweredNotifications: newState }),
      reset: () =>
        set({
          ...initialState,
        }),
    }),
    {
      name: 'appConfigurationStoreState',
      version: 3,
      // Migrations
      migrate(persistedState, version) {
        // Migrate from version 0 to 1 by adding hasAnsweredNotifications to true
        if (version === 0) {
          return {
            ...((persistedState as AppConfigurationStoreState) || initialState),
            hasAnsweredNotifications: true,
          } as AppConfigurationStoreState;
        }
        // Migrate from version 1 to 2 by adding hasSkippedSmartMeterIntegration
        // to version 1 hasSkippedRegister or false
        if (version === 1) {
          return {
            tenantId: (persistedState as AppConfigurationStoreState)?.tenantId,
            isOnboardingLessonsCompleted:
              (persistedState as AppConfigurationStoreState)
                ?.isOnboardingLessonsCompleted || false,
            hasAnsweredNotifications:
              (persistedState as AppConfigurationStoreState)
                ?.hasAnsweredNotifications || false,
            hasSkippedSmartMeterIntegration:
              (
                persistedState as AppConfigurationStoreState & {
                  hasSkippedRegister: boolean;
                }
              )?.hasSkippedRegister || false,
          } as AppConfigurationStoreState;
        }
        if (version === 2) {
          return {
            ...((persistedState as AppConfigurationStoreState) || initialState),
            hasSkippedSmartMeterIntegration: false,
          };
        }
        return (persistedState as AppConfigurationStoreState) || initialState;
      },
    },
  ),
);
