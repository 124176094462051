/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  GetSmartDevicesResponse,
  HttpError,
  UpdateSmartDeviceRequest,
} from '../models';
import {
  GetSmartDevicesResponseFromJSON,
  GetSmartDevicesResponseToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  UpdateSmartDeviceRequestFromJSON,
  UpdateSmartDeviceRequestToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface ControlSmartDeviceRequest {
  houseId: string;
  deviceId: string;
  status: ControlSmartDeviceStatusEnum;
}

export interface GetSmartDeviceDetailsRequest {
  houseId: string;
  deviceId: string;
  channel: number;
}

export interface GetSmartDevicesRequest {
  houseId: string;
}

export interface UpdateSmartDeviceOperationRequest {
  houseId: string;
  deviceId: string;
  channel: number;
  updateSmartDeviceRequest?: UpdateSmartDeviceRequest;
}

/**
 *
 */
export class SmartDevicesApi extends runtime.BaseAPI {
  /**
   * Control smart device status
   */
  async controlSmartDeviceRaw(
    requestParameters: ControlSmartDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling controlSmartDevice.',
      );
    }

    if (
      requestParameters.deviceId === null ||
      requestParameters.deviceId === undefined
    ) {
      throw new runtime.RequiredError(
        'deviceId',
        'Required parameter requestParameters.deviceId was null or undefined when calling controlSmartDevice.',
      );
    }

    if (
      requestParameters.status === null ||
      requestParameters.status === undefined
    ) {
      throw new runtime.RequiredError(
        'status',
        'Required parameter requestParameters.status was null or undefined when calling controlSmartDevice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/smartDevices/{deviceId}/control/{status}`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters.houseId)),
          )
          .replace(
            `{${'deviceId'}}`,
            encodeURIComponent(String(requestParameters.deviceId)),
          )
          .replace(
            `{${'status'}}`,
            encodeURIComponent(String(requestParameters.status)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Control smart device status
   */
  async controlSmartDevice(
    requestParameters: ControlSmartDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.controlSmartDeviceRaw(requestParameters, initOverrides);
  }

  /**
   * Get smart device details
   */
  async getSmartDeviceDetailsRaw(
    requestParameters: GetSmartDeviceDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetSmartDevicesResponse>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling getSmartDeviceDetails.',
      );
    }

    if (
      requestParameters.deviceId === null ||
      requestParameters.deviceId === undefined
    ) {
      throw new runtime.RequiredError(
        'deviceId',
        'Required parameter requestParameters.deviceId was null or undefined when calling getSmartDeviceDetails.',
      );
    }

    if (
      requestParameters.channel === null ||
      requestParameters.channel === undefined
    ) {
      throw new runtime.RequiredError(
        'channel',
        'Required parameter requestParameters.channel was null or undefined when calling getSmartDeviceDetails.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/smartDevices/{deviceId}/channel/{channel}`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters.houseId)),
          )
          .replace(
            `{${'deviceId'}}`,
            encodeURIComponent(String(requestParameters.deviceId)),
          )
          .replace(
            `{${'channel'}}`,
            encodeURIComponent(String(requestParameters.channel)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetSmartDevicesResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get smart device details
   */
  async getSmartDeviceDetails(
    requestParameters: GetSmartDeviceDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetSmartDevicesResponse> {
    const response = await this.getSmartDeviceDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get list of smart devices
   */
  async getSmartDevicesRaw(
    requestParameters: GetSmartDevicesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<GetSmartDevicesResponse>>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling getSmartDevices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/smartDevices`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters.houseId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(GetSmartDevicesResponseFromJSON),
    );
  }

  /**
   * Get list of smart devices
   */
  async getSmartDevices(
    requestParameters: GetSmartDevicesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<GetSmartDevicesResponse>> {
    const response = await this.getSmartDevicesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update smart device subtype
   */
  async updateSmartDeviceRaw(
    requestParameters: UpdateSmartDeviceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.houseId === null ||
      requestParameters.houseId === undefined
    ) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter requestParameters.houseId was null or undefined when calling updateSmartDevice.',
      );
    }

    if (
      requestParameters.deviceId === null ||
      requestParameters.deviceId === undefined
    ) {
      throw new runtime.RequiredError(
        'deviceId',
        'Required parameter requestParameters.deviceId was null or undefined when calling updateSmartDevice.',
      );
    }

    if (
      requestParameters.channel === null ||
      requestParameters.channel === undefined
    ) {
      throw new runtime.RequiredError(
        'channel',
        'Required parameter requestParameters.channel was null or undefined when calling updateSmartDevice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/smartDevices/{deviceId}/channel/{channel}`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters.houseId)),
          )
          .replace(
            `{${'deviceId'}}`,
            encodeURIComponent(String(requestParameters.deviceId)),
          )
          .replace(
            `{${'channel'}}`,
            encodeURIComponent(String(requestParameters.channel)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSmartDeviceRequestToJSON(
          requestParameters.updateSmartDeviceRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update smart device subtype
   */
  async updateSmartDevice(
    requestParameters: UpdateSmartDeviceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateSmartDeviceRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const ControlSmartDeviceStatusEnum = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
} as const;
export type ControlSmartDeviceStatusEnum =
  (typeof ControlSmartDeviceStatusEnum)[keyof typeof ControlSmartDeviceStatusEnum];
