/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { DataProviders } from './DataProviders';
import {
  DataProvidersFromJSON,
  DataProvidersFromJSONTyped,
  DataProvidersToJSON,
} from './DataProviders';

/**
 *
 * @export
 * @interface UserSetUpHouse
 */
export interface UserSetUpHouse {
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  cups: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  postalCode: string;
  /**
   *
   * @type {DataProviders}
   * @memberof UserSetUpHouse
   */
  dataProviders?: DataProviders;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  timeZone: string;
}

/**
 * Check if a given object implements the UserSetUpHouse interface.
 */
export function instanceOfUserSetUpHouse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'houseId' in value;
  isInstance = isInstance && 'cups' in value;
  isInstance = isInstance && 'address' in value;
  isInstance = isInstance && 'postalCode' in value;
  isInstance = isInstance && 'timeZone' in value;

  return isInstance;
}

export function UserSetUpHouseFromJSON(json: any): UserSetUpHouse {
  return UserSetUpHouseFromJSONTyped(json, false);
}

export function UserSetUpHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSetUpHouse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    cups: json['cups'],
    address: json['address'],
    postalCode: json['postalCode'],
    dataProviders: !exists(json, 'dataProviders')
      ? undefined
      : DataProvidersFromJSON(json['dataProviders']),
    timeZone: json['timeZone'],
  };
}

export function UserSetUpHouseToJSON(value?: UserSetUpHouse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    houseId: value.houseId,
    cups: value.cups,
    address: value.address,
    postalCode: value.postalCode,
    dataProviders: DataProvidersToJSON(value.dataProviders),
    timeZone: value.timeZone,
  };
}
