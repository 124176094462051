import { LoadingIndicator } from '../LoadingIndicator';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { FC, ReactNode } from 'react';

const buttonClasses = cva(
  'rounded-button inline-flex min-h-[42px] items-center justify-center gap-3 px-6 py-2 font-bold disabled:opacity-60',
  {
    variants: {
      variant: {
        filled: '',
        outlined: '',
        flat: 'min-h-0 !px-0 !py-0 rounded-none',
      },
      colorScheme: {
        primary: '',
        secondary: '',
        neutral: '',
        danger: '',
      },
      // NOTE: inverted is only used for filled variant
      inverted: {
        true: '',
      },
      fullWidth: {
        true: 'w-full',
      },
      disabled: {
        true: 'opacity-60',
      },
    },
    compoundVariants: [
      {
        variant: 'filled',
        colorScheme: 'primary',
        inverted: false,
        className: 'bg-clevergy-button text-white',
      },
      {
        variant: 'filled',
        colorScheme: 'primary',
        inverted: true,
        className: 'bg-white text-clevergy-primary',
      },
      {
        variant: 'filled',
        colorScheme: 'secondary',
        inverted: false,
        className: 'bg-clevergy-secondary text-white',
      },
      {
        variant: 'filled',
        colorScheme: 'secondary',
        inverted: true,
        className: 'bg-white text-clevergy-secondary',
      },
      {
        variant: 'filled',
        colorScheme: 'neutral',
        inverted: false,
        className: 'bg-clevergy-subtext text-white',
      },
      {
        variant: 'filled',
        colorScheme: 'neutral',
        inverted: true,
        className: 'bg-white text-clevergy-subtext',
      },
      {
        variant: 'filled',
        colorScheme: 'danger',
        inverted: false,
        className: 'bg-red-600 text-white',
      },
      {
        variant: 'outlined',
        colorScheme: 'primary',
        inverted: false,
        className:
          'border-clevergy-button text-clevergy-button border border-solid',
      },
      {
        variant: 'outlined',
        colorScheme: 'neutral',
        inverted: false,
        className: 'border-gray-300 text-clevergy-subtext border border-solid',
      },
      {
        variant: 'outlined',
        colorScheme: 'danger',
        inverted: false,
        className: 'border-red-600 text-red-600 border border-solid',
      },
      {
        variant: 'flat',
        colorScheme: 'primary',
        inverted: false,
        className: 'text-clevergy-button',
      },
      {
        variant: 'flat',
        colorScheme: 'neutral',
        inverted: false,
        className: 'text-clevergy-subtext',
      },
      {
        variant: 'flat',
        colorScheme: 'danger',
        inverted: false,
        className: 'text-red-600',
      },
    ],
    defaultVariants: {
      variant: 'filled',
      colorScheme: 'primary',
      inverted: false,
      fullWidth: false,
      disabled: false,
    },
  },
);

type ButtonBaseProps = VariantProps<typeof buttonClasses> & {
  /**
   * Show loading indicator
   * @default false
   */
  busy?: boolean;
  /**
   * Click handler
   */
  onClick?: () => void;
  /**
   * Children
   */
  children: ReactNode;
};

type ButtonAsButtonProps = ButtonBaseProps & {
  /**
   * Render as button tag
   */
  as?: 'button';
  /**
   * Button type
   * @default button
   */
  type?: 'button' | 'submit' | 'reset';
};

type ButtonAsAnchorProps = ButtonBaseProps & {
  /**
   * Render as anchor tag
   */
  as: 'a';
  /**
   * Anchor href
   */
  href?: string;
  /**
   * Rel attribute for anchor
   */
  rel?: string;
  /**
   * Target attribute for anchor
   */
  target?: string;
};

export type ButtonProps = ButtonAsAnchorProps | ButtonAsButtonProps;

export const Button: FC<ButtonAsAnchorProps | ButtonAsButtonProps> = ({
  as = 'button',
  ...otherProps
}) => {
  // render as anchor
  if (as === 'a') {
    const {
      variant,
      colorScheme,
      inverted,
      fullWidth,
      disabled,
      href,
      target,
      rel,
      busy,
      onClick,
      children,
    } = otherProps as ButtonAsAnchorProps;
    return (
      <a
        className={buttonClasses({
          variant,
          colorScheme,
          inverted: variant === 'filled' ? inverted : false,
          fullWidth,
          disabled,
        })}
        href={href}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {busy ? (
          <LoadingIndicator
            colorScheme={
              variant === 'filled' && !inverted ? 'white' : 'primary'
            }
          />
        ) : (
          <>{children}</>
        )}
      </a>
    );
  }

  // render as button
  const {
    variant,
    colorScheme,
    inverted,
    fullWidth,
    type = 'button',
    disabled,
    busy,
    children,
    onClick,
  } = otherProps as ButtonAsButtonProps;
  return (
    <button
      className={buttonClasses({
        variant,
        colorScheme,
        inverted: variant === 'filled' ? inverted : false,
        fullWidth,
        disabled,
      })}
      type={type}
      disabled={Boolean(disabled)}
      onClick={onClick}
    >
      {busy ? (
        <LoadingIndicator
          colorScheme={variant === 'filled' && !inverted ? 'white' : 'primary'}
        />
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
