/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ConnectWithAlexaRequest,
  CreateAlexaUserAuthCodeRequest,
  CreateAlexaUserAuthCodeResponse,
} from '../models';
import {
  ConnectWithAlexaRequestFromJSON,
  ConnectWithAlexaRequestToJSON,
  CreateAlexaUserAuthCodeRequestFromJSON,
  CreateAlexaUserAuthCodeRequestToJSON,
  CreateAlexaUserAuthCodeResponseFromJSON,
  CreateAlexaUserAuthCodeResponseToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface ConnectWithAlexaOperationRequest {
  userId: string;
  connectWithAlexaCredentials?: ConnectWithAlexaRequest;
}

export interface CreateAlexaUserAuthCodeOperationRequest {
  userId: string;
  createAlexaUserAuthCodeRequest?: CreateAlexaUserAuthCodeRequest;
}

/**
 *
 */
export class AlexaApi extends runtime.BaseAPI {
  /**
   * Save user alexa credentials
   */
  async connectWithAlexaRaw(
    requestParameters: ConnectWithAlexaOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling connectWithAlexa.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/integrations/alexa`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConnectWithAlexaRequestToJSON(
          requestParameters.connectWithAlexaCredentials,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Save user alexa credentials
   */
  async connectWithAlexa(
    requestParameters: ConnectWithAlexaOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.connectWithAlexaRaw(requestParameters, initOverrides);
  }

  /**
   * Create an user auth code for alexa
   */
  async createAlexaUserAuthCodeRaw(
    requestParameters: CreateAlexaUserAuthCodeOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateAlexaUserAuthCodeResponse>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling createAlexaUserAuthCode.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/integrations/alexa/user-auth-code`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateAlexaUserAuthCodeRequestToJSON(
          requestParameters.createAlexaUserAuthCodeRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateAlexaUserAuthCodeResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create an user auth code for alexa
   */
  async createAlexaUserAuthCode(
    requestParameters: CreateAlexaUserAuthCodeOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateAlexaUserAuthCodeResponse> {
    const response = await this.createAlexaUserAuthCodeRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
