import { TrackedPage } from '@clevergy/amplitude/components/TrackedPage';
import { TenantIcon, useTenantSettings } from '@clevergy/tenants';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { isClevergyEmail } from '@clevergy/utils/validations/isClevergyEmail';
import { AppVersionInfo } from 'components/AppVersionInfo';
import { Wrapper } from 'components/Wrapper';
import { useUserSetupContext } from 'context/UserSetupContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();

  const { hasInvoicesEnabled, hasContactInfoPageEnabled, tenantId } =
    useTenantSettings();

  return (
    <TrackedPage
      event={{
        event_type: 'page_view',
        event_properties: {
          page_name: 'profile',
        },
      }}
    >
      <Wrapper safeArea>
        <div className="flex flex-col">
          <div className="grow">
            <List>
              <>
                <List.Item
                  leftIcon={<FontIcon name="person" />}
                  rightIcon={<FontIcon name="chevron_right" />}
                  onClick={() => navigate('/account')}
                >
                  {t('account.button')}
                </List.Item>

                {selectedHouse && !userInfo.isCompany && (
                  <List.Item
                    leftIcon={<FontIcon name="home" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/settings`)
                    }
                  >
                    {t('house-settings.button')}
                  </List.Item>
                )}

                {selectedHouse && hasInvoicesEnabled && (
                  <List.Item
                    leftIcon={<FontIcon name="article" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/invoices`)
                    }
                  >
                    {t('invoices.button')}
                  </List.Item>
                )}

                {selectedHouse &&
                  // Hack requested by @Javier Martinez
                  (userInfo.email === 'jm46685@gmail.com' ||
                    isClevergyEmail(userInfo.email)) && (
                    <List.Item
                      leftIcon={<TenantIcon name="SmartPlug" />}
                      rightIcon={<FontIcon name="chevron_right" />}
                      onClick={() =>
                        navigate(
                          `/houses/${selectedHouse.houseId}/smart-devices`,
                        )
                      }
                    >
                      {t('smartDevices.title')}{' '}
                    </List.Item>
                  )}

                {selectedHouse && tenantId === 'clevergy' && (
                  <List.Item
                    leftIcon={<FontIcon name="home_iot_device" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() => navigate('/integrations')}
                  >
                    {t('integrations.button')}
                  </List.Item>
                )}

                {selectedHouse && (
                  <List.Item
                    leftIcon={<FontIcon name="notifications" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/alerts`)
                    }
                  >
                    {t('alerts.button')}
                  </List.Item>
                )}

                <List.Item
                  leftIcon={<FontIcon name="contact_support" />}
                  rightIcon={<FontIcon name="chevron_right" />}
                  onClick={() => navigate('/support')}
                >
                  {t('support.button')}
                </List.Item>

                {hasContactInfoPageEnabled && (
                  <List.Item
                    leftIcon={<FontIcon name="phone" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() => navigate('/contact')}
                  >
                    {t('contact.button')}
                  </List.Item>
                )}
              </>
            </List>
          </div>

          <AppVersionInfo />
        </div>
      </Wrapper>
    </TrackedPage>
  );
};
