/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface DataConsent
 */
export interface DataConsent {
  /**
   *
   * @type {string}
   * @memberof DataConsent
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof DataConsent
   */
  ipAddress?: string;
}

/**
 * Check if a given object implements the DataConsent interface.
 */
export function instanceOfDataConsent(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DataConsentFromJSON(json: any): DataConsent {
  return DataConsentFromJSONTyped(json, false);
}

export function DataConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataConsent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, 'userId') ? undefined : json['userId'],
    ipAddress: !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
  };
}

export function DataConsentToJSON(value?: DataConsent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    ipAddress: value.ipAddress,
  };
}
