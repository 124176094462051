import { useAuthContext } from './AuthContext';
import {
  AlexaApi,
  Configuration as ClevergyConfiguration,
  EnergyApi,
  InvoicesApi,
  OptimizeApi,
  SettingsApi,
  SolarApi,
  SmartDevicesApi,
  UsersApi,
} from '@clevergy/api-client';
import {
  TicketsApi,
  Configuration as TicketsConfiguration,
} from '@clevergy/api-client-portal-routes';
import { FC, ReactNode, createContext, useContext } from 'react';

export const ApiContext = createContext<{
  api: {
    alexa: AlexaApi;
    energy: EnergyApi;
    invoices: InvoicesApi;
    optimize: OptimizeApi;
    settings: SettingsApi;
    solar: SolarApi;
    smartDevices: SmartDevicesApi;
    users: UsersApi;
    tickets: TicketsApi;
  };
} | null>(null);

export const ApiProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { refreshToken } = useAuthContext();

  const config = {
    accessToken: async () => {
      const token = await refreshToken();
      return `Bearer ${token}`;
    },
  };
  const clevergyConfig = new ClevergyConfiguration(config);
  const ticketsConfig = new TicketsConfiguration(config);

  const api = {
    alexa: new AlexaApi(clevergyConfig),
    energy: new EnergyApi(clevergyConfig),
    invoices: new InvoicesApi(clevergyConfig),
    optimize: new OptimizeApi(clevergyConfig),
    settings: new SettingsApi(clevergyConfig),
    solar: new SolarApi(clevergyConfig),
    smartDevices: new SmartDevicesApi(clevergyConfig),
    users: new UsersApi(clevergyConfig),
    tickets: new TicketsApi(ticketsConfig),
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};

export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw Error('useApiContext must be used inside of a ApiContext');
  }
  return context;
};
