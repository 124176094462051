export enum IssueId {
  IntegrationAlexa = 'integrationAlexa',
  Register = 'register',
  ConsumptionData = 'consumptionData',
  SolarInstallation = 'solarInstallation',
  Billing = 'billing',
  Other = 'other',
}

export type Issue = {
  id: IssueId;
  text: string;
};
