/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  HttpError,
  NewComment,
  NewTicket,
  Ticket,
  TicketsList,
} from '../models';
import {
  HttpErrorFromJSON,
  HttpErrorToJSON,
  NewCommentFromJSON,
  NewCommentToJSON,
  NewTicketFromJSON,
  NewTicketToJSON,
  TicketFromJSON,
  TicketToJSON,
  TicketsListFromJSON,
  TicketsListToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface AddCommentRequest {
  ticketId: string;
  body: NewComment;
}

export interface CreateTicketRequest {
  ticket: NewTicket;
}

export interface GetTicketRequest {
  ticketId: string;
}

export interface GetTicketsListRequest {
  page?: number;
  size?: number;
  sort?: string;
  direction?: GetTicketsListDirectionEnum;
}

/**
 *
 */
export class TicketsApi extends runtime.BaseAPI {
  /**
   * Add a new comment to a ticket
   */
  async addCommentRaw(
    requestParameters: AddCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling addComment.',
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling addComment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/consumer/tickets/{ticketId}/comments`.replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewCommentToJSON(requestParameters.body),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add a new comment to a ticket
   */
  async addComment(
    requestParameters: AddCommentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addCommentRaw(requestParameters, initOverrides);
  }

  /**
   * Create a new ticket
   */
  async createTicketRaw(
    requestParameters: CreateTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.ticket === null ||
      requestParameters.ticket === undefined
    ) {
      throw new runtime.RequiredError(
        'ticket',
        'Required parameter requestParameters.ticket was null or undefined when calling createTicket.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/consumer/tickets`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewTicketToJSON(requestParameters.ticket),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create a new ticket
   */
  async createTicket(
    requestParameters: CreateTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createTicketRaw(requestParameters, initOverrides);
  }

  /**
   * Get ticket details
   */
  async getTicketRaw(
    requestParameters: GetTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Ticket>> {
    if (
      requestParameters.ticketId === null ||
      requestParameters.ticketId === undefined
    ) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter requestParameters.ticketId was null or undefined when calling getTicket.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/consumer/tickets/{ticketId}`.replace(
          `{${'ticketId'}}`,
          encodeURIComponent(String(requestParameters.ticketId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketFromJSON(jsonValue),
    );
  }

  /**
   * Get ticket details
   */
  async getTicket(
    requestParameters: GetTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Ticket> {
    const response = await this.getTicketRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get tickets list
   */
  async getTicketsListRaw(
    requestParameters: GetTicketsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TicketsList>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters['direction'] = requestParameters.direction;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/consumer/tickets`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketsListFromJSON(jsonValue),
    );
  }

  /**
   * Get tickets list
   */
  async getTicketsList(
    requestParameters: GetTicketsListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TicketsList> {
    const response = await this.getTicketsListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const GetTicketsListDirectionEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;
export type GetTicketsListDirectionEnum =
  (typeof GetTicketsListDirectionEnum)[keyof typeof GetTicketsListDirectionEnum];
