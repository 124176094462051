/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemGrid
 */
export interface EnergyItemGrid {
  /**
   *
   * @type {number}
   * @memberof EnergyItemGrid
   */
  _import?: number;
  /**
   *
   * @type {number}
   * @memberof EnergyItemGrid
   */
  _export?: number;
}

/**
 * Check if a given object implements the EnergyItemGrid interface.
 */
export function instanceOfEnergyItemGrid(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EnergyItemGridFromJSON(json: any): EnergyItemGrid {
  return EnergyItemGridFromJSONTyped(json, false);
}

export function EnergyItemGridFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemGrid {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _import: !exists(json, 'import') ? undefined : json['import'],
    _export: !exists(json, 'export') ? undefined : json['export'],
  };
}

export function EnergyItemGridToJSON(value?: EnergyItemGrid | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    import: value._import,
    export: value._export,
  };
}
