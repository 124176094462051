import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type WrapperSafeAreaEnabledSide = 'top' | 'right' | 'bottom' | 'left';

export type WrapperProps = {
  /**
   * Makes the wrapper full height.
   */
  fullHeight?: boolean;
  /**
   * This prop is used to enable safe area on mobile devices.
   * If `true`, it will enable safe area on all sides.
   * If `false`, it will disable safe area on all sides.
   * If an array of strings is provided, it will enable safe area on the specified sides.
   */
  safeArea?: boolean | WrapperSafeAreaEnabledSide[];
  children: ReactNode;
};

export const Wrapper: FC<WrapperProps> = ({
  fullHeight,
  safeArea,
  children,
}) => {
  const safeAreaEnabledSides =
    safeArea === true
      ? ['top', 'right', 'bottom', 'left']
      : safeArea === false || safeArea === undefined
        ? []
        : safeArea;

  const safeAreaStyles = {
    paddingTop: safeAreaEnabledSides.includes('top')
      ? 'max(env(safe-area-inset-top), 16px)'
      : undefined,
    paddingRight: safeAreaEnabledSides.includes('right')
      ? 'max(env(safe-area-inset-right), 16px)'
      : undefined,
    paddingBottom: safeAreaEnabledSides.includes('bottom')
      ? 'max(env(safe-area-inset-bottom), 16px)'
      : undefined,
    paddingLeft: safeAreaEnabledSides.includes('left')
      ? 'max(env(safe-area-inset-left), 16px)'
      : undefined,
  };

  return (
    <div
      className={clsx('px-4 py-4 grow shrink-0 overflow-y-auto', {
        'grid min-h-full w-full grid-cols-1': fullHeight,
      })}
      style={safeAreaStyles}
    >
      {children}
    </div>
  );
};
