import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

const icons = {
  warning: <FontIcon name="warning" />,
  error: <FontIcon name="error" />,
  help: <FontIcon name="help" />,
  info: <FontIcon name="info" variant="outlined" />,
  success: <FontIcon name="check_circle" />,
};

export type AlertProps = {
  type: keyof typeof icons;
  icon?: ReactNode;
  title?: string;
  text?: ReactNode;
  actions?: ReactNode;
};

export const Alert: FC<AlertProps> = (props) => {
  const { type, icon, title, text, actions } = props;
  return (
    <div
      className={clsx('flex gap-2 rounded-xl p-4 shadow-md', {
        'bg-amber-100': type === 'warning',
        'bg-red-100': type === 'error',
        'bg-blue-100': type === 'help',
        'bg-gray-100': type === 'info',
        'bg-green-100': type === 'success',
      })}
    >
      <div
        className={clsx({
          'text-amber-500': type === 'warning',
          'text-red-500': type === 'error',
          'text-blue-500': type === 'help',
          'text-gray-500': type === 'info',
          'text-green-500': type === 'success',
        })}
      >
        {icon ? icon : icons[type]}
      </div>
      <div className="flex flex-col gap-1 text-left">
        {title && (
          <p className="text-base">
            <strong>{title}</strong>{' '}
          </p>
        )}
        {text && <p className="text-sm text-left">{text}</p>}
        {actions && (
          <div
            className={clsx(
              'mt-2 font-semibold text-sm flex gap-4 items-center',
              {
                'text-amber-600': type === 'warning',
                'text-red-600': type === 'error',
                'text-blue-600': type === 'help',
                'text-gray-600': type === 'info',
                'text-green-600': type === 'success',
              },
            )}
          >
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};
