export const ClevergyEventTypes = {
  /**
   * Event to track analytics events in the app from modules
   */
  TRACK_ANALYTICS_EVENT: 'TRACK_ANALYTICS_EVENT',
  /**
   * Event to invalidate all queries in the ReactQuery cache
   */
  INVALIDATE_ALL_QUERIES: 'INVALIDATE_ALL_QUERIES',
  /**
   * Event to invalidate a specific query in the ReactQuery cache
   */
  INVALIDATE_QUERY: 'INVALIDATE_QUERY',
  /**
   * Event emitted when house settings are saved
   */
  HOUSE_SETTINGS_SAVED: 'HOUSE_SETTINGS_SAVED',
  /**
   * Event emitted when house settings are not saved
   */
  HOUSE_SETTINGS_NOT_SAVED: 'HOUSE_SETTINGS_NOT_SAVED',
  /**
   * Event emitted when house settings are saved in backup (local storage)
   */
  HOUSE_SETTINGS_SAVED_IN_BACKUP: 'HOUSE_SETTINGS_SAVED_IN_BACKUP',
  /**
   * Event emitted by the <clevergy-data-consent-inline> module when the user turns to COMPLETED status
   */
  USER_STATUS_COMPLETED: 'USER_STATUS_COMPLETED',
  /**
   * Event emitted by the <clevergy-data-consent-inline> module when the user turns to INITIAL status
   */
  USER_STATUS_INITIALIZED: 'USER_STATUS_INITIALIZED',
  /**
   * Event emitted by the <clevergy-id-document-validation> module when the document is validated
   */
  ID_DOCUMENT_VALIDATED: 'ID_DOCUMENT_VALIDATED',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module when is skipped
   */
  INTEGRATION_SMARTMETER_SKIPPED: 'INTEGRATION_SMARTMETER_SKIPPED',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module when is completed
   */
  INTEGRATION_SMARTMETER_COMPLETED: 'INTEGRATION_SMARTMETER_COMPLETED',
} as const;
