/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface OptimizeRecommendation
 */
export interface OptimizeRecommendation {
  /**
   *
   * @type {string}
   * @memberof OptimizeRecommendation
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OptimizeRecommendation
   */
  type: OptimizeRecommendationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OptimizeRecommendation
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OptimizeRecommendation
   */
  body: string;
}

/**
 * @export
 */
export const OptimizeRecommendationTypeEnum = {
  MonthlyConsumptionOk: 'MONTHLY_CONSUMPTION_OK',
  MonthlyConsumptionTooHigh: 'MONTHLY_CONSUMPTION_TOO_HIGH',
  EmptyConsumptionAlert: 'EMPTY_CONSUMPTION_ALERT',
  LastYearSameMonthImprovement: 'LAST_YEAR_SAME_MONTH_IMPROVEMENT',
  LastYearSameMonthRetreat: 'LAST_YEAR_SAME_MONTH_RETREAT',
  MonthlyConsumptionAlert: 'MONTHLY_CONSUMPTION_ALERT',
  SurplusDailyHours: 'SURPLUS_DAILY_HOURS',
  SurplusWeekly: 'SURPLUS_WEEKLY',
} as const;
export type OptimizeRecommendationTypeEnum =
  (typeof OptimizeRecommendationTypeEnum)[keyof typeof OptimizeRecommendationTypeEnum];

/**
 * Check if a given object implements the OptimizeRecommendation interface.
 */
export function instanceOfOptimizeRecommendation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'title' in value;
  isInstance = isInstance && 'body' in value;

  return isInstance;
}

export function OptimizeRecommendationFromJSON(
  json: any,
): OptimizeRecommendation {
  return OptimizeRecommendationFromJSONTyped(json, false);
}

export function OptimizeRecommendationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OptimizeRecommendation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    title: json['title'],
    body: json['body'],
  };
}

export function OptimizeRecommendationToJSON(
  value?: OptimizeRecommendation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    title: value.title,
    body: value.body,
  };
}
