import SuccessIllustration from './assets/illustrations/success.svg?react';
import { Button } from '@clevergy/ui/components/Button';
import { Wrapper } from 'components/Wrapper';
import { useNotificationsContext } from 'context/NotificationsContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const SupportTicketSuccessPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { requestNotificationsPermission } = useNotificationsContext();

  return (
    <Wrapper fullHeight safeArea={['top']}>
      <div className="flex h-full flex-col gap-6">
        <div className="flex grow flex-col justify-center text-center">
          <div className="w-52 mx-auto">
            <SuccessIllustration />
          </div>
          <p className="text-xl font-semibold text-center py-4">
            {t('support.successTicket.title')}
          </p>
          <p className="text-base text-slate-600 text-center px-4">
            {t('support.successTicket.message1')}{' '}
            {t('support.successTicket.message2')}{' '}
            <button
              onClick={() => requestNotificationsPermission()}
              className="text-clevergy-primary underline"
            >
              {t('support.successTicket.phoneSettingsLink')}
            </button>
          </p>
        </div>
        <div>
          <Button
            fullWidth
            onClick={() =>
              navigate('/', {
                replace: true,
              })
            }
          >
            {t('support.successTicket.actions.ok')}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};
