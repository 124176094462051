import { ConditionalRedirect } from 'components/ConditionalRedirect';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const OnlyPublicRoute: FC = () => {
  const { authedUser } = useAuthContext();
  return (
    <>
      <ConditionalRedirect
        targetPath="/"
        redirectCondition={Boolean(authedUser)}
      />
      <Outlet />
    </>
  );
};
