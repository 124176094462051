import './FontIcon.styles.css';
import { FC } from 'react';

export type FontIconProps = {
  /**
   * The icon name to use (From Google Material Symbols)
   * @see https://fonts.google.com/icons
   * */
  name: string;
  /**
   * The variant ("outlined" by default)
   * */
  variant?: 'filled' | 'outlined';
  /**
   * The size in pixels of the icon
   * */
  size?: number;
  /**
   * Whether the icon is inline or not
   * */
  inline?: boolean;
};

export const FontIcon: FC<FontIconProps> = (props) => {
  const { name, variant = 'filled', size = 24, inline = false } = props;
  return (
    <span
      className="material-symbols-rounded"
      data-variant={variant}
      style={{
        fontSize: `${size}px`,
        display: inline ? 'inline-block' : 'block',
        verticalAlign: inline ? 'bottom' : '',
        fontVariationSettings:
          variant === 'filled'
            ? "'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24"
            : "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
      }}
    >
      {name}
    </span>
  );
};
