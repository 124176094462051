import { PreferencesNotificationToggle } from './components/PreferencesNotificationToggle';
import { GetPreferencesNotificationsTypeEnum } from '@clevergy/api-client';
import { useTenantSettings } from '@clevergy/tenants';
import { ModuleHeader } from '@clevergy/ui/components/ModuleHeader';
import { AppHeader } from 'components/AppHeader';
import { HousePicker } from 'components/HousePicker';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useNotificationsContext } from 'context/NotificationsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlertsPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const tenantSettings = useTenantSettings();

  const { permissionsStatus, requestNotificationsPermission } =
    useNotificationsContext();

  const canRequestNotificationsPermission =
    tenantSettings.hasNotificationsFeatureEnabled &&
    permissionsStatus &&
    permissionsStatus !== 'unavailable';

  return (
    <>
      <AppHeader backButton title={t('alerts.title') as string}>
        <HousePicker />
      </AppHeader>

      <Wrapper>
        {token && (
          <>
            {selectedHouse && (
              <clevergy-alerts-settings
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {canRequestNotificationsPermission && (
              <>
                <div className="mt-8">
                  <ModuleHeader title={t('alerts.notifications.title')} />
                  <p className="mb-4">
                    {t('alerts.notifications.subtitle.part1')}{' '}
                    <button
                      onClick={() => requestNotificationsPermission()}
                      className="text-clevergy-primary underline"
                    >
                      {t('alerts.notifications.subtitle.phoneSettingsLink')}
                    </button>{' '}
                    {t('alerts.notifications.subtitle.part2')}
                  </p>

                  <div className="flex flex-col gap-4">
                    <PreferencesNotificationToggle
                      type={
                        GetPreferencesNotificationsTypeEnum.ConsumptionReport
                      }
                      title={
                        t(
                          'alerts.notifications.types.consumptionReport.title',
                        ) as string
                      }
                      description={
                        t(
                          'alerts.notifications.types.consumptionReport.description',
                        ) as string
                      }
                    />
                    <PreferencesNotificationToggle
                      type={GetPreferencesNotificationsTypeEnum.Export}
                      title={
                        t('alerts.notifications.types.export.title') as string
                      }
                      description={
                        t(
                          'alerts.notifications.types.export.description',
                        ) as string
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};
