import { ConditionalRedirect } from 'components/ConditionalRedirect';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const OnlyPrivateRoute: FC = () => {
  const { authedUser } = useAuthContext();

  return (
    <>
      <ConditionalRedirect
        targetPath="/login"
        redirectCondition={authedUser === null}
      />
      <Outlet />
    </>
  );
};
