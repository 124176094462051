import { Badge } from '../Badge';
import { Button } from '../Button';
import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type CardProps = {
  icon?: ReactNode;
  title?: string;
  text?: string | ReactNode;
  tag?: string;
  highlight?: boolean;
  withBorder?: boolean;
  closeButton?: {
    label: string;
    onClick: () => void;
  };
  actionButton?: {
    label: string;
    onClick: () => void;
  };
};

export const Card: FC<CardProps> = (props) => {
  const {
    icon,
    title,
    tag,
    text,
    highlight,
    actionButton,
    closeButton,
    withBorder = false,
  } = props;

  return (
    <div
      className={clsx('w-full h-full relative flex flex-col', {
        'bg-slate-100 text-clevergy-secondary': highlight,
        'border-clevergy-border rounded-lg border p-3':
          withBorder && !highlight,
        'border-slate-200 rounded-lg border p-3': withBorder && highlight,
        'text-clevergy-secondary rounded-lg p-3': !withBorder && highlight,
      })}
    >
      {closeButton && (
        <div className="absolute top-3 right-3">
          <button
            type="button"
            data-testid="card-close"
            aria-label={closeButton.label}
            onClick={closeButton.onClick}
            className={clsx({
              'text-black': !highlight,
            })}
          >
            <FontIcon name="close" />
          </button>
        </div>
      )}

      {tag && (
        <div className="mb-1">
          <Badge colorScheme={highlight ? 'secondary' : 'primary'}>{tag}</Badge>
        </div>
      )}

      <div
        className={clsx('flex gap-1 pr-8', {
          'text-clevergy-moduleHeaderTitle ': !highlight,
        })}
      >
        {icon && (
          <div
            className={clsx('flex-shrink-0', {
              'text-clevergy-primary': !highlight,
            })}
          >
            {icon}
          </div>
        )}
        {title && (
          <h4 className="text-base font-semibold leading-6">{title}</h4>
        )}
      </div>

      {text && (
        <div
          className={clsx('mt-2 grow', {
            'text-clevergy-text text-base': !highlight,
          })}
        >
          <p>{text}</p>
        </div>
      )}

      {actionButton && (
        <div className="mt-4">
          <Button
            fullWidth
            colorScheme={highlight ? 'secondary' : 'primary'}
            onClick={actionButton.onClick}
          >
            {actionButton.label}
          </Button>
        </div>
      )}
    </div>
  );
};
