import { useTenantSettings } from '../../context/TenantSettingsContext';
import { Icon, IconProps } from '@clevergy/ui/components/Icon';
import { FC } from 'react';

export type { IconNames } from '@clevergy/ui/components/Icon';
export type TenantIconProps = React.SVGProps<SVGElement> & IconProps;

/**
 * Provides an icon from the UI package with the icon variant setted from the tenant settings.
 */
export const TenantIcon: FC<TenantIconProps> = (props) => {
  const { variant, name, inline, ...rest } = props;
  const tenantSettings = useTenantSettings();

  const iconVariant = variant || tenantSettings.theme.iconVariant;

  return <Icon name={name} variant={iconVariant} inline={inline} {...rest} />;
};
