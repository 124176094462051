/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 * Detail of invoice
 * @export
 * @interface HouseInvoiceDetail
 */
export interface HouseInvoiceDetail {
  /**
   * Start date of the invoicing period
   * @type {Date}
   * @memberof HouseInvoiceDetail
   */
  startDate?: Date;
  /**
   * End date of the invoicing period
   * @type {Date}
   * @memberof HouseInvoiceDetail
   */
  endDate?: Date;
  /**
   * Total cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  totalCost: number;
  /**
   * Energy cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  energyCost?: number;
  /**
   * Power cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  powerCost?: number;
  /**
   * Other costs of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  otherCosts?: number;
  /**
   * Discounts applied to the invoice. Negative values means discounts.
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  discounts?: number;
  /**
   * Tax cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  taxCost?: number;
  /**
   * Surplus applied to the invoice. Negative values means surplus applied.
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  surplus?: number;
  /**
   * URL to download the invoice file
   * @type {string}
   * @memberof HouseInvoiceDetail
   */
  urlFile?: string;
}

/**
 * Check if a given object implements the HouseInvoiceDetail interface.
 */
export function instanceOfHouseInvoiceDetail(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'totalCost' in value;

  return isInstance;
}

export function HouseInvoiceDetailFromJSON(json: any): HouseInvoiceDetail {
  return HouseInvoiceDetailFromJSONTyped(json, false);
}

export function HouseInvoiceDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseInvoiceDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startDate: !exists(json, 'startDate')
      ? undefined
      : new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    totalCost: json['totalCost'],
    energyCost: !exists(json, 'energyCost') ? undefined : json['energyCost'],
    powerCost: !exists(json, 'powerCost') ? undefined : json['powerCost'],
    otherCosts: !exists(json, 'otherCosts') ? undefined : json['otherCosts'],
    discounts: !exists(json, 'discounts') ? undefined : json['discounts'],
    taxCost: !exists(json, 'taxCost') ? undefined : json['taxCost'],
    surplus: !exists(json, 'surplus') ? undefined : json['surplus'],
    urlFile: !exists(json, 'urlFile') ? undefined : json['urlFile'],
  };
}

export function HouseInvoiceDetailToJSON(
  value?: HouseInvoiceDetail | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    startDate:
      value.startDate === undefined
        ? undefined
        : value.startDate.toISOString().substr(0, 10),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate.toISOString().substr(0, 10),
    totalCost: value.totalCost,
    energyCost: value.energyCost,
    powerCost: value.powerCost,
    otherCosts: value.otherCosts,
    discounts: value.discounts,
    taxCost: value.taxCost,
    surplus: value.surplus,
    urlFile: value.urlFile,
  };
}
